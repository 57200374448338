<div class="general-info-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">

    <div class="row">
      <div class="col-md-6">
        <div class="section-title">
          <h3>1. Download the Template</h3>
        </div>

        <ul>
          <li><a href="/csv_templates/ScheduleEquipments.csv">Schedule Equipments</a></li>
          <li><a href="/csv_templates/ScheduleProperties.csv">Schedule Properties</a></li>
          <li><a href="/csv_templates/SchedulePayroll.csv">Schedule Payroll</a></li>
          <li><a href="/csv_templates/ScheduleVehicles.csv">Schedule Vehicles</a></li>
          <li><a href="/csv_templates/ScheduleWatercrafts.csv">Schedule Watercrafts</a></li>
          <li><a href="/csv_templates/ScheduleFineArts.csv">Schedule Fine Arts</a></li>
          <li><a href="/csv_templates/ScheduleEmployeeConcentrations.csv">Schedule Employee Concentrations</a></li>
          <li><a href="/csv_templates/ScheduleEmployeeLocations.csv">Schedule Employee Locations</a></li>
        </ul>

        <hr />

        <h5>Need to find a Department ID?</h5>
        <button type="button" mat-raised-button color="primary" class="submit-btn" (click)="openDepartmentsDialog()">View Department IDs</button>

      </div>
      <div class="col-md-6">

        <form [formGroup]="uploadForm" (ngSubmit)="onSubmit(uploadForm)">
          <div class="section-title">
            <h3>2. Upload your File</h3>
          </div>

          
          <input file id="file" type='file' (change)="selectFile($event)" accept=".csv">

          <!-- Table -->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-label>Importing Table</mat-label>
            <mat-select formControlName="UploadTable">
              <mat-option value="ScheduleEquipments">Schedule Equipments</mat-option>
              <mat-option value="ScheduleProperties">Schedule Properties</mat-option>
              <mat-option value="SchedulePayroll">Schedule Payroll</mat-option>
              <mat-option value="ScheduleVehicles">Schedule Vehicles</mat-option>
              <mat-option value="ScheduleWatercrafts">Schedule Watercrafts</mat-option>
              <mat-option value="ScheduleFineArts">Schedule Fine Arts</mat-option>
              <mat-option value="ScheduleEmployeeConcentrations">Schedule Employee Concentrations</mat-option>
              <mat-option value="ScheduleEmployeeLocations">Schedule EmployeeLocations</mat-option>
            </mat-select>
            <mat-error *ngIf="uploadForm.controls['UploadTable'].hasError('required')">This field is required</mat-error>
          </mat-form-field>

          <!-- ActionButtons -->
          <div class="form-action-buttons">
            <div class="row">
              <div class="col-md-12">
                <button type="submit" mat-raised-button color="success" class="submit-btn float-right">Upload</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>

    
  </section>

  
</div>
