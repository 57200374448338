<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>New Locations Property Values</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="newLocationsPropertyValuesForm" (ngSubmit)="submitNewLocationsPropertyValuesForm(newLocationsPropertyValuesForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Location Property Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--Street -->
            <mat-label>Street <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Street">
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['Street'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 200</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--City -->
            <mat-label>City <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="City">
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['City'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 160</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--State -->
            <mat-label>State <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="State" mask="SS" oninput="this.value = this.value.toUpperCase()">
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['State'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 2</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Zip -->
            <mat-label>Zip <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="Zip" placeholder="" mask="00000" />
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['Zip'].hasError('required')">This field is required</mat-error>
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['Zip'].hasError('isNumbersOnly')">This field accepts numbers only</mat-error>
              <mat-error>Must be 5 characters long</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--Type -->
            <mat-label>Type</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Type">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--SqFt -->
            <mat-label>SqFt</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="SqFt" maxlength="50">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Floors -->
            <mat-label>Floors</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Floors" maxlength="50">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Block -->
            <mat-label>Block</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Block">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Lot -->
            <mat-label>Lot</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Lot">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--YearBuilt -->
            <mat-label>Year Built</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="YearBuilt" maxlength="50">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--Construction -->
            <mat-label>Construction</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Construction">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--ProtectionClass -->
            <mat-label>Protection Class</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="ProtectionClass">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--FloodZone -->
            <mat-label>Flood Zone</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FloodZone">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--BuildingValue -->
            <mat-label>Building Value <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="BuildingValue">
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['BuildingValue'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--ContentsValue -->
            <mat-label>Contents Value <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="ContentsValue">
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['ContentsValue'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Sprinklers -->
            <mat-label>Sprinklers</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Sprinklers">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Alarms -->
            <mat-label>Alarms</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Alarms">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--SmokeDetectors -->
            <mat-label>Smoke Detectors</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="SmokeDetectors">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--OtherProtection -->
            <mat-label>Other Protection</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="OtherProtection">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Occupancy -->
            <mat-label>Occupancy</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Occupancy">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--RentalIncome -->
            <mat-label>Rental Income <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="RentalIncome">
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['RentalIncome'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--AdditionalInterest -->
            <mat-label>Additional Interest</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="AdditionalInterest">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error *ngIf="newLocationsPropertyValuesForm.controls['EffectiveDate'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
