//Import Modules
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from "angular-notifier";

//Import Services
import { SchedulesService } from '../../../shared/services/schedules/schedules.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

//Import Components
import { NewLocationsPropertyValuesDialogComponent } from './dialog/new-locations-property-values-dialog/new-locations-property-values-dialog.component';
import { DeleteLocationsPropertyDialogComponent } from './dialog/delete-locations-property-dialog/delete-locations-property-dialog.component';
import { UpdateLocationsPropertyDialogComponent } from './dialog/update-locations-property-dialog/update-locations-property-dialog.component';

//Export to Excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-locations-property-values',
  templateUrl: './locations-property-values.component.html',
  styleUrls: ['./locations-property-values.component.scss']
})

export class LocationsPropertyValuesComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private schedulesService: SchedulesService, private authService: AuthService, private notifier: NotifierService) { };

  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public activeMunicipality;
  public sort;

  //Locations property table
  public displayedColumns: string[] = ["street", "city", "state", "zip", "type", "sqFt", "floors", "block", "lot", "yearBuilt", "construction", "milesFromCoast", "floodZone", "contentsValue", "buildingValue", "total", "action"];
  public data;
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMunicipality = response;
        this.getTableList(response);
      }
    });
  }

  public getTableList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListLocationsPropertyValues').subscribe((response: any) => {
      if (!response) {
        return
      }
      else {
        this.data = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
      }
    });
  }

  public getDetails(employeeConcentrationID) {
    this.schedulesService.getDetail(employeeConcentrationID, 'GetDetailEmployeeConcentrations').subscribe((response: any) => {
      console.log(response);
    });
  }

  public openNewDialog() {
    const dialogRef = this.dialog.open(NewLocationsPropertyValuesDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.add(result, 'AddLocationsPropertyValue').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  }

  public openUpdateDialog(data) {
    const dialogRef = this.dialog.open(UpdateLocationsPropertyDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.update(result, 'UpdateLocationsPropertyValue').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  }

  public openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeleteLocationsPropertyDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.delete(result, 'DeleteLocationsPropertyValue').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  }

  public calculateBuildingValue() {
    return this.dataSource.filteredData.map(t => t.buildingValue).reduce((acc, value) => acc + value, 0);
  }

  public calculateContentsValue() {
    return this.dataSource.filteredData.map(t => t.contentsValue).reduce((acc, value) => acc + value, 0);
  }

  public calculateBuildingTotal() {
    return this.dataSource.filteredData.map(t => t.contentsValue + t.buildingValue).reduce((acc, value) => acc + value, 0);
  }

  public filterTable = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Location Property Values');

    worksheet.columns = [
      { header: 'Street', key: 'street', width: 60 },
      { header: 'City', key: 'city', width: 26 },
      { header: 'State', key: 'state', width: 10 },
      { header: 'ZIP', key: 'zip', width: 10 },
      { header: 'Type', key: 'type', width: 48 },
      { header: 'Sq. Ft', key: 'sqFt', width: 12 },
      { header: 'Floors', key: 'floors', width: 10 },
      { header: 'Block', key: 'block', width: 10 },
      { header: 'Lot', key: 'lot', width: 10 },
      { header: 'Year Built', key: 'yearBuilt', width: 12 },
      { header: 'Construction', key: 'construction', width: 22 },
      { header: 'Miles From Coast', key: 'milesFromCoast', width: 16 },
      { header: 'Flood Zone', key: 'floodZone', width: 12 },
      { header: 'Contents Value', key: 'contentsValue', width: 18 },
      { header: 'Building Value', key: 'buildingValue', width: 18 },
      { header: 'Total', key: 'total', width: 20 },
    ];

    this.data.forEach(e => {
      worksheet.addRow({
        street: e.street,
        city: e.city,
        state: e.state,
        zip: e.zip,
        type: e.type,
        sqFt: e.sqFt,
        floors: e.floors,
        block: e.block,
        lot: e.lot,
        yearBuilt: e.yearBuilt,
        construction: e.construction,
        milesFromCoast: e.milesFromCoast,
        floodZone: e.floodZone,
        contentsValue: e.contentsValue,
        buildingValue: e.buildingValue,
        total: e.contentsValue + e.buildingValue
      }, "n");
    });

    worksheet.getColumn(14).numFmt = '"$"#,##0.00';
    worksheet.getColumn(15).numFmt = '"$"#,##0.00';
    worksheet.getColumn(16).numFmt = '"$"#,##0.00';

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Location-Property-Values.xlsx');
    })

  }

}
