//Import Modules
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { NotifierService } from "angular-notifier";

// Import Logs Service (replace with your actual service)
import { LogsService } from '../../shared/services/logs/logs.service';

// Import Dialog for Log Details
import { LogDetailComponent } from './dialog/log-detail/log-detail.component';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  constructor(private logsService: LogsService, private dialog: MatDialog, private notifier: NotifierService) { }

  @ViewChild(MatSort) sort: MatSort;
  public displayedColumns: string[] = ['ChangedDate', 'TableName', 'Operation', 'ChangedBy', 'action'];
  public dataSource: MatTableDataSource<any>;

  ngOnInit() {
    this.getAllLogs();
  }

  // Get all logs and populate the table
  getAllLogs() {
    this.logsService.getListLogs().subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort;
    }, error => {
      this.notifier.notify('error', 'Failed to load logs');
    });
  }

  // Open modal to view log details
  viewLogDetail(log: any) {
    this.logsService.getDetailLog(log.auditLogID).subscribe((logDetail: any) => {
      const dialogRef = this.dialog.open(LogDetailComponent, {
        data: logDetail,
        minWidth: '600px'
      });

      dialogRef.afterClosed().subscribe();
    }, error => {
      this.notifier.notify('error', 'Failed to load log details');
    });
  }
}
