//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { PasswordValidators } from '../../shared/pipes/password-validators';

//Import Services
import { AccountService } from '../service/account.service';
import { NotifierService } from "angular-notifier";
import { AuthService } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  constructor(private accountService: AccountService, private authService: AuthService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private notifier: NotifierService) {
    this.changePasswordForm = fb.group({
      UserId: ['', Validators.compose([Validators.required])], //STRING
      CurrentPassword: ['', Validators.compose([Validators.required])], //STRING
      Password: ['', Validators.compose([Validators.required, PasswordValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }), PasswordValidators.patternValidator(/[a-z]/, { hasSmallCase: true }), PasswordValidators.patternValidator(/[!@#$%^&*]/, { hasSpecialCharacter: true }), Validators.minLength(6)])], //STRING
      ConfirmPassword: ['', Validators.compose([Validators.required])] //STRING
    },
      {
        validator: PasswordValidators.passwordMatchValidator
      })
  };

  public changePasswordForm: FormGroup;

  ngOnInit() {
    this.changePasswordForm.controls.UserId.setValue(this.authService.activeAccount.userID);
  };

  //Submit change password form
  submitChangePasswordForm(form) {
    if (form.valid) {
      this.accountService.accuntChangePassword(form.value.UserId, form.value.CurrentPassword, form.value.Password).pipe(first()).subscribe({
        next: () => {
          this.notifier.notify('success', 'Password updated successfully');
        },
        error: error => {
          this.notifier.notify('error', 'Something went wrong');
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
