//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

//Import Services
import { AccountService } from '../service/account.service';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  constructor(public accountService: AccountService, private http: HttpClient, private fb: FormBuilder, private router: Router, private notifier: NotifierService) {
    this.forgotPasswordForm = new FormGroup({
      Email: new FormControl('', [Validators.required, Validators.email])
    });
  };

  public forgotPasswordForm: FormGroup;

  ngOnInit() { }

  //Submit forgot password form
  submitForgotPasswordForm(form) {
    if (form.valid) {
      this.accountService.accountForgotPassword(form.value.Email).pipe(first()).subscribe({
        next: (response: any) => {
          if (response.error === 0) {
            this.notifier.notify('success', response.message);
          } else {
            this.notifier.notify('error', response.message);
          }
        },
        error: error => {
          this.notifier.notify('error', 'Something went wrong');
        }
      });
    } else {
      this.notifier.notify("error", "Submitted form is invalid");
    }
  };
};
