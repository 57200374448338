//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

interface IActiveMunicipality {
  municipalityID: string;
  municipalityYear: string;
};

@Injectable({
  providedIn: 'root'
})
export class RenewalApplicationsService {
  constructor(private http: HttpClient) {
    this.activeMunicipalitySubject = new BehaviorSubject<IActiveMunicipality>(JSON.parse(localStorage.getItem('activeMunicipality')));
    this.activeMunicipality = this.activeMunicipalitySubject.asObservable();
  };

  private activeMunicipalitySubject: BehaviorSubject<IActiveMunicipality>;
  public activeMunicipality: Observable<IActiveMunicipality>;

  public getMunicipality(): Observable<IActiveMunicipality> {
    return this.activeMunicipalitySubject.asObservable();
  };

  public setBehaviorView(activeMunicipality: IActiveMunicipality) {
    localStorage.setItem('activeMunicipality', JSON.stringify(activeMunicipality));
    this.activeMunicipalitySubject.next(activeMunicipality);
  };
};
