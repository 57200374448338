<div class="risk-managers-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Municipalities</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" (click)="openNewMunicipalityDialog()">Register New Municipality</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table mat-table class="datatable" [dataSource]="dataSource" matSort matSortActive="fullName" matSortDirection="asc" [@.disabled]="true">
          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Full Name </th>
            <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
          </ng-container>

          <!--Years-->
          <ng-container matColumnDef="Years">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" [disabled]="element.municipalityYear.length == 0">
                <mat-icon>expand_more</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let year of element.municipalityYear" (click)="openEditMunicipalityDialog(element.id, year)">
                  <mat-icon mat-raised-button class="mr-1" type="button" color="primary">mode_edit</mat-icon> <span>{{year}}</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
