//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

//Import Services
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';

@Component({
  selector: 'app-specific',
  templateUrl: './specific.component.html',
  styleUrls: ['./specific.component.scss']
})
export class SpecificComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.specificForm = new FormGroup({
      Airports: new FormControl(false),
      AmusementParks: new FormControl(false),
      ExhibitionCenter: new FormControl(false),
      BlastingOperations: new FormControl(false),
      Cemetery: new FormControl(false),
      ChemicalSpraying: new FormControl(false),
      DamDikes: new FormControl(false),
      DayCare: new FormControl(false),
      ElectricUtility: new FormControl(false),
      EMTParamedics: new FormControl(false),
      Firefighters: new FormControl(false),
      TownFireworks: new FormControl(false),
      GarbageRefuse: new FormControl(false),
      GasUtility: new FormControl(false),
      GolfCourses: new FormControl(false),
      HousingProjectsTown: new FormControl(false),
      LakesReservoirs: new FormControl(false),
      LandfillSites: new FormControl(false),
      LawFacilities: new FormControl(false),
      MedicalFacilities: new FormControl(false),
      MowingOperations: new FormControl(false),
      ParkingAuthority: new FormControl(false),
      ParksPlaygrounds: new FormControl(false),
      PistolRange: new FormControl(false),
      RaceTracks: new FormControl(false),
      SchoolsColleges: new FormControl(false),
      Skating: new FormControl(false),
      SkiAreas: new FormControl(false),
      SpecialEvents: new FormControl(false),
      Stadiums: new FormControl(false),
      Streets: new FormControl(false),
      SwimmingPools: new FormControl(false),
      SewerUtility: new FormControl(false),
      WaterUtility: new FormControl(false),
      WaterfrontProperties: new FormControl(false),
      Zoo: new FormControl(false),
      ExposureSpecificID: new FormControl(0),
      RenewalApplicationID: new FormControl(0),
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]),
      AirportsOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      AirportsOwnedDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      AmusementOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      AmusementOwnedDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      MechanicalAmusement: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      ExhibitionOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      ExhibitionOwnedDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      FootageCapacity: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      BlastingOperationsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      CemeteryDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      ChemicalSprayingDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      DamDikesDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      NumbDamDikes: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      DayCareOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      NumbFacilities: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      NumbChildren: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      ElectricUtilityDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      TotalPayroll: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      EMTParamedicsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      EMTNumbPersonnel: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      EMTNumbCalls: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      FirefightersDescription: new FormControl({ value: '', disabled: true, }, Validators.maxLength(200)),
      FireNumbPersonnel: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      FireNumbVolunteer: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      FireTotalPayroll: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      TownFireWorksDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      TownFireWorksEvents: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      GarbageRefuseDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      GarbageRefuseCustomers: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      GasUtilityDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      GolfCoursesOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      GolfCoursesDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      GolfCoursesNumb: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      GolfCoursesSales: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      HousingProjectsTownDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LakesReservoirsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LakesReservoirsNumb: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LandfillSitesOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LandfillSitesDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawFacilitiesOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawFacilitiesDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawFacilitiesPayroll: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawFirearmsFull: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawFirearmsPart: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawPersonnel: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawCells: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawLengthStay: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      LawDogHorse: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      MedicalFacilitiesOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      MedicalFacilitiesDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      MedicalFacilitiesBeds: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      MedicalFacilitiesPatients: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      MowingOperationsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      ParkingAuthorityDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      ParksPlaygroundsNumb: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      PistolRangeDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      RaceTracksOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SchoolsCollegesDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SkatingOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SkatingDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SkiAreasDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SpecialAmusementDevices: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SpecialEventsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SpecialEventsYear: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StadiumsOwned: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StadiumsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StadiumsCapacity5k: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StadiumsTotalCapacity: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StreetsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StreetsPaved: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StreetsUnpaved: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      StreetsBridges: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SwimmingPoolsDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SwimmingPoolsNumb: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SwimmingPoolsDiving: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SewerUtilityDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      SewerUtilityPayroll: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      WaterUtilityDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      WaterUtilityPayroll: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      WaterfrontPropertiesMiles: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      WaterfrontPropertiesDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      WaterfrontPropertiesBoats: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      WaterfrontPropertiesPiers: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      WaterfrontPropertiesMarinas: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      ZooDescription: new FormControl({ value: '', disabled: true }, Validators.maxLength(200)),
      EffectiveDate: new FormControl('', Validators.required)
    });

    //Airports
    this.specificForm.controls['Airports'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('AirportsOwned').enable() : this.specificForm.get('AirportsOwned').disable()
      value ? this.specificForm.get('AirportsOwnedDescription').enable() : this.specificForm.get('AirportsOwnedDescription').disable()
    });

    //AmusementParks
    this.specificForm.controls['AmusementParks'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('AmusementOwned').enable() : this.specificForm.get('AmusementOwned').disable()
      value ? this.specificForm.get('AmusementOwnedDescription').enable() : this.specificForm.get('AmusementOwnedDescription').disable()
      value ? this.specificForm.get('MechanicalAmusement').enable() : this.specificForm.get('MechanicalAmusement').disable()
    });

    //ExhibitionCenter
    this.specificForm.controls['ExhibitionCenter'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('ExhibitionOwned').enable() : this.specificForm.get('ExhibitionOwned').disable()
      value ? this.specificForm.get('ExhibitionOwnedDescription').enable() : this.specificForm.get('ExhibitionOwnedDescription').disable()
      value ? this.specificForm.get('FootageCapacity').enable() : this.specificForm.get('FootageCapacity').disable()
    });

    //BlastingOperations
    this.specificForm.controls['BlastingOperations'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('BlastingOperationsDescription').enable() : this.specificForm.get('BlastingOperationsDescription').disable()
    });

    //BlastingOperations
    this.specificForm.controls['BlastingOperations'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('BlastingOperationsDescription').enable() : this.specificForm.get('BlastingOperationsDescription').disable()
    });

    //Cemetery
    this.specificForm.controls['Cemetery'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('CemeteryDescription').enable() : this.specificForm.get('CemeteryDescription').disable()
    });

    //ChemicalSpraying
    this.specificForm.controls['ChemicalSpraying'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('ChemicalSprayingDescription').enable() : this.specificForm.get('ChemicalSprayingDescription').disable()
    });

    //DamDikes
    this.specificForm.controls['DamDikes'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('DamDikesDescription').enable() : this.specificForm.get('DamDikesDescription').disable()
      value ? this.specificForm.get('NumbDamDikes').enable() : this.specificForm.get('NumbDamDikes').disable()
    });

    //DayCare
    this.specificForm.controls['DayCare'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('DayCareOwned').enable() : this.specificForm.get('DayCareOwned').disable()
      value ? this.specificForm.get('NumbFacilities').enable() : this.specificForm.get('NumbFacilities').disable()
      value ? this.specificForm.get('NumbChildren').enable() : this.specificForm.get('NumbChildren').disable()
    });

    //ElectricUtility
    this.specificForm.controls['ElectricUtility'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('ElectricUtilityDescription').enable() : this.specificForm.get('ElectricUtilityDescription').disable()
      value ? this.specificForm.get('TotalPayroll').enable() : this.specificForm.get('TotalPayroll').disable()
    });

    //EMTParamedics
    this.specificForm.controls['EMTParamedics'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('EMTParamedicsDescription').enable() : this.specificForm.get('EMTParamedicsDescription').disable()
      value ? this.specificForm.get('EMTNumbPersonnel').enable() : this.specificForm.get('EMTNumbPersonnel').disable()
      value ? this.specificForm.get('EMTNumbCalls').enable() : this.specificForm.get('EMTNumbCalls').disable()
    });

    //Firefighters
    this.specificForm.controls['Firefighters'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('FirefightersDescription').enable() : this.specificForm.get('FirefightersDescription').disable()
      value ? this.specificForm.get('FireNumbPersonnel').enable() : this.specificForm.get('FireNumbPersonnel').disable()
      value ? this.specificForm.get('FireNumbVolunteer').enable() : this.specificForm.get('FireNumbVolunteer').disable()
      value ? this.specificForm.get('FireTotalPayroll').enable() : this.specificForm.get('FireTotalPayroll').disable()
    });

    //TownFireworks
    this.specificForm.controls['TownFireworks'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('TownFireWorksDescription').enable() : this.specificForm.get('TownFireWorksDescription').disable()
      value ? this.specificForm.get('TownFireWorksEvents').enable() : this.specificForm.get('TownFireWorksEvents').disable()
    });

    //GarbageRefuse
    this.specificForm.controls['GarbageRefuse'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('GarbageRefuseDescription').enable() : this.specificForm.get('GarbageRefuseDescription').disable()
      value ? this.specificForm.get('GarbageRefuseCustomers').enable() : this.specificForm.get('GarbageRefuseCustomers').disable()
    });

    //GasUtility
    this.specificForm.controls['GasUtility'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('GasUtilityDescription').enable() : this.specificForm.get('GasUtilityDescription').disable()
    });

    //GolfCourses
    this.specificForm.controls['GolfCourses'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('GolfCoursesOwned').enable() : this.specificForm.get('GolfCoursesOwned').disable()
      value ? this.specificForm.get('GolfCoursesDescription').enable() : this.specificForm.get('GolfCoursesDescription').disable()
      value ? this.specificForm.get('GolfCoursesNumb').enable() : this.specificForm.get('GolfCoursesNumb').disable()
      value ? this.specificForm.get('GolfCoursesSales').enable() : this.specificForm.get('GolfCoursesSales').disable()
    });

    //HousingProjectsTown
    this.specificForm.controls['HousingProjectsTown'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('HousingProjectsTownDescription').enable() : this.specificForm.get('HousingProjectsTownDescription').disable()
    });

    //LakesReservoirs
    this.specificForm.controls['LakesReservoirs'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('LakesReservoirsDescription').enable() : this.specificForm.get('LakesReservoirsDescription').disable()
      value ? this.specificForm.get('LakesReservoirsNumb').enable() : this.specificForm.get('LakesReservoirsNumb').disable()
    });

    //LandfillSites
    this.specificForm.controls['LandfillSites'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('LandfillSitesOwned').enable() : this.specificForm.get('LandfillSitesOwned').disable()
      value ? this.specificForm.get('LandfillSitesDescription').enable() : this.specificForm.get('LandfillSitesDescription').disable()
    });

    //LandfillSites
    this.specificForm.controls['LandfillSites'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('LandfillSitesOwned').enable() : this.specificForm.get('LandfillSitesOwned').disable()
      value ? this.specificForm.get('LandfillSitesDescription').enable() : this.specificForm.get('LandfillSitesDescription').disable()
    });

    //LawFacilities
    this.specificForm.controls['LawFacilities'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('LawFacilitiesOwned').enable() : this.specificForm.get('LawFacilitiesOwned').disable()
      value ? this.specificForm.get('LawFacilitiesDescription').enable() : this.specificForm.get('LawFacilitiesDescription').disable()
      value ? this.specificForm.get('LawFacilitiesPayroll').enable() : this.specificForm.get('LawFacilitiesPayroll').disable()
      value ? this.specificForm.get('LawFirearmsFull').enable() : this.specificForm.get('LawFirearmsFull').disable()

      value ? this.specificForm.get('LawFirearmsPart').enable() : this.specificForm.get('LawFirearmsPart').disable()
      value ? this.specificForm.get('LawFirearmsPart').enable() : this.specificForm.get('LawFirearmsPart').disable()
      value ? this.specificForm.get('LawFirearmsPart').enable() : this.specificForm.get('LawFirearmsPart').disable()
      value ? this.specificForm.get('LawPersonnel').enable() : this.specificForm.get('LawPersonnel').disable()

      value ? this.specificForm.get('LawCells').enable() : this.specificForm.get('LawCells').disable()
      value ? this.specificForm.get('LawLengthStay').enable() : this.specificForm.get('LawLengthStay').disable()
      value ? this.specificForm.get('LawDogHorse').enable() : this.specificForm.get('LawDogHorse').disable()
    });

    //MedicalFacilities
    this.specificForm.controls['MedicalFacilities'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('MedicalFacilitiesOwned').enable() : this.specificForm.get('MedicalFacilitiesOwned').disable()
      value ? this.specificForm.get('MedicalFacilitiesDescription').enable() : this.specificForm.get('MedicalFacilitiesDescription').disable()
      value ? this.specificForm.get('MedicalFacilitiesBeds').enable() : this.specificForm.get('MedicalFacilitiesBeds').disable()
      value ? this.specificForm.get('MedicalFacilitiesPatients').enable() : this.specificForm.get('MedicalFacilitiesPatients').disable()
    });

    //MowingOperations
    this.specificForm.controls['MowingOperations'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('MowingOperationsDescription').enable() : this.specificForm.get('MowingOperationsDescription').disable()
    });

    //ParkingAuthority
    this.specificForm.controls['ParkingAuthority'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('ParkingAuthorityDescription').enable() : this.specificForm.get('ParkingAuthorityDescription').disable()
    });

    //ParksPlaygrounds
    this.specificForm.controls['ParksPlaygrounds'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('ParksPlaygroundsNumb').enable() : this.specificForm.get('ParksPlaygroundsNumb').disable()
    });

    //PistolRange
    this.specificForm.controls['PistolRange'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('PistolRangeDescription').enable() : this.specificForm.get('PistolRangeDescription').disable()
    });

    //RaceTracks
    this.specificForm.controls['RaceTracks'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('RaceTracksOwned').enable() : this.specificForm.get('RaceTracksOwned').disable()
    });

    //SchoolsColleges
    this.specificForm.controls['SchoolsColleges'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('SchoolsCollegesDescription').enable() : this.specificForm.get('SchoolsCollegesDescription').disable()
    });

    //Skating
    this.specificForm.controls['Skating'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('SkatingOwned').enable() : this.specificForm.get('SkatingOwned').disable()
      value ? this.specificForm.get('SkatingDescription').enable() : this.specificForm.get('SkatingDescription').disable()
    });

    //SkiAreas
    this.specificForm.controls['SkiAreas'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('SkiAreasDescription').enable() : this.specificForm.get('SkiAreasDescription').disable()
    });

    //SpecialEvents
    this.specificForm.controls['SpecialEvents'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('SpecialAmusementDevices').enable() : this.specificForm.get('SpecialAmusementDevices').disable()
      value ? this.specificForm.get('SpecialEventsDescription').enable() : this.specificForm.get('SpecialEventsDescription').disable()
      value ? this.specificForm.get('SpecialEventsYear').enable() : this.specificForm.get('SpecialEventsYear').disable()
    });

    //Stadiums
    this.specificForm.controls['Stadiums'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('StadiumsOwned').enable() : this.specificForm.get('StadiumsOwned').disable()
      value ? this.specificForm.get('StadiumsDescription').enable() : this.specificForm.get('StadiumsDescription').disable()
      value ? this.specificForm.get('StadiumsCapacity5k').enable() : this.specificForm.get('StadiumsCapacity5k').disable()
      value ? this.specificForm.get('StadiumsTotalCapacity').enable() : this.specificForm.get('StadiumsTotalCapacity').disable()
    });

    //Streets
    this.specificForm.controls['Streets'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('StreetsDescription').enable() : this.specificForm.get('StreetsDescription').disable()
      value ? this.specificForm.get('StreetsPaved').enable() : this.specificForm.get('StreetsPaved').disable()
      value ? this.specificForm.get('StreetsUnpaved').enable() : this.specificForm.get('StreetsUnpaved').disable()
      value ? this.specificForm.get('StreetsBridges').enable() : this.specificForm.get('StreetsBridges').disable()
    });

    //SwimmingPools
    this.specificForm.controls['SwimmingPools'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('SwimmingPoolsDescription').enable() : this.specificForm.get('SwimmingPoolsDescription').disable()
      value ? this.specificForm.get('SwimmingPoolsNumb').enable() : this.specificForm.get('SwimmingPoolsNumb').disable()
      value ? this.specificForm.get('SwimmingPoolsDiving').enable() : this.specificForm.get('SwimmingPoolsDiving').disable()
    });

    //SewerUtility
    this.specificForm.controls['SewerUtility'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('SewerUtilityDescription').enable() : this.specificForm.get('SewerUtilityDescription').disable()
      value ? this.specificForm.get('SewerUtilityPayroll').enable() : this.specificForm.get('SewerUtilityPayroll').disable()
    });

    //WaterUtility
    this.specificForm.controls['WaterUtility'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('WaterUtilityDescription').enable() : this.specificForm.get('WaterUtilityDescription').disable()
      value ? this.specificForm.get('WaterUtilityPayroll').enable() : this.specificForm.get('WaterUtilityPayroll').disable()
    });

    //WaterfrontProperties
    this.specificForm.controls['WaterfrontProperties'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('WaterfrontPropertiesMiles').enable() : this.specificForm.get('WaterfrontPropertiesMiles').disable()
      value ? this.specificForm.get('WaterfrontPropertiesDescription').enable() : this.specificForm.get('WaterfrontPropertiesDescription').disable()
      value ? this.specificForm.get('WaterfrontPropertiesBoats').enable() : this.specificForm.get('WaterfrontPropertiesBoats').disable()
      value ? this.specificForm.get('WaterfrontPropertiesPiers').enable() : this.specificForm.get('WaterfrontPropertiesPiers').disable()
      value ? this.specificForm.get('WaterfrontPropertiesMarinas').enable() : this.specificForm.get('WaterfrontPropertiesMarinas').disable()
    });

    //WaterfrontProperties
    this.specificForm.controls['Zoo'].valueChanges.subscribe(value => {
      value ? this.specificForm.get('ZooDescription').enable() : this.specificForm.get('ZooDescription').disable()
    });
  };

  public activeMunicipality;
  public specificForm: FormGroup;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.specificForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getExposureSpecific(response);
      }
    });
  };

  public getExposureSpecific(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetExposureSpecific').subscribe((response: any) => {
      if (!response) {
        this.specificForm.patchValue({
          'Airports': false,
          'AmusementParks': false,
          'ExhibitionCenter': false,
          'BlastingOperations': false,
          'Cemetery': false,
          'ChemicalSpraying': false,
          'DamDikes': false,
          'DayCare': false,
          'ElectricUtility': false,
          'EMTParamedics': false,
          'Firefighters': false,
          'TownFireworks': false,
          'GarbageRefuse': false,
          'GasUtility': false,
          'GolfCourses': false,
          'HousingProjectsTown': false,
          'LakesReservoirs': false,
          'LandfillSites': false,
          'LawFacilities': false,
          'MedicalFacilities': false,
          'MowingOperations': false,
          'ParkingAuthority': false,
          'ParksPlaygrounds': false,
          'PistolRange': false,
          'RaceTracks': false,
          'SchoolsColleges': false,
          'Skating': false,
          'SkiAreas': false,
          'SpecialEvents': false,
          'Stadiums': false,
          'Streets': false,
          'SwimmingPools': false,
          'SewerUtility': false,
          'WaterUtility': false,
          'WaterfrontProperties': false,
          'Zoo': false,
          'ExposureSpecificID': 0,
          'RenewalApplicationID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'AirportsOwned': '',
          'AirportsOwnedDescription': '',
          'AmusementOwned': '',
          'AmusementOwnedDescription': '',
          'MechanicalAmusement': '',
          'ExhibitionOwned': '',
          'ExhibitionOwnedDescription': '',
          'FootageCapacity': '',
          'BlastingOperationsDescription': '',
          'CemeteryDescription': '',
          'ChemicalSprayingDescription': '',
          'DamDikesDescription': '',
          'NumbDamDikes': '',
          'DayCareOwned': '',
          'NumbFacilities': '',
          'NumbChildren': '',
          'ElectricUtilityDescription': '',
          'TotalPayroll': '',
          'EMTParamedicsDescription': '',
          'EMTNumbPersonnel': '',
          'EMTNumbCalls': '',
          'FirefightersDescription': '',
          'FireNumbVolunteer': '',
          'FireTotalPayroll': '',
          'TownFireWorksDescription': '',
          'TownFireWorksEvents': '',
          'GarbageRefuseDescription': '',
          'GarbageRefuseCustomers': '',
          'GasUtilityDescription': '',
          'GolfCoursesOwned': '',
          'GolfCoursesDescription': '',
          'GolfCoursesNumb': '',
          'GolfCoursesSales': '',
          'HousingProjectsTownDescription': '',
          'LakesReservoirsDescription': '',
          'LakesReservoirsNumb': '',
          'LandfillSitesOwned': '',
          'LandfillSitesDescription': '',
          'LawFacilitiesOwned': '',
          'LawFacilitiesDescription': '',
          'LawFacilitiesPayroll': '',
          'LawFirearmsFull': '',
          'LawFirearmsPart': '',
          'LawPersonnel': '',
          'LawCells': '',
          'LawLengthStay': '',
          'LawDogHorse': '',
          'MedicalFacilitiesOwned': '',
          'MedicalFacilitiesDescription': '',
          'MedicalFacilitiesBeds': '',
          'MedicalFacilitiesPatients': '',
          'MowingOperationsDescription': '',
          'ParkingAuthorityDescription': '',
          'ParksPlaygroundsNumb': '',
          'PistolRangeDescription': '',
          'RaceTracksOwned': '',
          'SchoolsCollegesDescription': '',
          'SkatingOwned': '',
          'SkatingDescription': '',
          'SkiAreasDescription': '',
          'SpecialAmusementDevices': '',
          'SpecialEventsDescription': '',
          'SpecialEventsYear': '',
          'StadiumsOwned': '',
          'StadiumsDescription': '',
          'StadiumsCapacity5k': '',
          'StadiumsTotalCapacity': '',
          'StreetsDescription': '',
          'StreetsPaved': '',
          'StreetsUnpaved': '',
          'StreetsBridges': '',
          'SwimmingPoolsDescription': '',
          'SwimmingPoolsNumb': '',
          'SwimmingPoolsDiving': '',
          'SewerUtilityDescription': '',
          'SewerUtilityPayroll': '',
          'WaterUtilityDescription': '',
          'WaterUtilityPayroll': '',
          'WaterfrontPropertiesMiles': '',
          'WaterfrontPropertiesDescription': '',
          'WaterfrontPropertiesBoats': '',
          'WaterfrontPropertiesPiers': '',
          'WaterfrontPropertiesMarinas': '',
          'ZooDescription': '',
          'EffectiveDate': ''
        });
      } else {
        this.specificForm.patchValue({
          'ExposureSpecificID': response.exposureSpecificID,
          'RenewalApplicationID': response.renewalApplicationID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'Airports': response.airports,
          'AmusementParks': response.amusementParks,
          'ExhibitionCenter': response.exhibitionCenter,
          'BlastingOperations': response.blastingOperations,
          'Cemetery': response.cemetery,
          'ChemicalSpraying': response.chemicalSpraying,
          'DamDikes': response.damDikes,
          'DayCare': response.dayCare,
          'ElectricUtility': response.electricUtility,
          'EMTParamedics': response.emtParamedics,
          'Firefighters': response.firefighters,
          'TownFireworks': response.townFireworks,
          'GarbageRefuse': response.garbageRefuse,
          'GasUtility': response.gasUtility,
          'GolfCourses': response.golfCourses,
          'HousingProjectsTown': response.housingProjectsTown,
          'LakesReservoirs': response.lakesReservoirs,
          'LandfillSites': response.landfillSites,
          'LawFacilities': response.lawFacilities,
          'MedicalFacilities': response.medicalFacilities,
          'MowingOperations': response.mowingOperations,
          'ParkingAuthority': response.parkingAuthority,
          'ParksPlaygrounds': response.parksPlaygrounds,
          'PistolRange': response.pistolRange,
          'RaceTracks': response.raceTracks,
          'SchoolsColleges': response.schoolsColleges,
          'Skating': response.skating,
          'SkiAreas': response.skiAreas,
          'SpecialEvents': response.specialEvents,
          'Stadiums': response.stadiums,
          'Streets': response.streets,
          'SwimmingPools': response.swimmingPools,
          'SewerUtility': response.sewerUtility,
          'WaterUtility': response.waterUtility,
          'WaterfrontProperties': response.waterfrontProperties,
          'Zoo': response.zoo,
          'AirportsOwned': response.airportsOwned,
          'AirportsOwnedDescription': response.airportsOwnedDescription,
          'AmusementOwned': response.amusementOwned,
          'AmusementOwnedDescription': response.amusementOwnedDescription,
          'MechanicalAmusement': response.mechanicalAmusement,
          'ExhibitionOwned': response.exhibitionOwned,
          'ExhibitionOwnedDescription': response.exhibitionOwnedDescription,
          'FootageCapacity': response.footageCapacity,
          'BlastingOperationsDescription': response.blastingOperationsDescription,
          'CemeteryDescription': response.cemeteryDescription,
          'ChemicalSprayingDescription': response.chemicalSprayingDescription,
          'DamDikesDescription': response.damDikesDescription,
          'NumbDamDikes': response.numbDamDikes,
          'DayCareOwned': response.dayCareOwned,
          'NumbFacilities': response.numbFacilities,
          'NumbChildren': response.numbChildren,
          'ElectricUtilityDescription': response.electricUtilityDescription,
          'TotalPayroll': response.totalPayroll,
          'EMTParamedicsDescription': response.emtParamedicsDescription,
          'EMTNumbPersonnel': response.emtNumbPersonnel,
          'EMTNumbCalls': response.emtNumbCalls,
          'FirefightersDescription': response.firefightersDescription,
          'FireNumbPersonnel': response.fireNumbPersonnel,
          'FireNumbVolunteer': response.fireNumbVolunteer,
          'FireTotalPayroll': response.fireTotalPayroll,
          'TownFireWorksDescription': response.townFireWorksDescription,
          'TownFireWorksEvents': response.townFireWorksEvents,
          'GarbageRefuseDescription': response.garbageRefuseDescription,
          'GarbageRefuseCustomers': response.garbageRefuseCustomers,
          'GasUtilityDescription': response.gasUtilityDescription,
          'GolfCoursesOwned': response.golfCoursesOwned,
          'GolfCoursesDescription': response.golfCoursesDescription,
          'GolfCoursesNumb': response.golfCoursesNumb,
          'GolfCoursesSales': response.golfCoursesSales,
          'HousingProjectsTownDescription': response.housingProjectsTownDescription,
          'LakesReservoirsDescription': response.lakesReservoirsDescription,
          'LakesReservoirsNumb': response.lakesReservoirsNumb,
          'LandfillSitesOwned': response.landfillSitesOwned,
          'LandfillSitesDescription': response.landfillSitesDescription,
          'LawFacilitiesOwned': response.lawFacilitiesOwned,
          'LawFacilitiesDescription': response.lawFacilitiesDescription,
          'LawFacilitiesPayroll': response.lawFacilitiesPayroll,
          'LawFirearmsFull': response.lawFirearmsFull,
          'LawFirearmsPart': response.lawFirearmsPart,
          'LawPersonnel': response.lawPersonnel,
          'LawCells': response.lawCells,
          'LawLengthStay': response.lawLengthStay,
          'LawDogHorse': response.lawDogHorse,
          'MedicalFacilitiesOwned': response.medicalFacilitiesOwned,
          'MedicalFacilitiesDescription': response.medicalFacilitiesDescription,
          'MedicalFacilitiesBeds': response.medicalFacilitiesBeds,
          'MedicalFacilitiesPatients': response.medicalFacilitiesPatients,
          'MowingOperationsDescription': response.mowingOperationsDescription,
          'ParkingAuthorityDescription': response.parkingAuthorityDescription,
          'ParksPlaygroundsNumb': response.parksPlaygroundsNumb,
          'PistolRangeDescription': response.pistolRangeDescription,
          'RaceTracksOwned': response.raceTracksOwned,
          'SchoolsCollegesDescription': response.schoolsCollegesDescription,
          'SkatingOwned': response.skatingOwned,
          'SkatingDescription': response.skatingDescription,
          'SkiAreasDescription': response.skiAreasDescription,
          'SpecialAmusementDevices': response.specialAmusementDevices,
          'SpecialEventsDescription': response.specialEventsDescription,
          'SpecialEventsYear': response.specialEventsYear,
          'StadiumsOwned': response.stadiumsOwned,
          'StadiumsDescription': response.stadiumsDescription,
          'StadiumsCapacity5k': response.stadiumsCapacity5k,
          'StadiumsTotalCapacity': response.stadiumsTotalCapacity,
          'StreetsDescription': response.streetsDescription,
          'StreetsPaved': response.streetsPaved,
          'StreetsUnpaved': response.streetsUnpaved,
          'StreetsBridges': response.streetsBridges,
          'SwimmingPoolsDescription': response.swimmingPoolsDescription,
          'SwimmingPoolsNumb': response.swimmingPoolsNumb,
          'SwimmingPoolsDiving': response.swimmingPoolsDiving,
          'SewerUtilityDescription': response.sewerUtilityDescription,
          'SewerUtilityPayroll': response.sewerUtilityPayroll,
          'WaterUtilityDescription': response.waterUtilityDescription,
          'WaterUtilityPayroll': response.waterUtilityPayroll,
          'WaterfrontPropertiesMiles': response.waterfrontPropertiesMiles,
          'WaterfrontPropertiesDescription': response.waterfrontPropertiesDescription,
          'WaterfrontPropertiesBoats': response.waterfrontPropertiesBoats,
          'WaterfrontPropertiesPiers': response.waterfrontPropertiesPiers,
          'WaterfrontPropertiesMarinas': response.waterfrontPropertiesMarinas,
          'ZooDescription': response.zooDescription,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.ExposureSpecificID == 0) {
      this.exposuresService.add(form.value, 'AddExposureSpecifics').subscribe((response: any) => {
        if (response.error == 0) {
          this.getExposureSpecific(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateExposureSpecifics').subscribe((response: any) => {
        if (response.error == 0) {
          this.getExposureSpecific(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
