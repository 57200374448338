//Import Modules
import { Component, ChangeDetectorRef, OnInit, HostListener } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { first } from "rxjs/operators";

// Import Services
import { AuthService } from '../shared/services/auth/auth.service';
import { FreezeUpdatesService } from '../shared/services/freeze-updates/freeze-updates.service';
import { RenewalApplicationsService } from "../shared/services/renewal-applications/renewal-applications.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  constructor(private router: Router, private notifier: NotifierService, public authService: AuthService, public freezeUpdatesService: FreezeUpdatesService, private renewalApplicationsService: RenewalApplicationsService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.authService.activeAccount) {
          this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
            if (!response) {
              return;
            } else {
              this.activeMuniciaplity = response;
              this.freezeUpdatesService.getFrozenMunicipalities(response.municipalityYear).pipe(first()).subscribe({
                next: (response) => {
                  this.frozenMunicipalities = response;
                  this.enableReadOnly();
                },
                error: error => {
                  this.notifier.notify('error', 'Unable to load frozen municipalities');
                }
              });
            }
          });
        };
      };
    });
  };
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.isReadOnly && event.keyCode == 9) {
      event.preventDefault();
      return false;
    };
  };

  ngOnInit() {
    if (this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly') {
      this.isReadOnly = true;
    }
  };

  public activeMuniciaplity;
  public frozenMunicipalities;
  public isReadOnly = false;

  public logout() {
    this.authService.accountLogout().pipe(first()).subscribe({
      next: () => {
        window.location.reload();
      },
      error: error => {
        this.notifier.notify('error', 'Something went wrong please try again');
      }
    });
  };

  private enableReadOnly() {
    if (this.router.url == '/dashboard/user-profile' || this.router.url == '/dashboard/user-profile/change-password' || this.router.url == '/dashboard/users' || this.router.url == '/dashboard/freeze-updates' || this.router.url == '/dashboard/generate-year' || this.router.url == '/dashboard/risk-managers' || this.router.url == '/dashboard/departments' || this.router.url == '/dashboard/reports' || this.router.url == '/dashboard/downloads') {
      this.isReadOnly = false;
    } else if (this.frozenMunicipalities.find((item) => item.municipalityID == this.activeMuniciaplity.municipalityID && item.municipalityYear == this.activeMuniciaplity.municipalityYear && item.locked === true)) {
      this.isReadOnly = true;
    } else if (this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly') {
      this.isReadOnly = true;
    } else {
      this.isReadOnly = false
    }
  };
};
