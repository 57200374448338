<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Update Fine Art</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="updateFineArtForm" (ngSubmit)="submitUpdateFineArtForm(updateFineArtForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Art Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <!--Location -->
            <mat-label>Location</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Location">
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Year-->
            <mat-label>Year</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Year">
              <mat-error>Character length for this field is 4</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--Artist-->
            <mat-label>Artist</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Artist">
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--AppraisedValue-->
            <mat-label>Appraised Value</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="AppraisedValue">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--Description-->
            <mat-label>Description <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Description">
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Update</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
