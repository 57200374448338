//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NotifierService } from "angular-notifier";

//import Services
import { AccountService } from '../../account/service/account.service';
import { AuthService } from '../../shared/services/auth/auth.service';
import { MunicipalitiesService } from '../../shared/services/municipalities/municipalities.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  constructor(private accountService: AccountService, private authService: AuthService, private municipalitiesService: MunicipalitiesService, private notifier: NotifierService) {
    this.userProfileForm = new FormGroup({
      Id: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      FirstName: new FormControl('', Validators.required),
      Email: new FormControl('', Validators.required),
      EmailConfirmed: new FormControl({ value: false }),
      LastLoginTime: new FormControl(''),
      Municipalities: new FormControl(''),
      PhoneNumber: new FormControl(''),
      TwoFactor: new FormControl(false),
      UserRole: new FormControl('')
    });
  };

  public municipalities: any[];
  public userProfileForm: FormGroup;

  ngOnInit() {
    this.getAccountInfo();
    this.getListMunicipalitiesService();
  };

  //Get municipalities, populates the dropdown field
  getListMunicipalitiesService() {
    this.municipalitiesService.getListMunicipalities(this.authService.activeAccount.userID).pipe(first()).subscribe({
      next: (response) => {
        this.municipalities = response;
      },
      error: error => {
        this.notifier.notify('error', 'Something went wrong');
      }
    });
  };

  //Get info about user account
  getAccountInfo() {
    this.accountService.getAccountDetails(this.authService.activeAccount).pipe(first()).subscribe({
      next: (response: any) => {
        this.userProfileForm.patchValue({
          'Id': response.id,
          'LastName': response.lastName,
          'FirstName': response.firstName,
          'Email': response.email,
          'EmailConfirmed': response.emailConfirmed,
          'LastLoginTime': response.lastLoginTime,
          'Municipalities': response.municipalities.map(mun => (mun.municipalityID)),
          'PhoneNumber': response.phoneNumber,
          'UserRole': response.role,
          'TwoFactor': response.twoFactor
        });
      },
      error: error => {
        this.notifier.notify('error', 'Something went wrong');
      }
    });
  };

  //Sbumit user profile form
  submitUserProfileForm(form) {
    if (form.value.PhoneNumber == '' || form.value.PhoneNumber == null && form.value.TwoFactor == true) {
      this.notifier.notify('error', 'Please enter phone number to enable Two Factor Auth');
    } else if (form.valid) {
      form.value.Municipalities = form.value.Municipalities.join();
      this.accountService.accountEdit(form.value).pipe(first()).subscribe({
        next: (response: any) => {
          if (response.error === 0) {
            this.getAccountInfo();
            this.notifier.notify('success', response.message);
          } else {
            this.notifier.notify('error', response.message);
          }
        },
        error: error => {
          this.notifier.notify('error', 'Something went wrong');
        }
      })
    } else {
      this.notifier.notify("error", "Submitted form is invalid");
    }
  };
};
