<div class="additional-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Additional Info</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="additionalForm" (ngSubmit)="submitForm(additionalForm)">
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h5>Compliance with Regulations <br /> <span class="text-success"><small>If checked, please enter description.</small></span></h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--NonCompliance-->
          <mat-checkbox formControlName="NonCompliance">The entity and/or any premises, operations or departments within its control (e.g., utilities, jail, dams, etc.) has been cited or fined for non-compliance with local state or federal guidelines or laws</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="NonComplianceDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h5>Policies and Procedures</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EmploymentPractices-->
          <mat-checkbox formControlName="EmploymentPractices">Employment Practices</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmploymentPracticesDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--HumanResources-->
          <mat-checkbox formControlName="HumanResources">Is there a Human Resources Dept in place? If yes, total number of staff; If no, how is function handled?</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="HumanResourcesDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--JobDescriptions-->
          <mat-checkbox formControlName="JobDescriptions">Formal written job descriptions are in place for all positions</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="JobDescriptionsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--StandardizedApplication-->
          <mat-checkbox formControlName="StandardizedApplication">A formal standardized employment application is utilized</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StandardizedApplicationDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--LegalCounselReview-->
          <mat-checkbox formControlName="LegalCounselReview">It has been reviewed within last two years by legal counsel</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LegalCounselReviewDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EmploymentTesting-->
          <mat-checkbox formControlName="EmploymentTesting">Employment & pre-employment testing:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmploymentTestingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--DriverTesting-->
          <mat-checkbox formControlName="DriverTesting">Driver testing:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DriverTestingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--MVRObtained-->
          <mat-checkbox formControlName="MVRObtained">MVR (Motor Vehicle Reports) are obtained annually for all EE's driving on company business</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MVRObtainedDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--MVRRequired-->
          <mat-checkbox formControlName="MVRRequired">MVR (Motor Vehicle Reports) are a required pre-hire for all prospective EE's driving on company business</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MVRRequiredDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--WrittenConsent-->
          <mat-checkbox formControlName="WrittenConsent">Written consent is obtained from above prior to ordering MVRs</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WrittenConsentDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--DrivingStandards-->
          <mat-checkbox formControlName="DrivingStandards">Minimum driving standards/guidelines are established in policy and uniformly and consistently enforced</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DrivingStandardsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ShowEvidence-->
          <mat-checkbox formControlName="ShowEvidence">Employees using personal vehicles for approved municipality business are required to show evidence annually of liability insurance</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ShowEvidenceDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--RegulationsComplied-->
          <mat-checkbox formControlName="RegulationsComplied">The Fair Credit Reporting Act (FCRA), Driver's Privacy Protection Act (DPPA), and state statutes and regulations are complied with when making employment decisions based on driving records</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RegulationsCompliedDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--CDLPolicy-->
          <mat-checkbox formControlName="CDLPolicy">There is a written Commercial Drivers License (CDL) policy in place</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="CDLPolicyDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PostAccidentGuidelines-->
          <mat-checkbox formControlName="PostAccidentGuidelines">Post accident CDL guidelines are followed including mandatory drug testing</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PostAccidentGuidelinesDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PostMVATesting-->
          <mat-checkbox formControlName="PostMVATesting">Post-MVA testing is mandatory for all drivers involved in MVA and/or General Testing</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PostMVATestingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--GeneralTesting-->
          <mat-checkbox formControlName="GeneralTesting">General Testing</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GeneralTestingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PsychologicalTests-->
          <mat-checkbox formControlName="PsychologicalTests">Psychological tests are used to screen applicants, promote employees or for purposes of continuing employment? If yes, administered to all applicants/ departments? If no, explain</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PsychologicalTestsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ResultsReviewed-->
          <mat-checkbox formControlName="ResultsReviewed">Results are reviewed by individuals with professional training in this field</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ResultsReviewedDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PsychoInterview-->
          <mat-checkbox formControlName="PsychoInterview">Test-takers are interviewed by psychologist/psychiatrist</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PsychoInterviewDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EvalPerformance-->
          <mat-checkbox formControlName="EvalPerformance">Written performance evaluations are performed for all employees? If yes, how often?</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EvalPerformanceDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--AdviceRequired-->
          <mat-checkbox formControlName="AdviceRequired">Advice from a human resource manager and/or qualified legal counsel is required prior to terminating an employee</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AdviceRequiredDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ProhibitWrongdoing-->
          <mat-checkbox formControlName="ProhibitWrongdoing">There are formal policies prohibiting harassment and wrongdoing in the workplace</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ProhibitWrongdoingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EmploymentHandbook-->
          <mat-checkbox formControlName="EmploymentHandbook">Employment Handbook:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmploymentHandbookDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EmploymentHandbookUpdated-->
          <mat-checkbox formControlName="EmploymentHandbookUpdated">It updated is regularly</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmploymentHandbookUpdatedDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--LaborRelationsReview-->
          <mat-checkbox formControlName="LaborRelationsReview">It's reviewed by a labor relations attorney; Last reviewed:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LaborRelationsReviewDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--SignForHandbook-->
          <mat-checkbox formControlName="SignForHandbook">All employees are required to sign for the handbook</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SignForHandbookDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PracticeManual-->
          <mat-checkbox formControlName="PracticeManual">Employment Practice Manual:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PracticeManualDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--TerminationProcedures-->
          <mat-checkbox formControlName="TerminationProcedures">Employee hiring and termination procedures:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TerminationProceduresDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--GrievanceProcedures-->
          <mat-checkbox formControlName="GrievanceProcedures">Dispute or grievance procedures:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GrievanceProceduresDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EmploymentTraining-->
          <mat-checkbox formControlName="EmploymentTraining">Employment/Supervisor Training:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmploymentTrainingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--DiscriminationTraining-->
          <mat-checkbox formControlName="DiscriminationTraining">Discrimination training:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DiscriminationTrainingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EmployeeHIT-->
          <mat-checkbox formControlName="EmployeeHIT">There are procedures in place for employee hiring, interviews, and termination of employees</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmployeeHITDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EmployeeCDG-->
          <mat-checkbox formControlName="EmployeeCDG">Handling of employee complaints, disputes, grievances</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmployeeCDGDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PerformanceReviews-->
          <mat-checkbox formControlName="PerformanceReviews">Performance reviews</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PerformanceReviewsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--DisciplinaryProgram-->
          <mat-checkbox formControlName="DisciplinaryProgram">Progressive Disciplinary Program</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DisciplinaryProgramDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--TerminationHearings-->
          <mat-checkbox formControlName="TerminationHearings">Pre-Termination Hearings</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TerminationHearingsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--TimeoffPolicies-->
          <mat-checkbox formControlName="TimeoffPolicies">Timeoff policies / FMLA</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TimeoffPoliciesDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--HarassmentSO-->
          <mat-checkbox formControlName="HarassmentSO">Harassment (sexual and other):</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="HarassmentSODescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--TrainingMandatory-->
          <mat-checkbox formControlName="TrainingMandatory">Training is mandatory for all managers and supervisors</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TrainingMandatoryDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ComplaintStructure-->
          <mat-checkbox formControlName="ComplaintStructure">The municipality has an informal and formal complaint structure for employees</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ComplaintStructureDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PublicOfficials-->
          <mat-checkbox formControlName="PublicOfficials">Public Officials:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PublicOfficialsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--OfficialsActivities-->
          <mat-checkbox formControlName="OfficialsActivities">Officials engage in planning & zoning activities.</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="OfficialsActivitiesDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--OfficialsTraining-->
          <mat-checkbox formControlName="OfficialsTraining">Officials engage 'open meeting' and hearing regulations training.</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="OfficialsTrainingDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--LandStatutes-->
          <mat-checkbox formControlName="LandStatutes">Formal procedure are in place regarding requests for variance to land development statutes.</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LandStatutesDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--MasterPlan-->
          <mat-checkbox formControlName="MasterPlan">There is a written master plan for development.</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MasterPlanDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--LastRevised-->
          <mat-checkbox formControlName="LastRevised">Last revised or adopted:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LastRevisedDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--LawEnforcement-->
          <mat-checkbox formControlName="LawEnforcement">Law Enforcement:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawEnforcementDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--HiringScreening-->
          <mat-checkbox formControlName="HiringScreening">Hiring/Screening procedures (criminal investigation, psychological testing, reference check, etc):</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="HiringScreeningDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EducationRequirements-->
          <mat-checkbox formControlName="EducationRequirements">Minimum education requirements (HS, college, etc.):</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EducationRequirementsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--OperationsManual-->
          <mat-checkbox formControlName="OperationsManual">Operations manual (use of deadly force, 'hot pursuit', domestic violence, etc):</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="OperationsManualDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--TrainingManualAcademy-->
          <mat-checkbox formControlName="TrainingManualAcademy">Training manual (academy training, supervisory training use of weapons, etc):</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TrainingManualAcademyDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--JailOperations-->
          <mat-checkbox formControlName="JailOperations">Jail operations (intake procedures, suicide prevention, Separation of juveniles, inmate monitoring):</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="JailOperationsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--DeptAccreditation-->
          <mat-checkbox formControlName="DeptAccreditation">Dept. Accreditation:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DeptAccreditationDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PoliceChiefs-->
          <mat-checkbox formControlName="PoliceChiefs">NJ Association of Police Chiefs</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PoliceChiefsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--NjLeap-->
          <mat-checkbox formControlName="NjLeap">NJLEAP</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="NjLeapDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h5>Legal Counsel</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--MunicipalAttorney-->
          <mat-checkbox formControlName="MunicipalAttorney">Municipal attorney - name/firm:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MunicipalAttorneyDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EntityAttorney-->
          <mat-checkbox formControlName="EntityAttorney">The entity attorney reviews all policies and procedures manuals on a regular basis</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EntityAttorneyDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EntityAttorneyConsulted-->
          <mat-checkbox formControlName="EntityAttorneyConsulted">The entity attorney is consulted prior to any decisions to terminate employment</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EntityAttorneyConsultedDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--EntityAttorneyAttend-->
          <mat-checkbox formControlName="EntityAttorneyAttend">The entity attorney attends all meetings of planning and zoning board</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EntityAttorneyAttendDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h5>History <br /> <span class="text-success"><small>If yes, please provide a detailed narrative.</small></span></h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--GrandOfficials-->
          <mat-checkbox formControlName="GrandOfficials">Grand jury investigations or indictments of any public officials:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GrandOfficialsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ClaimImproper-->
          <mat-checkbox formControlName="ClaimImproper">Claim by any person, former employees, volunteer or job applicant alleging unfair or improper treatment regarding hiring remuneration, advancement or termination:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ClaimImproperDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ClaimViolation-->
          <mat-checkbox formControlName="ClaimViolation">Claim by any person, former employees, volunteer or job applicant alleging discrimination or violation of civil rights including sexual or the Americans with Disabilities Act (ADA):</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ClaimViolationDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ClaimZoning-->
          <mat-checkbox formControlName="ClaimZoning">Claim by any person or organization for zoning, eminent domain, inverse condemnation or denial of license:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ClaimZoningDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ClaimWrongful-->
          <mat-checkbox formControlName="ClaimWrongful">Claim alleging wrongful approval of building design/specs:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ClaimWrongfulDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--RequiredComply-->
          <mat-checkbox formControlName="RequiredComply">Currently required to comply with any judicial or administrative agreement, order, decree or judgment relating to employment:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RequiredComplyDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ContinuousClaims-->
          <mat-checkbox formControlName="ContinuousClaims">EPL/POL- There have been continuous claims-made coverage for past five years:</mat-checkbox>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ContinuousClaimsDescription" placeholder="Description" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate-->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="additionalForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
