//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';

//Import Services
import { AuthService } from '../../../shared/services/auth/auth.service';
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';

//Import Components
import { NewLocationDialogComponent } from './dialog/new-location-dialog/new-location-dialog.component';
import { UpdateLocationDialogComponent } from './dialog/update-location-dialog/update-location-dialog.component';
import { DeleteLocationDialogComponent } from './dialog/delete-location-dialog/delete-location-dialog.component';

@Component({
  selector: 'app-ust-liability',
  templateUrl: './ust-liability.component.html',
  styleUrls: ['./ust-liability.component.scss']
})

export class UstLiabilityComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.ustLiabilityForm = new FormGroup({
      TankSafeApplicatonID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      ApplicantName: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      ApplicantContact: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      ApplicantEmail: new FormControl('', [Validators.maxLength(100), Validators.email]), //STRING
      ApplicantMailingAddress: new FormControl('', [Validators.required, Validators.maxLength(250)]), //STRING
      ApplicantTelephone: new FormControl('', [Validators.required, Validators.maxLength(20)]), //STRING
      ApplicantFax: new FormControl('', Validators.maxLength(20)), //STRING
      ApplicantURL: new FormControl('', Validators.maxLength(250)), //STRING
      ApplicantDateEstablish: new FormControl('', Validators.required), //DATE
      ApplicantIs: new FormControl('', Validators.maxLength(100)), //STRING
      FEIN: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      RecoveredExpensesAmount: new FormControl('', Validators.maxLength(50)), //STRING
      EndingPeriodMonth: new FormControl('', Validators.maxLength(50)), //STRING
      EndingPeriodYear: new FormControl('', Validators.maxLength(50)), //STRING
      GrossRevenuesFiscalYear: new FormControl('', Validators.maxLength(50)), //STRING
      CoverageDate: new FormControl('', [Validators.required, Validators.maxLength(50)]), //DATE
      PolicyInception: new FormControl(false), //BOOL
      PolicyInceptionOther: new FormControl(false), //BOOL
      PolicyInceptionOtherDesc: new FormControl('', Validators.maxLength(50)), //STRING
      PerStorageTankIncidentAmount: new FormControl('', Validators.maxLength(50)), //STRING
      AggregateAmount: new FormControl('', Validators.maxLength(50)), //STRING
      DeductibleAmount: new FormControl('', Validators.maxLength(50)), //STRING
      DefenseExpenseLimit: new FormControl('', Validators.maxLength(50)),
      Ans_08: new FormControl(false), //BOOL
      Ans_09: new FormControl(false), //BOOL
      Ans_10: new FormControl(false), //BOOL
      Ans_11: new FormControl(false), //BOOL
      Ans_12_NA: new FormControl(false), //BOOL
      Ans_12: new FormControl(false), //BOOL
      Ans_13: new FormControl(false), //BOOL
      Ans_14: new FormControl(false), //BOOL
      Ans_14_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      Ans_15: new FormControl(false), //BOOL
      Ans_15_a: new FormControl(false), //BOOL
      Ans_15_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      Ans_16_NA: new FormControl(false), //BOOL
      Ans_16: new FormControl(false), //BOOL
      Ans_16_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      Ans_17: new FormControl(false), //BOOL
      Ans_17_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      Ans_18: new FormControl(false), //BOOL
      Ans_18_a: new FormControl(false), //BOOL
      Ans_18_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      Ans_19: new FormControl(false), //BOOL
      Ans_19_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      Ans_20: new FormControl(false), //BOOL
      Ans_20_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      Ans_21: new FormControl(false), //BOOL
      Ans_21_Desc: new FormControl('', Validators.maxLength(450)), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });

    //PolicyInceptionOther
    this.ustLiabilityForm.controls['PolicyInceptionOther'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('PolicyInceptionOtherDesc').enable() : this.ustLiabilityForm.get('PolicyInceptionOtherDesc').disable();
    });

    //Ans_14
    this.ustLiabilityForm.controls['Ans_14'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_14_Desc').enable() : this.ustLiabilityForm.get('Ans_14_Desc').disable();
    });

    //Ans_15
    this.ustLiabilityForm.controls['Ans_15'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_15_Desc').enable() : this.ustLiabilityForm.get('Ans_15_Desc').disable();
    });

    //Ans_16
    this.ustLiabilityForm.controls['Ans_16'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_16_Desc').enable() : this.ustLiabilityForm.get('Ans_16_Desc').disable();
    });

    //Ans_17
    this.ustLiabilityForm.controls['Ans_17'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_17_Desc').enable() : this.ustLiabilityForm.get('Ans_17_Desc').disable();
    });

    //Ans_18_a
    this.ustLiabilityForm.controls['Ans_18_a'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_18_Desc').enable() : this.ustLiabilityForm.get('Ans_18_Desc').disable();
    });

    //Ans_19
    this.ustLiabilityForm.controls['Ans_19'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_19_Desc').enable() : this.ustLiabilityForm.get('Ans_19_Desc').disable();
    });

    //Ans_20
    this.ustLiabilityForm.controls['Ans_20'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_20_Desc').enable() : this.ustLiabilityForm.get('Ans_20_Desc').disable();
    });

    //Ans_21
    this.ustLiabilityForm.controls['Ans_21'].valueChanges.subscribe(value => {
      value ? this.ustLiabilityForm.get('Ans_21_Desc').enable() : this.ustLiabilityForm.get('Ans_21_Desc').disable();
    });
  };

  public activeMunicipality;
  public ustLiabilityForm: FormGroup;

  //Ust liability table
  public displayedColumns: string[] = ["companyName", "address", "ustLocation", "astLocation", "contamination", "facilityType", "facilityTypeOther", "action"];
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.ustLiabilityForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getLiabilityUndergroundStorageTank(response);
        this.getListStorageTankLocation(response);
      }
    });
  };

  public getLiabilityUndergroundStorageTank(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetLiabilityUndergroundStorageTank').subscribe((response: any) => {
      if (!response) {
        this.ustLiabilityForm.patchValue({
          'TankSafeApplicatonID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'ApplicantName': '',
          'ApplicantContact': '',
          'ApplicantEmail': '',
          'ApplicantMailingAddress': '',
          'ApplicantTelephone': '',
          'ApplicantFax': '',
          'ApplicantURL': '',
          'ApplicantDateEstablish': '',
          'ApplicantIs': '',
          'FEIN': '',
          'RecoveredExpensesAmount': '',
          'EndingPeriodMonth': '',
          'EndingPeriodYear': '',
          'GrossRevenuesFiscalYear': '',
          'CoverageDate': '',
          'PolicyInception': false,
          'PolicyInceptionOther': false,
          'PolicyInceptionOtherDesc': '',
          'PerStorageTankIncidentAmount': '',
          'AggregateAmount': '',
          'DeductibleAmount': '',
          'DefenseExpenseLimit': '',
          'Ans_08': false,
          'Ans_09': false,
          'Ans_10': false,
          'Ans_11': false,
          'Ans_12_NA': false,
          'Ans_12': false,
          'Ans_13': false,
          'Ans_14': false,
          'Ans_14_Desc': '',
          'Ans_15': false,
          'Ans_15_a': false,
          'Ans_15_Desc': '',
          'Ans_16_NA': false,
          'Ans_16': false,
          'Ans_16_Desc': '',
          'Ans_17': false,
          'Ans_17_Desc': '',
          'Ans_18': false,
          'Ans_18_a': false,
          'Ans_18_Desc': '',
          'Ans_19': false,
          'Ans_19_Desc': '',
          'Ans_20': false,
          'Ans_20_Desc': '',
          'Ans_21': false,
          'Ans_21_Desc': '',
          'EffectiveDate': ''
        });
      } else {
        this.ustLiabilityForm.patchValue({
          'TankSafeApplicatonID': response.tankSafeApplicatonID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'PolicyInception': response.policyInception,
          'PolicyInceptionOther': response.policyInceptionOther,
          'Ans_08': response.ans_08,
          'Ans_09': response.ans_09,
          'Ans_10': response.ans_10,
          'Ans_11': response.ans_11,
          'Ans_12': response.ans_12,
          'Ans_13': response.ans_13,
          'Ans_14': response.ans_14,
          'Ans_15': response.ans_15,
          'Ans_15_a': response.ans_15_a,
          'Ans_16': response.ans_16,
          'Ans_17': response.ans_17,
          'Ans_18': response.ans_18,
          'Ans_18_a': response.ans_18_a,
          'Ans_19': response.ans_19,
          'Ans_20': response.ans_20,
          'Ans_21': response.ans_21,
          'ApplicantName': response.applicantName,
          'ApplicantEmail': response.applicantEmail,
          'ApplicantMailingAddress': response.applicantMailingAddress,
          'ApplicantTelephone': response.applicantTelephone,
          'ApplicantURL': response.applicantURL,
          'ApplicantIs': response.applicantIs,
          'ApplicantContact': response.applicantContact,
          'ApplicantFax': response.applicantFax,
          'ApplicantDateEstablish': response.applicantDateEstablish,
          'FEIN': response.fein,
          'RecoveredExpensesAmount': response.recoveredExpensesAmount,
          'EndingPeriodMonth': response.endingPeriodMonth,
          'EndingPeriodYear': response.endingPeriodYear,
          'GrossRevenuesFiscalYear': response.grossRevenuesFiscalYear,
          'CoverageDate': response.coverageDate,
          'PolicyInceptionOtherDesc': response.policyInceptionOtherDesc,
          'PerStorageTankIncidentAmount': response.perStorageTankIncidentAmount,
          'AggregateAmount': response.aggregateAmount,
          'DefenseExpenseLimit': response.defenseExpenseLimit,
          'DeductibleAmount': response.deductibleAmount,
          'Ans_14_Desc': response.ans_14_Desc,
          'Ans_15_Desc': response.ans_15_Desc,
          'Ans_16_Desc': response.ans_16_Desc,
          'Ans_17_Desc': response.ans_17_Desc,
          'Ans_18_Desc': response.ans_18_Desc,
          'Ans_19_Desc': response.ans_19_Desc,
          'Ans_20_Desc': response.ans_20_Desc,
          'Ans_21_Desc': response.Ans_21_Desc,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public getListStorageTankLocation(data) {
    this.exposuresService.getList(data.municipalityID, data.municipalityYear, 'GetListStorageTankLocationDetails').subscribe((response: any) => {
      if (!response) {
        return;
      } else if (response.length > 0) {
        this.dataSource = new MatTableDataSource(response);
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.TankSafeApplicatonID == 0) {
      this.exposuresService.add(form.value, 'AddLiabilityUndergroundStorageTank').subscribe((response: any) => {
        if (response.error == 0) {
          this.getLiabilityUndergroundStorageTank(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateLiabilityUndergroundStorageTank').subscribe((response: any) => {
        if (response.error == 0) {
          this.getLiabilityUndergroundStorageTank(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  public openNewStorageTankLocationDialog() {
    const dialogRef = this.dialog.open(NewLocationDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.add(result, 'AddStorageTankLocationDetails').subscribe((response: any) => {
          if (response.error == 0) {
            this.getListStorageTankLocation(this.activeMunicipality);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public updateStorageTankLocationService(data) {
    const dialogRef = this.dialog.open(UpdateLocationDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.update(result, 'UpdateStorageTankLocationDetails').subscribe((response: any) => {
          if (response.error == 0) {
            this.getListStorageTankLocation(this.activeMunicipality);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public deleteStorageTankLocationService(data) {
    const dialogRef = this.dialog.open(DeleteLocationDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.delete(result, 'DeleteStorageTankLocationDetails').subscribe((response: any) => {
          if (response.error == 0) {
            this.getListStorageTankLocation(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };
};
