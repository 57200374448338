<div class="locations-property-values">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Locations Property Values</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" [disabled]="!activeMunicipality" (click)="openNewDialog()">Add New Location</button>
          <button mat-raised-button type="button" color="primary" class="mb-2 ml-2" [disabled]="!activeMunicipality" (click)="exportExcel()">Export to Excel</button>
        </div>
      </div>
      <!-- Filter -->
      <div class="col-md-4">
        <mat-form-field class="field-full-width">
          <input matInput type="text" (keyup)="filterTable($event.target.value)" placeholder="Filter results">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12" style="overflow-x: scroll;">
        <table class="datatable" mat-table [dataSource]="dataSource" matSort matSortActive="street" matSortDirection="asc" [@.disabled]="true">
          <!-- street -->
          <ng-container matColumnDef="street">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Street </th>
            <td mat-cell *matCellDef="let element"> {{element.street}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- city -->
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- state -->
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
            <td mat-cell *matCellDef="let element"> {{element.state}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- zip -->
          <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Zip </th>
            <td mat-cell *matCellDef="let element"> {{element.zip}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- type -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- sqFt -->
          <ng-container matColumnDef="sqFt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SQFT </th>
            <td mat-cell *matCellDef="let element"> {{element.sqFt}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- floors -->
          <ng-container matColumnDef="floors">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Floors </th>
            <td mat-cell *matCellDef="let element"> {{element.floors}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- block -->
          <ng-container matColumnDef="block">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Block </th>
            <td mat-cell *matCellDef="let element"> {{element.block}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- lot -->
          <ng-container matColumnDef="lot">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Lot </th>
            <td mat-cell *matCellDef="let element"> {{element.lot}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- yearBuilt -->
          <ng-container matColumnDef="yearBuilt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Year Built </th>
            <td mat-cell *matCellDef="let element"> {{element.yearBuilt}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- construction -->
          <ng-container matColumnDef="construction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Construction </th>
            <td mat-cell *matCellDef="let element"> {{element.construction}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- milesFromCoast -->
          <ng-container matColumnDef="milesFromCoast">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Miles From Coast </th>
            <td mat-cell *matCellDef="let element"> {{element.milesFromCoast}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- floodZone -->
          <ng-container matColumnDef="floodZone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Flood Zone </th>
            <td mat-cell *matCellDef="let element"> {{element.floodZone}} </td>
            <td mat-footer-cell *matFooterCellDef>Total</td>
          </ng-container>
          <!-- contentsValue -->
          <ng-container matColumnDef="contentsValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Contents Value </th>
            <td mat-cell *matCellDef="let element"> {{element.contentsValue | currency}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateContentsValue() | currency}}</td>
          </ng-container>
          <!-- buildingValue -->
          <ng-container matColumnDef="buildingValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Building Value </th>
            <td mat-cell *matCellDef="let element"> {{element.buildingValue | currency}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateBuildingValue() | currency}} </td>
          </ng-container>
          <!-- total -->
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
            <td mat-cell *matCellDef="let element"> {{element.contentsValue + element.buildingValue | currency}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateBuildingTotal() | currency }}</td>
          </ng-container>
          <!-- Action -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <div style="white-space: nowrap;">
                <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openUpdateDialog(element)">mode_edit</mat-icon>
                <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteDialog(element)">delete</mat-icon>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
