<div class="crime-application-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Crime Position Bond Worksheet</h3>
          <span>Please indicate maximum exposure for each location:</span>
        </div>
      </div>
    </div>
    <div class="row">
      <!--Table-->
      <div class="col-md-12 my-5">
        <table class="datatable" mat-table [dataSource]="positionBondWorksheetDataSource" matSort>
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> Position </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="limitRequired">
            <th mat-header-cell *matHeaderCellDef> Limit Required </th>
            <td mat-cell *matCellDef="let element"> {{element.limitRequired}} </td>
          </ng-container>
          <ng-container matColumnDef="bondCopy">
            <th mat-header-cell *matHeaderCellDef> Bond Copy </th>
            <td mat-cell *matCellDef="let element"> {{element.bondCopy}} </td>
          </ng-container>
          <ng-container matColumnDef="applicationCopy">
            <th mat-header-cell *matHeaderCellDef> Application Copy </th>
            <td mat-cell *matCellDef="let element"> {{element.applicationCopy}} </td>
          </ng-container>
          <ng-container matColumnDef="applicationDate">
            <th mat-header-cell *matHeaderCellDef> Application Date </th>
            <td mat-cell *matCellDef="let element"> {{element.applicationDate | date}} </td>
          </ng-container>
          <ng-container matColumnDef="currentPolicyDuration">
            <th mat-header-cell *matHeaderCellDef> Current Policy Duration </th>
            <td mat-cell *matCellDef="let element"> {{element.currentPolicyDuration}} </td>
          </ng-container>
          <ng-container matColumnDef="asOf">
            <th mat-header-cell *matHeaderCellDef> As of </th>
            <td mat-cell *matCellDef="let element"> {{element.asOf | date}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <div style="white-space: nowrap;">
                <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="updateCrimePositonBond(element)">mode_edit</mat-icon>
                <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="deleteCrimePositonBond(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsPositionBondWorksheet"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsPositionBondWorksheet;"></tr>
        </table>
        <div class="table-button">
          <button type="button" class="mt-3" mat-raised-button color="primary" [disabled]="!activeMunicipality" (click)="openAddLocationExposureDialog()">Add Location Bond Exposure</button>
        </div>
      </div>
    </div>
  </section>
</div>
