<div class="non-owned-aircraft-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Non Owned Aircraft Liability</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="nonOownedAircraftLiabilityForm" (ngSubmit)="submitForm(nonOownedAircraftLiabilityForm)">
      <div class="row">
        <div class="col-md-12">
          <!--ApplicantName-->
          <mat-label>Applicant Name</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ApplicantName" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Address-->
          <mat-label>Address</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Address" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Applicant-->
          <mat-label>Applicant Is <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant" placeholder="" />
            <mat-error *ngIf="nonOownedAircraftLiabilityForm.controls['Applicant'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Business-->
          <mat-label>Business or Occupation of Applicant</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Business" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!--InsuranceRequestedToDate-->
          <mat-label>INSURANCE IS REQUESTED FROM <span class="field-required-symbol">*</span> </mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="InsuranceRequestedToDate" [matDatepicker]="InsuranceRequestedToDate">
            <mat-datepicker-toggle matSuffix [for]="InsuranceRequestedToDate"></mat-datepicker-toggle>
            <mat-datepicker #InsuranceRequestedToDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!--InsuranceRequestedFromDate-->
          <mat-label>INSURANCE IS REQUESTED TO <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="InsuranceRequestedFromDate" [matDatepicker]="InsuranceRequestedFromDate">
            <mat-datepicker-toggle matSuffix [for]="InsuranceRequestedFromDate"></mat-datepicker-toggle>
            <mat-datepicker #InsuranceRequestedFromDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h6>Liability Coverage</h6>
          <div class="row">
            <div class="col-md-12">
              <!--Passengers-->
              <mat-form-field class="field-full-width" appearance="fill">
                <mat-select formControlName="Passengers" placeholder="">
                  <mat-option value="Included">Included</mat-option>
                  <mat-option value="Excluded">Excluded</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--OtherCoverage-->
              <mat-checkbox formControlName="OtherCoverage">Other Coverage</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--OtherCoverageDesc-->
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="OtherCoverageDesc" placeholder="" />
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--MedicalExpence-->
              <mat-checkbox formControlName="MedicalExpence">Medical Expence</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--MedicalExpenceDesc-->
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="MedicalExpenceDesc" placeholder="" />
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6 ">
          <h6>Limits of Liability Requested</h6>
          <div class="row">
            <div class="col-md-6">
              <!--PassengersEachPersonAmt-->
              <mat-label>Each Person</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="PassengersEachPersonAmt" placeholder="" />
                <mat-error>Character length for this field is 50</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <!--PassengersEachOccurenceAmt-->
              <mat-label>Each Occurrence</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="PassengersEachOccurenceAmt" placeholder="" />
                <mat-error>Character length for this field is 50</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <!--OtherCoverageEachOccurenceAmt-->
              <mat-label>Other Coverage EachOccurence Amt</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input type="text" matInput formControlName="OtherCoverageEachOccurenceAmt" placeholder="" />
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <!--OtherCoveragePersonAmt-->
              <mat-label>Other Coverage Person Amt</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input type="text" matInput formControlName="OtherCoveragePersonAmt" placeholder="" />
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <!--MedicalExpenceAmt-->
              <mat-label>Medical Expence Amt</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="MedicalExpenceAmt" placeholder="" />
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PersonAircraft-->
          <mat-checkbox formControlName="PersonAircraft">Does applicant have directives regarding rental or charter of aircraft, or use of employee’s personal aircraft? If “Yes,” attach copies of such directives, bulletins, memos, etc., and briefly outline applicant’s policy:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--ApplicantPolicy-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ApplicantPolicy" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--HourFlown-->
          <mat-label>Describe all rental or charter of aircraft by applicant, or applicant’s employees, and describe usage including purpose of use. Names of charter operators, types of aircraft, destinations, number of flights and number of hours flown:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="HourFlown" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Seats-->
          <mat-checkbox formControlName="Seats">Do you use or anticipate using any non-owned aircraft with 25 or more seats?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--US-->
          <mat-checkbox formControlName="US">Are any flights contemplated outside the United States? If “Yes,” Where:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--USWhereDesc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="USWhereDesc" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Insured-->
          <mat-checkbox formControlName="Insured">Do you obtain a certificate of insurance from each aircraft operator naming you an additional insured?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--AirCraftOperator-->
          <mat-checkbox formControlName="AirCraftOperator">Do you require a minimum limit of liability from the aircraft operator? If “Yes,”Amount:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--AirCraftOperatorDesc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AirCraftOperatorDesc" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--ApplicantsBehalf-->
          <mat-checkbox formControlName="ApplicantsBehalf">Are employee’s personal aircraft used on applicant’s behalf? If “Yes,” Describe Usage:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--ApplicantsBehalfDesc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ApplicantsBehalfDesc" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--CertificateInsurance-->
          <mat-checkbox formControlName="CertificateInsurance">Is applicant included as an insured on employee’s aircraft insurance and require a certificate of insurance?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--LeaseAirCraft-->
          <mat-checkbox formControlName="LeaseAirCraft">Does Applicant own or lease any aircraft If “Yes,” Describe:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--LeaseAirCraftDesc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LeaseAirCraftDesc" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>PILOTS: Complete This Section (Including Items 1-5 Below) For Every Pilot Who Will Operate An Aircraft During The Policy Term Unless A Pilot Questionnaire Is Completed By The Pilot. This Section Need Not Be Completed For Airline, Charter Or Air Taxi Pilots Not Employed By The Applicant.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--Table-->
          <div class="row">
            <div class="col-md-12 my-5" style="overflow-x: scroll;">
              <table class="datatable" mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="nameofPilot">
                  <th mat-header-cell *matHeaderCellDef> Name Of Pilot </th>
                  <td mat-cell *matCellDef="let element"> {{element.nameofPilot}} </td>
                </ng-container>
                <ng-container matColumnDef="dob">
                  <th mat-header-cell *matHeaderCellDef> DOB </th>
                  <td mat-cell *matCellDef="let element"> {{element.dob}} </td>
                </ng-container>
                <ng-container matColumnDef="stud">
                  <th mat-header-cell *matHeaderCellDef> Stud </th>
                  <td mat-cell *matCellDef="let element"> {{element.stud}} </td>
                </ng-container>
                <ng-container matColumnDef="pvt">
                  <th mat-header-cell *matHeaderCellDef> Pvt </th>
                  <td mat-cell *matCellDef="let element"> {{element.pvt}} </td>
                </ng-container>
                <ng-container matColumnDef="coml">
                  <th mat-header-cell *matHeaderCellDef> Coml </th>
                  <td mat-cell *matCellDef="let element"> {{element.coml}} </td>
                </ng-container>
                <ng-container matColumnDef="asel">
                  <th mat-header-cell *matHeaderCellDef> ASEL </th>
                  <td mat-cell *matCellDef="let element"> {{element.asel}} </td>
                </ng-container>
                <ng-container matColumnDef="amel">
                  <th mat-header-cell *matHeaderCellDef> AMEL </th>
                  <td mat-cell *matCellDef="let element"> {{element.amel}} </td>
                </ng-container>
                <ng-container matColumnDef="instrumt">
                  <th mat-header-cell *matHeaderCellDef> Instrumt </th>
                  <td mat-cell *matCellDef="let element"> {{element.instrumt}} </td>
                </ng-container>
                <ng-container matColumnDef="atp">
                  <th mat-header-cell *matHeaderCellDef> ATP </th>
                  <td mat-cell *matCellDef="let element"> {{element.atp}} </td>
                </ng-container>
                <ng-container matColumnDef="other">
                  <th mat-header-cell *matHeaderCellDef> Other </th>
                  <td mat-cell *matCellDef="let element"> {{element.other}} </td>
                </ng-container>
                <ng-container matColumnDef="dateLastPhysical">
                  <th mat-header-cell *matHeaderCellDef> DateLastPhysical </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateLastPhysical}} </td>
                </ng-container>
                <ng-container matColumnDef="class">
                  <th mat-header-cell *matHeaderCellDef> Class </th>
                  <td mat-cell *matCellDef="let element"> {{element.class}} </td>
                </ng-container>
                <ng-container matColumnDef="total">
                  <th mat-header-cell *matHeaderCellDef> Total </th>
                  <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                </ng-container>
                <ng-container matColumnDef="retractGear">
                  <th mat-header-cell *matHeaderCellDef> RetractGear </th>
                  <td mat-cell *matCellDef="let element"> {{element.retractGear}} </td>
                </ng-container>
                <ng-container matColumnDef="multiEngine">
                  <th mat-header-cell *matHeaderCellDef> MultiEngine </th>
                  <td mat-cell *matCellDef="let element"> {{element.multiEngine}} </td>
                </ng-container>
                <ng-container matColumnDef="last90days">
                  <th mat-header-cell *matHeaderCellDef> Last90days </th>
                  <td mat-cell *matCellDef="let element"> {{element.last90days}} </td>
                </ng-container>
                <ng-container matColumnDef="last12Mos">
                  <th mat-header-cell *matHeaderCellDef> Last 12Mos </th>
                  <td mat-cell *matCellDef="let element"> {{element.last12Mos}} </td>
                </ng-container>
                <ng-container matColumnDef="last12MosPilot">
                  <th mat-header-cell *matHeaderCellDef> Last12MosPilot </th>
                  <td mat-cell *matCellDef="let element"> {{element.last12MosPilot}} </td>
                </ng-container>
                <ng-container matColumnDef="next12MosPilot">
                  <th mat-header-cell *matHeaderCellDef> Next12MosPilot </th>
                  <td mat-cell *matCellDef="let element"> {{element.next12MosPilot}} </td>
                </ng-container>
                <ng-container matColumnDef="faaCrtNoPilot">
                  <th mat-header-cell *matHeaderCellDef> faaCrtNoPilot </th>
                  <td mat-cell *matCellDef="let element"> {{element.faaCrtNoPilot}} </td>
                </ng-container>
                <ng-container matColumnDef="dateLastBiennialRev">
                  <th mat-header-cell *matHeaderCellDef> DateLastBiennialRev </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateLastBiennialRev}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-raised-button class="mr-1" type="button" color="primary" (click)="updateQuestionnairePilotService(element)">Edit</button>
                    <button mat-raised-button class="ml-1" type="button" color="warn" (click)="deleteQuestionnairePilotService(element)">Delete</button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <div class="table-button">
                <button type="button" class="my-3" mat-raised-button color="primary" [disabled]="nonOownedAircraftLiabilityForm.disabled" (click)="openNewPilotDialog()">Add New Pilot </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>EXPLAIN CIRCUMSTANCES IF:</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PhysImpairmnts1a-->
          <mat-label>1. Any pilots named above have any; (a) physical impairments,</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PhysImpairmnts1a" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--MedicalCertificates1b-->
          <mat-label>(b) waivers, limitations, or conditions attached to their medical certificates</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MedicalCertificates1b" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--SuspendedorRevkd-->
          <mat-label>2. An FAA, Military, or other pilot certificate held by any pilot named above has ever been suspended or revoked</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SuspendedorRevkd" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--AviationRegulation-->
          <mat-label>3. Any pilot above has ever been cited for violation of any aviation regulation in any country</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AviationRegulation" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--AircrafAccdnt-->
          <mat-label>4. Any pilot named above has ever been involved in any aircraft accident</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AircrafAccdnt" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Intoxicated-->
          <mat-label>5. Any pilot named above has ever been convicted of or pleaded guilty to a felony or driving while intoxicated</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Intoxicated" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>PLEASE COMPLETE THE FOLLOWING ON THE AIRCRAFT INSURANCE CURRENTLY IN FORCE FOR THE APPLICANT:</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 align-self-center">
          <span>Applicant's Aircraft Liability/Hull</span>
        </div>
        <div class="col-md-3">
          <!--AircraftLiabilityHullCmp-->
          <mat-label>NAME OF COMPANY</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AircraftLiabilityHullCmp" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--ApplicntAircraftLiabilityHullLiabity-->
          <mat-label>LIMIT OF LIABILITY</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ApplicntAircraftLiabilityHullLiabity" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--ApplicntAircraftLiabilityExpiratDate-->
          <mat-label>EXPIRATION DATE</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ApplicntAircraftLiabilityExpiratDate" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 align-self-center">
          <span>Employee's Aircraft Liability/Hull (if known)</span>
        </div>
        <div class="col-md-3">
          <!--EmplyAircraftLiabilityHullLiabity-->
          <mat-label>NAME OF COMPANY</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmplyAircraftLiabilityHullLiabity" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--EmplyAircraftLiabilityHullCmp-->
          <mat-label>LIMIT OF LIABILITY</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmplyAircraftLiabilityHullCmp" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--EmplyAircraftLiabilityExpiratDate-->
          <mat-label>EXPIRATION DATE</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmplyAircraftLiabilityExpiratDate" placeholder="" />
            <mat-error>Character length for this field is 20</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>THE FOLLOWING INFORMATION IS TO BE FURNISHED BY APPLICANT WHO IS NOT AN INDIVIDUAL</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--NoofEmply-->
          <mat-label>Total number of employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="NoofEmply" placeholder="" />
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--RegularlyTravlEmplymnt-->
          <mat-label>How many of these employees regularly travel in the course of their employment</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RegularlyTravlEmplymnt" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Subsidiaries-->
          <mat-label>List location of applicant's branch offices and subsidiaries</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Subsidiaries" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--NameAgentOrBroker-->
          <mat-label>Name of Agent or Broker:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="NameAgentOrBroker" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--AgentOrBrokerAddress-->
          <mat-label>Address:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AgentOrBrokerAddress" placeholder="" />
            <mat-error>Character length for this field is 250</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <!--Broker-->
          <mat-checkbox formControlName="Broker">Broker</mat-checkbox>
        </div>
        <div class="col-md-2">
          <!--Agent-->
          <mat-checkbox formControlName="Agent">Agent</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Licenseheld-->
          <mat-label>Global Aerospace Member insurance company in which agency license is held</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Licenseheld" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate-->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="nonOownedAircraftLiabilityForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
