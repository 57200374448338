<div class="vehicles-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Vehicle Information</h3>
          <span>Summary and updated vehicle by type, as per schedule B.</span>
        </div>
      </div>
    </div>
    <!--Table-->
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table class="datatable" mat-table [dataSource]="dataSource.data">
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef> Total {{dataSource.total}}</th>
            <td mat-cell *matCellDef="let element"> {{element.total}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
    <form class="mt-3" [formGroup]="vehicleForm" (ngSubmit)="submitForm(vehicleForm)">
      <!--Transit System-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="TransitSystem">Does the Entity operate a transit system?</mat-checkbox>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="vehicleForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
