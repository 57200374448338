//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

//Import Services
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {
  constructor(private notifier: NotifierService, private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService) {
    this.propertyForm = new FormGroup({
      MunicipalityID: new FormControl(0), //INT
      PropertyAppraisal: new FormControl(''), //STRING
      PropertyAppr3Yr: new FormControl(false) //BOOL
    });
  };

  public activeMunicipality;
  public propertyForm: FormGroup;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.propertyForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getMiscellaneous(response);
      }
    });
  };

  public getMiscellaneous(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetProperty').subscribe((response: any) => {
      this.propertyForm.patchValue({
        'MunicipalityID': response.municipalityID,
        'PropertyAppraisal': response.propertyAppraisal,
        'PropertyAppr3Yr': response.propertyAppr3Yr
      });
    });
  };

  public submitForm(form) {
    if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateProperty').subscribe((response: any) => {
        if (response.error == 0) {
          this.getMiscellaneous(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
