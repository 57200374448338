//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
//Import Components
import { NewPilotDialogComponent } from './dialog/new-pilot-dialog/new-pilot-dialog.component';
import { UpdatePilotDialogComponent } from './dialog/update-pilot-dialog/update-pilot-dialog.component';
import { DeletePilotDialogComponent } from './dialog/delete-pilot-dialog/delete-pilot-dialog.component';

//Import Services
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-non-owned-aircraft-liability',
  templateUrl: './non-owned-aircraft-liability.component.html',
  styleUrls: ['./non-owned-aircraft-liability.component.scss']
})
export class NonOwnedAircraftLiabilityComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.nonOownedAircraftLiabilityForm = new FormGroup({
      LiabilityNonOwnedAircraft_yearId: new FormControl(0), //INT
      LiabilityNonOwnedAircraftID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      ApplicantName: new FormControl('', Validators.maxLength(100)), //STRING
      Address: new FormControl('', Validators.maxLength(100)), //STRING
      Business: new FormControl('', Validators.maxLength(100)), //STRING
      Applicant: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      InsuranceRequestedFromDate: new FormControl('', Validators.required), //DATE
      InsuranceRequestedToDate: new FormControl('', Validators.required), //DATE
      Passengers: new FormControl(''), //STRING
      PassengersEachPersonAmt: new FormControl('', Validators.maxLength(50)), //STRING
      PassengersEachOccurenceAmt: new FormControl('', Validators.maxLength(50)), //STRING
      OtherCoverage: new FormControl(false), //BOOL
      OtherCoverageDesc: new FormControl('', Validators.maxLength(100)), //STRING
      OtherCoveragePersonAmt: new FormControl('', Validators.maxLength(100)), //STRING
      OtherCoverageEachOccurenceAmt: new FormControl('', Validators.maxLength(100)), //STRING
      MedicalExpence: new FormControl(false), //BOOL
      MedicalExpenceDesc: new FormControl('', Validators.maxLength(100)), //STRING
      MedicalExpenceAmt: new FormControl('', Validators.maxLength(100)), //STRING
      PersonAircraft: new FormControl(false), //BOOL
      ApplicantPolicy: new FormControl('', Validators.maxLength(100)), //STRING
      HourFlown: new FormControl('', Validators.maxLength(100)), //STRING
      Seats: new FormControl(false), //BOOL
      US: new FormControl(false), //BOOL
      USWhereDesc: new FormControl('', Validators.maxLength(100)), //STRING
      Insured: new FormControl(false), //BOOL
      AirCraftOperator: new FormControl(false), //BOOL
      AirCraftOperatorDesc: new FormControl('', Validators.maxLength(50)), //STRING
      ApplicantsBehalf: new FormControl(false), //BOOL
      ApplicantsBehalfDesc: new FormControl('', Validators.maxLength(100)), //STRING
      CertificateInsurance: new FormControl(false), //BOOL
      LeaseAirCraft: new FormControl(false), //BOOL
      LeaseAirCraftDesc: new FormControl('', Validators.maxLength(100)), //STRING
      AircraftLiabilityHullCmp: new FormControl('', Validators.maxLength(50)), //STRING
      ApplicntAircraftLiabilityHullLiabity: new FormControl('', Validators.maxLength(50)), //STRING
      ApplicntAircraftLiabilityExpiratDate: new FormControl('', Validators.maxLength(50)), //DATE
      EmplyAircraftLiabilityHullLiabity: new FormControl('', Validators.maxLength(50)), //STRING
      EmplyAircraftLiabilityExpiratDate: new FormControl('', Validators.maxLength(20)), //DATE
      EmplyAircraftLiabilityHullCmp: new FormControl('', Validators.maxLength(50)), //STRING
      NoofEmply: new FormControl(0, Validators.required), //INT
      RegularlyTravlEmplymnt: new FormControl('', Validators.maxLength(50)), //STRING
      Subsidiaries: new FormControl('', Validators.maxLength(50)), //STRING
      NameAgentOrBroker: new FormControl('', Validators.maxLength(100)), //STRING
      AgentOrBrokerAddress: new FormControl('', Validators.maxLength(250)), //STRING
      Broker: new FormControl(false), //BOOL
      Agent: new FormControl(false), //BOOL
      Licenseheld: new FormControl('', Validators.maxLength(50)), //STRING
      PhysImpairmnts1a: new FormControl('', Validators.maxLength(50)), //STRING
      MedicalCertificates1b: new FormControl('', Validators.maxLength(50)), //STRING
      SuspendedorRevkd: new FormControl('', Validators.maxLength(50)), //STRING
      AviationRegulation: new FormControl('', Validators.maxLength(50)), //STRING
      AircrafAccdnt: new FormControl('', Validators.maxLength(50)), //STRING
      Intoxicated: new FormControl('', Validators.maxLength(50)), //STRING
      EffectiveDate: new FormControl('', [Validators.required, Validators.maxLength(50)]) //DATE
    });

    //OtherCoverage
    this.nonOownedAircraftLiabilityForm.controls['OtherCoverage'].valueChanges.subscribe(value => {
      value ? this.nonOownedAircraftLiabilityForm.get('OtherCoverageDesc').enable() : this.nonOownedAircraftLiabilityForm.get('OtherCoverageDesc').disable();
    });

    //PersonAircraft
    this.nonOownedAircraftLiabilityForm.controls['PersonAircraft'].valueChanges.subscribe(value => {
      value ? this.nonOownedAircraftLiabilityForm.get('ApplicantPolicy').enable() : this.nonOownedAircraftLiabilityForm.get('ApplicantPolicy').disable();
    });

    //US
    this.nonOownedAircraftLiabilityForm.controls['US'].valueChanges.subscribe(value => {
      value ? this.nonOownedAircraftLiabilityForm.get('USWhereDesc').enable() : this.nonOownedAircraftLiabilityForm.get('USWhereDesc').disable();
    });

    //AirCraftOperator
    this.nonOownedAircraftLiabilityForm.controls['AirCraftOperator'].valueChanges.subscribe(value => {
      value ? this.nonOownedAircraftLiabilityForm.get('AirCraftOperatorDesc').enable() : this.nonOownedAircraftLiabilityForm.get('AirCraftOperatorDesc').disable();
    });

    //AirCraftApplicantsBehalfOperator
    this.nonOownedAircraftLiabilityForm.controls['ApplicantsBehalf'].valueChanges.subscribe(value => {
      value ? this.nonOownedAircraftLiabilityForm.get('ApplicantsBehalfDesc').enable() : this.nonOownedAircraftLiabilityForm.get('ApplicantsBehalfDesc').disable();
    });

    //LeaseAirCraft
    this.nonOownedAircraftLiabilityForm.controls['LeaseAirCraft'].valueChanges.subscribe(value => {
      value ? this.nonOownedAircraftLiabilityForm.get('LeaseAirCraftDesc').enable() : this.nonOownedAircraftLiabilityForm.get('LeaseAirCraftDesc').disable();
    });
  };

  public activeMunicipality;
  public nonOownedAircraftLiabilityForm: FormGroup;
  public displayedColumns: string[] = ["nameofPilot", "dob", "stud", "pvt", "coml", "asel", "amel", "instrumt", "atp", "other", "dateLastPhysical", "class", "total", "retractGear", "multiEngine", "last90days", "last12Mos", "last12MosPilot", "next12MosPilot", "faaCrtNoPilot", "dateLastBiennialRev", "action"];
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.nonOownedAircraftLiabilityForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getLiabilityNonOwnedAircraft(response);
        this.getListQuestionnairePilot(response);
      }
    });
  };

  public getLiabilityNonOwnedAircraft(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetLiabilityNonOwnedAircraft').subscribe((response: any) => {
      if (!response) {
        this.nonOownedAircraftLiabilityForm.patchValue({
          'LiabilityNonOwnedAircraft_yearId': 0,
          'LiabilityNonOwnedAircraftID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'ApplicantName': '',
          'Address': '',
          'Business': '',
          'Applicant': '',
          'InsuranceRequestedFromDate': '',
          'InsuranceRequestedToDate': '',
          'Passengers': '',
          'PassengersEachPersonAmt': '',
          'PassengersEachOccurenceAmt': '',
          'OtherCoverage': false,
          'OtherCoverageDesc': '',
          'OtherCoveragePersonAmt': '',
          'OtherCoverageEachOccurenceAmt': '',
          'MedicalExpence': false,
          'MedicalExpenceDesc': '',
          'MedicalExpenceAmt': '',
          'PersonAircraft': false,
          'ApplicantPolicy': '',
          'HourFlown': '',
          'Seats': false,
          'US': false,
          'USWhereDesc': '',
          'Insured': false,
          'AirCraftOperator': false,
          'AirCraftOperatorDesc': '',
          'ApplicantsBehalf': false,
          'ApplicantsBehalfDesc': '',
          'CertificateInsurance': false,
          'LeaseAirCraft': false,
          'LeaseAirCraftDesc': '',
          'AircraftLiabilityHullCmp': '',
          'ApplicntAircraftLiabilityHullLiabity': '',
          'ApplicntAircraftLiabilityExpiratDate': '',
          'EmplyAircraftLiabilityHullLiabity': '',
          'EmplyAircraftLiabilityExpiratDate': '',
          'EmplyAircraftLiabilityHullCmp': '',
          'NoofEmply': 0,
          'RegularlyTravlEmplymnt': '',
          'Subsidiaries': '',
          'NameAgentOrBroker': '',
          'AgentOrBrokerAddress': '',
          'Broker': false,
          'Agent': false,
          'Licenseheld': '',
          'PhysImpairmnts1a': '',
          'MedicalCertificates1b': '',
          'SuspendedorRevkd': '',
          'AviationRegulation': '',
          'AircrafAccdnt': '',
          'Intoxicated': '',
          'EffectiveDate': ''
        });
      }
      else {
        this.nonOownedAircraftLiabilityForm.patchValue({
          'LiabilityNonOwnedAircraft_yearId': response.liabilityNonOwnedAircraft_yearId,
          'LiabilityNonOwnedAircraftID': response.liabilityNonOwnedAircraftID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'ApplicantName': response.applicantName,
          'Address': response.address,
          'Business': response.business,
          'Applicant': response.applicant,
          'InsuranceRequestedFromDate': response.insuranceRequestedFromDate,
          'InsuranceRequestedToDate': response.insuranceRequestedToDate,
          'Passengers': response.passengers,
          'PassengersEachPersonAmt': response.passengersEachPersonAmt,
          'PassengersEachOccurenceAmt': response.passengersEachOccurenceAmt,
          'OtherCoverage': response.otherCoverage,
          'OtherCoverageDesc': response.otherCoverageDesc,
          'OtherCoveragePersonAmt': response.otherCoveragePersonAmt,
          'OtherCoverageEachOccurenceAmt': response.otherCoverageEachOccurenceAmt,
          'MedicalExpence': response.medicalExpence,
          'MedicalExpenceDesc': response.medicalExpenceDesc,
          'MedicalExpenceAmt': response.medicalExpenceAmt,
          'PersonAircraft': response.personAircraft,
          'ApplicantPolicy': response.applicantPolicy,
          'HourFlown': response.hourFlown,
          'Seats': response.seats,
          'US': response.us,
          'USWhereDesc': response.usWhereDesc,
          'Insured': response.insured,
          'AirCraftOperator': response.airCraftOperator,
          'AirCraftOperatorDesc': response.airCraftOperatorDesc,
          'ApplicantsBehalf': response.applicantsBehalf,
          'ApplicantsBehalfDesc': response.applicantsBehalfDesc,
          'CertificateInsurance': response.certificateInsurance,
          'LeaseAirCraft': response.leaseAirCraft,
          'LeaseAirCraftDesc': response.leaseAirCraftDesc,
          'AircraftLiabilityHullCmp': response.aircraftLiabilityHullCmp,
          'ApplicntAircraftLiabilityHullLiabity': response.applicntAircraftLiabilityHullLiabity,
          'ApplicntAircraftLiabilityExpiratDate': response.applicntAircraftLiabilityExpiratDate,
          'EmplyAircraftLiabilityHullLiabity': response.emplyAircraftLiabilityHullLiabity,
          'EmplyAircraftLiabilityExpiratDate': response.emplyAircraftLiabilityExpiratDate,
          'EmplyAircraftLiabilityHullCmp': response.emplyAircraftLiabilityHullCmp,
          'NoofEmply': response.noofEmply,
          'RegularlyTravlEmplymnt': response.regularlyTravlEmplymnt,
          'Subsidiaries': response.subsidiaries,
          'NameAgentOrBroker': response.nameAgentOrBroker,
          'AgentOrBrokerAddress': response.agentOrBrokerAddress,
          'Broker': response.broker,
          'Agent': response.agent,
          'Licenseheld': response.licenseheld,
          'PhysImpairmnts1a': response.physImpairmnts1a,
          'MedicalCertificates1b': response.medicalCertificates1b,
          'SuspendedorRevkd': response.suspendedorRevkd,
          'AviationRegulation': response.aviationRegulation,
          'AircrafAccdnt': response.aircrafAccdnt,
          'Intoxicated': response.intoxicated,
          'EffectiveDate': response.effectiveDate
        });
      };
    });
  };

  public getListQuestionnairePilot(data) {
    this.exposuresService.getList(data.municipalityID, data.municipalityYear, 'GetListQuestionnairePilot').subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.dataSource = new MatTableDataSource(response);
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.LiabilityNonOwnedAircraftID == 0 && form.value.LiabilityNonOwnedAircraft_yearId == 0) {
      this.exposuresService.add(form.getRawValue(), 'AddLiabilityNonOwnedAircraft').subscribe((response: any) => {
        if (response.error == 0) {
          this.notifier.notify('success', 'New record created successfully');
        }
        else {
          this.notifier.notify("error", response.message);
        }
      });
    } else if (form.valid) {
      this.exposuresService.update(form.getRawValue(), 'UpdateLiabilityNonOwnedAircraft').subscribe((response: any) => {
        if (response.error == 0) {
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  public openNewPilotDialog() {
    const dialogRef = this.dialog.open(NewPilotDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.exposuresService.add(result, 'AddQuestionnairePilot').subscribe((response: any) => {
          if (response.error == 0) {
            this.getListQuestionnairePilot(this.activeMunicipality);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify("error", response.message);
          }
        })
      }
    });
  };

  public updateQuestionnairePilotService(data) {
    const dialogRef = this.dialog.open(UpdatePilotDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.update(result, 'UpdateQuestionnairePilot').subscribe((response: any) => {
          if (response.error == 0) {
            this.getListQuestionnairePilot(this.activeMunicipality);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public deleteQuestionnairePilotService(data) {

    const dialogRef = this.dialog.open(DeletePilotDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.delete(result, 'DeleteQuestionnairePilot').subscribe((response: any) => {
          if (response.error == 0) {
            this.getListQuestionnairePilot(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

};
