//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-add-new-year-dialog',
  templateUrl: './add-new-year-dialog.component.html',
  styleUrls: ['./add-new-year-dialog.component.scss']
})
export class AddNewYearDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddNewYearDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.generateNewYearForm = new FormGroup({
      MunicipalityID: new FormControl(null, Validators.required),
      MunicipalityYear: new FormControl('', [Validators.required, Validators.minLength(4)])
    });
  };

  public generateNewYearForm: FormGroup;

  ngOnInit() {
    this.generateNewYearForm.patchValue({
      'MunicipalityID': this.data.id
    });
  };

  //Close modal and pass form data to controller
  submitGenerateNewYearForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };
}
