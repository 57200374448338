//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { FormValidator } from '../../../../shared/pipes/FormValidator';
import { MatTableDataSource } from '@angular/material/table';

//Import Services
import { RenewalApplicationsService } from '../../../../shared/services/renewal-applications/renewal-applications.service';
import { ExposuresService } from '../../../../shared/services/exposures/exposures.service';
import { AuthService } from '../../../../shared/services/auth/auth.service';

//Import Components
import { NewLocationExposureDialogComponent } from './dialog/new-location-exposure-dialog/new-location-exposure-dialog.component';
import { NewLossExperienceDialogComponent } from './dialog/new-loss-experience-dialog/new-loss-experience-dialog.component';

import { DeleteLocationExposureDialogComponent } from './dialog/delete-location-exposure-dialog/delete-location-exposure-dialog.component';
import { DeleteLossExperienceDialogComponent } from './dialog/delete-loss-experience-dialog/delete-loss-experience-dialog.component';

import { UpdateLocationExposureDialogComponent } from './dialog/update-location-exposure-dialog/update-location-exposure-dialog.component';
import { UpdateLossExperienceDialogComponent } from './dialog/update-loss-experience-dialog/update-loss-experience-dialog.component';

@Component({
  selector: 'app-crime-application',
  templateUrl: './crime-application.component.html',
  styleUrls: ['./crime-application.component.scss']
})
export class CrimeApplicationComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.crimeApplicationForm = new FormGroup({
      CrimeApplicationID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      ApplicationName: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      ApplicationAddress: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      SICCode: new FormControl('', Validators.maxLength(10)), //STRING
      City: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      State: new FormControl('', Validators.compose([Validators.minLength(2), Validators.maxLength(2), Validators.required, FormValidator.patternValidator(/^[A-Z]*$/, { isCapital: true })])), //STRING
      Zip: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required, FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])), //STRING
      BusinessActivity: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      TaxReceipts: new FormControl(0, Validators.required), //INT
      PolicyTerm: new FormControl('', Validators.maxLength(50)), //STRING
      Organization: new FormControl('', Validators.maxLength(50)), //STRING
      CovOLimits: new FormControl(0, Validators.required), //INT
      CovBLimits: new FormControl(0, Validators.required), //INT
      CovCLimits: new FormControl(0, Validators.required), //INT
      CovDLimits: new FormControl(0, Validators.required), //INT
      CovFLimits: new FormControl(0, Validators.required), //INT
      OtherLimits: new FormControl(0, Validators.required), //INT
      Deductible: new FormControl(0, Validators.required), //INT
      PriorInsurer: new FormControl('', Validators.maxLength(50)), //STRING
      PriorLimit: new FormControl(0, Validators.required), //INT
      PriorDeductible: new FormControl(0, Validators.required), //INT
      EntityExclude: new FormControl('', Validators.maxLength(250)), //STRING
      Statutory: new FormControl('', Validators.maxLength(250)), //STRING
      ObligeeName: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      ObligeeAddress: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      TotalEmployees: new FormControl(0, Validators.required), //INT 
      TotalPolicemen: new FormControl(0, Validators.required), //INT 
      TotalFiremen: new FormControl(0, Validators.required), //INT 
      OtherLocations: new FormControl('', Validators.maxLength(250)), //STRING
      DateLastAudit: new FormControl('', Validators.required), //DATE
      RenderName: new FormControl('', Validators.maxLength(50)), //STRING
      DutyCov: new FormControl(false), //BOOL
      Treasurer: new FormControl(false), //BOOL
      TaxCollector: new FormControl(false), //BOOL
      OtherBonded: new FormControl(false), //BOOL
      IsBankruptcy: new FormControl(false), //BOOL
      LossExperience: new FormControl(false), //BOOL
      ReconDeposit: new FormControl(false), //BOOL
      ReconWithdraw: new FormControl(false), //BOOL
      ReconSignCks: new FormControl(false), //BOOL
      IndependentAudit: new FormControl(false), //BOOL
      AuditRendered: new FormControl(false), //BOOL
      AuditDiscrepencies: new FormControl(false), //BOOL
      InternalAuditDept: new FormControl(false), //BOOL
      AllLocationAudit: new FormControl(false), //BOOL
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });

    //DutyCov
    this.crimeApplicationForm.controls['DutyCov'].valueChanges.subscribe(value => {
      value ? this.crimeApplicationForm.get('EntityExclude').enable() : this.crimeApplicationForm.get('EntityExclude').disable();
    });

    //OtherBonded
    this.crimeApplicationForm.controls['OtherBonded'].valueChanges.subscribe(value => {
      value ? this.crimeApplicationForm.get('Statutory').enable() : this.crimeApplicationForm.get('Statutory').disable();
    });

    //OtherBonded
    this.crimeApplicationForm.controls['OtherBonded'].valueChanges.subscribe(value => {
      value ? this.crimeApplicationForm.get('Statutory').enable() : this.crimeApplicationForm.get('Statutory').disable();
    });

    //ReconDeposit
    this.crimeApplicationForm.controls['ReconDeposit'].valueChanges.subscribe(value => {
      value ? this.crimeApplicationForm.get('ReconWithdraw').enable() : this.crimeApplicationForm.get('ReconWithdraw').disable();
      value ? this.crimeApplicationForm.get('ReconSignCks').enable() : this.crimeApplicationForm.get('ReconSignCks').disable();
    });

    //InternalAuditDept
    this.crimeApplicationForm.controls['InternalAuditDept'].valueChanges.subscribe(value => {
      value ? this.crimeApplicationForm.get('RenderName').enable() : this.crimeApplicationForm.get('RenderName').disable();
    });
  };

  public activeMuniciaplity;
  public crimeApplicationForm: FormGroup;

  //Loss experience table
  public displayedColumnsLossExperience: string[] = ["lossDate", "lossDescription", "correctiveAction", "totalAmount", "action"];
  public lossExperienceDataSource;

  public displayedColumnsLocationExposure: string[] = ["location", "cash", "retailChecks", "creditCardReceipts", "safe", "action"];
  public locationExposureDataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.crimeApplicationForm.disable();
        return;
      } else {
        this.activeMuniciaplity = response;
        this.getCrimeApplication(response);
        this.getCrimeLossList(response);
        this.getCrimeLocationList(response);
      }
    });
  };

  //Crime Application

  public getCrimeApplication(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetCrimeApplication').subscribe((response: any) => {
      if (!response) {
        this.crimeApplicationForm.patchValue({
          'CrimeApplicationID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'ApplicationName': '',
          'ApplicationAddress': '',
          'SICCode': '',
          'City': '',
          'State': '',
          'Zip': '',
          'BusinessActivity': '',
          'TaxReceipts': 0,
          'PolicyTerm': '',
          'Organization': '',
          'CovOLimits': 0,
          'CovBLimits': 0,
          'CovCLimits': 0,
          'CovDLimits': 0,
          'CovFLimits': 0,
          'OtherLimits': 0,
          'Deductible': 0,
          'PriorInsurer': '',
          'PriorLimit': 0,
          'PriorDeductible': 0,
          'EntityExclude': '',
          'Statutory': '',
          'ObligeeName': '',
          'ObligeeAddress': '',
          'TotalEmployees': 0,
          'TotalPolicemen': 0,
          'TotalFiremen': 0,
          'OtherLocations': '',
          'DateLastAudit': '',
          'RenderName': '',
          'DutyCov': false,
          'Treasurer': false,
          'TaxCollector': false,
          'OtherBonded': false,
          'IsBankruptcy': false,
          'LossExperience': false,
          'ReconDeposit': false,
          'ReconWithdraw': false,
          'ReconSignCks': false,
          'IndependentAudit': false,
          'AuditRendered': false,
          'AuditDiscrepencies': false,
          'InternalAuditDept': false,
          'AllLocationAudit': false,
          'EffectiveDate': ''
        });
      } else {
        this.crimeApplicationForm.patchValue({
          'CrimeApplicationID': response.crimeApplicationID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'ApplicationName': response.applicationName,
          'ApplicationAddress': response.applicationAddress,
          'SICCode': response.sicCode,
          'Address': response.address,
          'City': response.city,
          'State': response.state,
          'Zip': response.zip,
          'BusinessActivity': response.businessActivity,
          'TaxReceipts': response.taxReceipts,
          'PolicyTerm': response.policyTerm,
          'Organization': response.organization,
          'CovOLimits': response.covOLimits,
          'CovBLimits': response.covBLimits,
          'CovCLimits': response.covCLimits,
          'CovDLimits': response.covDLimits,
          'CovFLimits': response.covFLimits,
          'OtherLimits': response.otherLimits,
          'Deductible': response.deductible,
          'PriorInsurer': response.priorInsurer,
          'PriorLimit': response.priorLimit,
          'PriorDeductible': response.priorDeductible,
          'EntityExclude': response.entityExclude,
          'Statutory': response.statutory,
          'ObligeeName': response.obligeeName,
          'ObligeeAddress': response.obligeeAddress,
          'TotalEmployees': response.totalEmployees,
          'TotalPolicemen': response.totalPolicemen,
          'TotalFiremen': response.totalFiremen,
          'OtherLocations': response.otherLocations,
          'DateLastAudit': response.dateLastAudit,
          'RenderName': response.renderName,
          'DutyCov': response.dutyCov,
          'Treasurer': response.treasurer,
          'TaxCollector': response.taxCollector,
          'OtherBonded': response.otherBonded,
          'IsBankruptcy': response.isBankruptcy,
          'LossExperience': response.lossExperience,
          'ReconDeposit': response.reconDeposit,
          'ReconWithdraw': response.reconWithdraw,
          'ReconSignCks': response.reconSignCks,
          'IndependentAudit': response.independentAudit,
          'AuditRendered': response.auditRendered,
          'AuditDiscrepencies': response.auditDiscrepencies,
          'InternalAuditDept': response.internalAuditDept,
          'AllLocationAudit': response.allLocationAudit,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.CrimeApplicationID == 0) {
      this.exposuresService.add(form.getRawValue(), 'AddCrimeApplication').subscribe((response: any) => {
        if (response.error == 0) {
          this.getCrimeApplication(this.activeMuniciaplity);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else if (form.valid) {
      this.exposuresService.update(form.getRawValue(), 'UpdateCrimeApplication').subscribe((response: any) => {
        if (response.error == 0) {
          this.getCrimeApplication(this.activeMuniciaplity);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Crime Loss

  public getCrimeLossList(data) {
    this.exposuresService.getList(data.municipalityID, data.municipalityYear, 'GetListCrimeLoss').subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.lossExperienceDataSource = new MatTableDataSource(response);
      }
    });
  };

  public openAddLossExperienceDialog() {
    const dialogRef = this.dialog.open(NewLossExperienceDialogComponent, {
      data: {
        'MunicipalityID': this.crimeApplicationForm.controls['MunicipalityID'].value,
        'MunicipalityYear': this.crimeApplicationForm.controls['MunicipalityYear'].value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.add(result, 'AddCrimeLoss').subscribe((response: any) => {
          if (response.error == 0) {
            this.getCrimeLossList(this.activeMuniciaplity);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify('error', response.message);
          }
        });
      } 
    });
  };

  public updateLossExperience(data) {
    const dialogRef = this.dialog.open(UpdateLossExperienceDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.update(result, 'UpdateCrimeLoss').subscribe((response: any) => {
          if (response.error == 0) {
            this.getCrimeLossList(this.activeMuniciaplity);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public deleteLossExperience(data) {
    const dialogRef = this.dialog.open(DeleteLossExperienceDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.delete(result, 'DeleteCrimeLoss').subscribe((response: any) => {
          if (response.error == 0) {
            this.getCrimeLossList(this.activeMuniciaplity);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } 
    });
  };

  //Location Exposure

  public getCrimeLocationList(data) {
    this.exposuresService.getList(data.municipalityID, data.municipalityYear, 'GetListCrimeLocationExposure').subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.locationExposureDataSource = new MatTableDataSource(response);
      }
    });
  };

  public openAddLocationExposureDialog() {
    const dialogRef = this.dialog.open(NewLocationExposureDialogComponent, {
      data: {
        'MunicipalityID': this.activeMuniciaplity.municipalityID,
        'MunicipalityYear': this.activeMuniciaplity.municipalityYear
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.add(result, 'AddCrimeLocationExposure').subscribe((response: any) => {
          if (response.error == 0) {
            this.getCrimeLocationList(this.activeMuniciaplity);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify('error', response.message);
          }
        });
      }
    });
  };

  public updateLocationExposure(data) {
    const dialogRef = this.dialog.open(UpdateLocationExposureDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.update(result, 'UpdateCrimeLocationExposure').subscribe((response: any) => {
          if (response.error == 0) {
            this.getCrimeLocationList(this.activeMuniciaplity);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public deleteLocationExposure(data) {
    const dialogRef = this.dialog.open(DeleteLocationExposureDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.delete(result, 'DeleteCrimeLocationExposure').subscribe((response: any) => {
          if (response.error == 0) {
            this.getCrimeLocationList(this.activeMuniciaplity);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } 
    });
  };

};
