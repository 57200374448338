<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Register municipality</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="editMunicipalityForm" (ngSubmit)="submitEditMunicipalityForm(editMunicipalityForm)">
      <div class="form-section">
        <!-- Information for all the years -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>MUNICIPALITY INFORMATION</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- Full Name -->
            <mat-label>Full Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="FullName" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['FullName'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <!-- Short Name -->
            <mat-label>Short Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="ShortName" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['ShortName'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Municipality Information -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Municipality Information on {{data.selectedYear}}</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- Address 1 -->
            <mat-label>Address1 <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="Address1" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['Address1'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!-- Address 2 -->
            <mat-label>Address2</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="Address2" matInput>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!-- City -->
            <mat-label>City <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="City" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['City'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!-- State -->
            <mat-label>State <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="State" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['State'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 2</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!-- ZIP -->
            <mat-label>Zip <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="Zip" matInput mask="00000">
              <mat-error *ngIf="editMunicipalityForm.controls['Zip'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 5</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!-- County -->
            <mat-label>County <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <mat-select formControlName="County">
                <mat-option value="Atlantic">Atlantic</mat-option>
                <mat-option value="Bergen">Bergen</mat-option>
                <mat-option value="Burlington">Burlington</mat-option>
                <mat-option value="Camden">Camden</mat-option>
                <mat-option value="Cape May">Cape May</mat-option>
                <mat-option value="Cumberland">Cumberland</mat-option>
                <mat-option value="Essex">Essex</mat-option>
                <mat-option value="Gloucester">Gloucester</mat-option>
                <mat-option value="Hudson">Hudson</mat-option>
                <mat-option value="Hunterdon">Hunterdon</mat-option>
                <mat-option value="Mercer">Mercer</mat-option>
                <mat-option value="Middlesex">Middlesex</mat-option>
                <mat-option value="Monmouth">Monmouth</mat-option>
                <mat-option value="Morris">Morris</mat-option>
                <mat-option value="Ocean">Ocean</mat-option>
                <mat-option value="Passaic">Passaic</mat-option>
                <mat-option value="Salem">Salem</mat-option>
                <mat-option value="Somerset">Somerset</mat-option>
                <mat-option value="Sussex">Sussex</mat-option>
                <mat-option value="Union">Union</mat-option>
                <mat-option value="Warren">Warren</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="editMunicipalityForm.controls['County'].hasError('required')">This field is required</mat-error>
          </div>
        </div>

        <!-- Contact Information -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Contact Information on {{data.selectedYear}}</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- Contact Name -->
            <mat-label>Contact Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="ContactName" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['ContactName'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!-- Contact Title -->
            <mat-label>Contact Title <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="ContactTitle" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['ContactTitle'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!-- Contact Phone -->
            <mat-label>Phone Number <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="ContactPhone" matInput mask="(000) 000-0000">
              <mat-error *ngIf="editMunicipalityForm.controls['ContactPhone'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!-- Contact Fax -->
            <mat-label>Fax <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="ContactFax" matInput mask="(000) 000-0000">
              <mat-error *ngIf="editMunicipalityForm.controls['ContactFax'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!-- Contact Email -->
            <mat-label>Email <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="ContactEmail" matInput>
              <mat-error *ngIf="editMunicipalityForm.controls['ContactEmail'].hasError('required')">This field is required</mat-error>
              <mat-error *ngIf="editMunicipalityForm.controls['ContactEmail'].hasError('email')">Must be a valid email address</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- More Information -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>More Information on {{data.selectedYear}}</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- RiskManagerID -->
            <mat-label>Risk Manager <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <mat-select formControlName="RiskManagerID">
                <mat-option *ngFor="let manager of riskManagers" [value]="manager.riskManagerID">{{manager.firstName}} {{manager.lastName}}</mat-option>
              </mat-select>
              <mat-error *ngIf="editMunicipalityForm.controls['RiskManagerID'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!-- ApplicationDate -->
            <mat-label>Date of Application <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicationDate" [matDatepicker]="ApplicationDate">
              <mat-datepicker-toggle matSuffix [for]="ApplicationDate"></mat-datepicker-toggle>
              <mat-datepicker #ApplicationDate></mat-datepicker>
              <mat-error *ngIf="editMunicipalityForm.controls['ApplicationDate'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--CoverageEffectiveDate -->
            <mat-label>Coverage Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="CoverageEffectiveDate" [matDatepicker]="CoverageEffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="CoverageEffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #CoverageEffectiveDate></mat-datepicker>
              <mat-error *ngIf="editMunicipalityForm.controls['CoverageEffectiveDate'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--CoverageEffectiveYear -->
            <mat-label>Coverage Effective Year <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="CoverageEffectiveYear" [matDatepicker]="CoverageEffectiveYear">
              <mat-datepicker-toggle matSuffix [for]="CoverageEffectiveYear"></mat-datepicker-toggle>
              <mat-datepicker #CoverageEffectiveYear></mat-datepicker>
              <mat-error *ngIf="editMunicipalityForm.controls['CoverageEffectiveYear'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- Public Officials Elected -->
            <mat-label>Public Officials Elected</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="PublicOfficialsElected" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!-- Public Officials Appointed -->
            <mat-label>Public Officials Appointed</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="PublicOfficialsAppointed" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!-- Population Normal -->
            <mat-label>Population Normal</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="PopulationNormal" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!-- Population Seasonal -->
            <mat-label>Population Seasonal</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="PopulationSeasonal" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!-- EmployeeTotal -->
            <mat-label>Employee Total</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="EmployeeTotal" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!-- GrossPayrollTotal -->
            <mat-label>Gross Payroll Total</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" currencyMask formControlName="GrossPayrollTotal" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!-- ContactorExpenditures -->
            <mat-label>Contactor Expenditures</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="ContactorExpenditures" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--QuasiMunicipalEntities-->
            <mat-label>6. Quasi Municipal Entities - Identify all such entities</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <textarea matInput formControlName="QuasiMunicipalEntities" placeholder=""></textarea>
              <mat-error *ngIf="editMunicipalityForm.controls['QuasiMunicipalEntities'].hasError('maxLength')">Character length for this field is 500</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!-- GrossPayrollTotal -->
            <mat-label>Operating Expenditure Estimate</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="OperatingExpenditureEstimate" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--CoverageEffectiveDate -->
            <mat-label>Coverage Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error *ngIf="editMunicipalityForm.controls['EffectiveDate'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">UPDATE</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
