<div class="login-section-wrapper h-100">
  <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-12 col-lg-6 col-xl-6 align-self-center">
        <section class="mat-elevation-z8 white-box form-container">
          <div class="section-title text-center">
            <img id="logo-login" src="../../../assets/images/logo/logo-w.png" />
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm(loginForm)">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="field-full-width" appearance="fill">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="Email" placeholder="Email" />
                  <mat-error>This field is required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="field-full-width" appearance="fill">
                  <mat-label>Password</mat-label>
                  <input type="password" matInput formControlName="Password" placeholder="Password" />
                  <mat-error>This field is required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!--ActionButtons-->
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <button mat-button type="button" routerLink="/forgot-password">Forgot Password?</button>
                  </div>
                  <div class="col-md-6 text-right">
                    <button type="submit" color="primary" mat-raised-button [disabled]="loginForm.invalid">Login</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>

  <div class="footer-info">2021 © NIP Group, Inc. All right reserved. Privacy Policy. No part of this site may be reproduced without written permission from NIP Group, Inc.</div>
</div>

