//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

import { AuthService } from '../../../shared/services/auth/auth.service';
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';

@Component({
  selector: 'app-storage-tank-marina-questionnaire',
  templateUrl: './storage-tank-marina-questionnaire.component.html',
  styleUrls: ['./storage-tank-marina-questionnaire.component.scss']
})
export class StorageTankMarinaQuestionnaireComponent implements OnInit {
  constructor(private notifier: NotifierService, private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService) {
    this.marinaQuestionnaireForm = new FormGroup({
      QuestionnaireMarina_yearId: new FormControl(0), //INT
      QuestionnaireMarinaID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      FacilityDetails: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      IsSpillPrevention: new FormControl(false), //BOOL
      ESLessThan2000: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      ESLessThan1Mile: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      ESMoreThan1Mile: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      RSLessThan2000: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      RSLessThan1Mile: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      RSMoreThank1Mile: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      IsPipingAssociatedStorageTank: new FormControl(false), //BOOL
      IsPipingAssociatedUV: new FormControl(false), //BOOL
      IsPipingTested: new FormControl(false), //BOOL
      PipingDesc: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      IsPipingExtendsUnderWater: new FormControl(false), //BOOL
      ExtendsUnderWaterDesc: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      IsPipingExtendsOverWater: new FormControl(false), //BOOL
      ExtendsOverWaterDesc: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      IsShutOffValve: new FormControl(false), //BOOL
      ShutOffValveDesc: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      IsStorageTankProtected: new FormControl(false), //BOOL
      StorageTankDesc: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      IsAboveground: new FormControl(false), //BOOL
      AbovegroundDesc: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public activeMunicipality;
  public marinaQuestionnaireForm: FormGroup;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.marinaQuestionnaireForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getQuestionnaireMarina(response);
      }
    });
  };

  public getQuestionnaireMarina(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetQuestionnaireMarina').subscribe((response: any) => {
      if (!response) {
        this.marinaQuestionnaireForm.patchValue({
          'QuestionnaireMarina_yearId': 0,
          'QuestionnaireMarinaID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'FacilityDetails': '',
          'IsSpillPrevention': false,
          'ESLessThan2000': '',
          'ESLessThan1Mile': '',
          'ESMoreThan1Mile': '',
          'RSLessThan2000': '',
          'RSLessThan1Mile': '',
          'RSMoreThank1Mile': '',
          'IsPipingAssociatedStorageTank': false,
          'IsPipingAssociatedUV': false,
          'IsPipingTested': false,
          'PipingDesc': '',
          'IsPipingExtendsUnderWater': false,
          'ExtendsUnderWaterDesc': '',
          'IsPipingExtendsOverWater': false,
          'ExtendsOverWaterDesc': '',
          'IsShutOffValve': false,
          'ShutOffValveDesc': '',
          'IsStorageTankProtected': false,
          'StorageTankDesc': '',
          'IsAboveground': false,
          'AbovegroundDesc': '',
          'EffectiveDate': ''
        });
      } else {
        this.marinaQuestionnaireForm.patchValue({
          'QuestionnaireMarina_yearId': response.questionnaireMarina_yearId,
          'QuestionnaireMarinaID': response.questionnaireMarinaID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'FacilityDetails': response.facilityDetails,
          'IsSpillPrevention': response.isSpillPrevention,
          'ESLessThan2000': response.esLessThan2000,
          'ESLessThan1Mile': response.esLessThan1Mile,
          'ESMoreThan1Mile': response.esMoreThan1Mile,
          'RSLessThan2000': response.rsLessThan2000,
          'RSLessThan1Mile': response.rsLessThan1Mile,
          'RSMoreThank1Mile': response.rsMoreThank1Mile,
          'IsPipingAssociatedStorageTank': response.isPipingAssociatedStorageTank,
          'IsPipingAssociatedUV': response.isPipingAssociatedUV,
          'IsPipingTested': response.isPipingTested,
          'PipingDesc': response.pipingDesc,
          'IsPipingExtendsUnderWater': response.isPipingExtendsUnderWater,
          'ExtendsUnderWaterDesc': response.extendsUnderWaterDesc,
          'IsPipingExtendsOverWater': response.isPipingExtendsOverWater,
          'ExtendsOverWaterDesc': response.extendsOverWaterDesc,
          'IsShutOffValve': response.isShutOffValve,
          'ShutOffValveDesc': response.shutOffValveDesc,
          'IsStorageTankProtected': response.isStorageTankProtected,
          'StorageTankDesc': response.storageTankDesc,
          'IsAboveground': response.isAboveground,
          'AbovegroundDesc': response.abovegroundDesc,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.QuestionnaireMarinaID == 0 && form.value.QuestionnaireMarina_yearId == 0) {
      this.exposuresService.add(form.value, 'AddQuestionnaireMarina').subscribe((response: any) => {
        if (response.error == 0) {
          this.getQuestionnaireMarina(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateQuestionnaireMarina').subscribe((response: any) => {
        if (response.error == 0) {
          this.getQuestionnaireMarina(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
