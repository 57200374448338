//Import Modules
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { NotifierService } from "angular-notifier";

//Import Components
import { CreateAccountComponent } from '../../account/create-account/create-account.component';
import { EditAccountComponent } from '../../account/edit-account/edit-account.component';
import { DeleteAccountComponent } from '../../account/delete-account/delete-account.component';

//Import Services
import { AccountService } from '../../account/service/account.service';
import { AuthService } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  constructor(private accountService: AccountService, private authService: AuthService, private dialog: MatDialog, private notifier: NotifierService) { };
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public sort;
  public displayedColumns: string[] = ['firstName', 'lastName', 'email', 'emailConfirmed', 'municipalities', 'role', 'action'];
  public dataSource;

  ngOnInit() {
    this.getAllAccounts();
  };

  //Get all users and poplulate the table
  getAllAccounts() {
    this.accountService.getAllAccount().subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort;
    });
  };

  //Open register new account dialog
  openRegisterNewAccountDialog() {
    const dialogRef = this.dialog.open(CreateAccountComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.accountService.accountCreate(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.getAllAccounts();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        });
      }
    });
  };

  //Open edit account dialog
  openEditAccountDialog(data) {
    const dialogRef = this.dialog.open(EditAccountComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.accountService.accountEdit(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.getAllAccounts();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        })
      }
    });
  };

  //Open delete account dialog
  openDeleteAccountDialog(data) {
    const dialogRef = this.dialog.open(DeleteAccountComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.accountService.accountDelete(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.getAllAccounts();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        })
      }
    });
  };

  //Resend activation email for the user
  resendActivationEmail(data) {
    this.accountService.accountResendActivationEmail(data).pipe(first()).subscribe({
      next: (response: any) => {
        if (response.error === 0) {
          this.notifier.notify('success', response.message);
        } else {
          this.notifier.notify('error', response.message);
        }
      },
      error: error => {
        this.notifier.notify('error', 'Something went wrong');
      }
    })
  };
};
