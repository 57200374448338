//Import Modules
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from "angular-notifier";

//Import Services
import { SchedulesService } from '../../../shared/services/schedules/schedules.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

//Import Components
import { NewWatercraftDialogComponent } from './dialog/new-watercraft-dialog/new-watercraft-dialog.component';
import { UpdateWatercraftDialogComponent } from './dialog/update-watercraft-dialog/update-watercraft-dialog.component';
import { DeleteWatercraftDialogComponent } from './dialog/delete-watercraft-dialog/delete-watercraft-dialog.component';

//Export to Excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-watercraft',
  templateUrl: './watercraft.component.html',
  styleUrls: ['./watercraft.component.scss']
})
export class WatercraftComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private schedulesService: SchedulesService, private authService: AuthService, private notifier: NotifierService) { }
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public activeMunicipality;
  public sort;

  //Watercraft table
  public displayedColumns: string[] = ["departmentName", "year", "make", "model", "description", "vin", "cnv", "action"];
  public data;
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMunicipality = response;
        this.getTableList(response);
      }
    });
  };

  public getTableList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListWatercrafts').subscribe((response: any) => {
      if (!response) {
        return
      }
      else {
        this.data = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
      }
    });
  };

  public getDetails(employeeConcentrationID) {
    this.schedulesService.getDetail(employeeConcentrationID, 'GetDetailWatercrafts').subscribe((response: any) => {
      console.log(response);
    });
  };

  public openNewDialog() {
    const dialogRef = this.dialog.open(NewWatercraftDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.add(result, 'AddWatercraft').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public openUpdateDialog(data) {
    const dialogRef = this.dialog.open(UpdateWatercraftDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.update(result, 'UpdateWatercraft').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeleteWatercraftDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.delete(result, 'DeleteWatercraft').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  }

  public filterTable = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Watercraft');

    worksheet.columns = [
      { header: 'Department', key: 'departmentName', width: 22 },
      { header: 'Year', key: 'year', width: 14 },
      { header: 'Make', key: 'make', width: 20 },
      { header: 'Model', key: 'model', width: 20 },
      { header: 'Description', key: 'description', width: 40 },
      { header: 'VIN', key: 'vin', width: 20 },
      { header: 'CNV', key: 'cnv', width: 12 },
    ];

    this.data.forEach(e => {
      worksheet.addRow({
        departmentName: e.departmentName,
        year: e.year,
        make: e.make,
        model: e.model,
        description: e.description,
        vin: e.vin,
        cnv: e.cnv
      }, "n");
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Watercraft.xlsx');
    })

  }

}
