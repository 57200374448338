//Import Modules
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { NotifierService } from "angular-notifier";

//Import Services
import { SchedulesService } from '../../../shared/services/schedules/schedules.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { GeneralInfoService } from '../../general-info/service/general-info.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

//Import Components
import { NewEmployeeLocationDialogComponent } from './dialog/new-employee-location-dialog/new-employee-location-dialog.component';
import { UpdateEmployeeLocationDialogComponent } from './dialog/update-employee-location-dialog/update-employee-location-dialog.component';
import { DeleteEmployeeLocationDialogComponent } from './dialog/delete-employee-location-dialog/delete-employee-location-dialog.component';

@Component({
  selector: 'app-employee-concentration',
  templateUrl: './employee-concentration.component.html',
  styleUrls: ['./employee-concentration.component.scss']
})

export class EmployeeConcentrationComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private authService: AuthService, private generalInfoService: GeneralInfoService, private schedulesService: SchedulesService, private notifier: NotifierService) {
    this.initForm();
  };
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public sort;
  public activeMunicipality;
  public riskManagers;
  public employeeConcentrationForm: FormGroup;
  public displayedColumns: string[] = ['propLocation', 'hoursOfOperation', 'floorsOccupied', 'numberOfStories', 'yearBuilt', 'action'];
  public dataSource;

  ngOnInit() {
    this.getListRiskManagersService();
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.employeeConcentrationForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getList(response);
        this.getTableList(response);
      }
    });
  };

  public initForm() {
    this.employeeConcentrationForm = new FormGroup({
      EmployeeConcentrationID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      SurveyDate: new FormControl('', Validators.required), //DATE
      RiskManager: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      FullTimeEmployees: new FormControl(0, Validators.required), //INT
      PartTimeEmployees: new FormControl(0, Validators.required), //INT
      SeasonalEmployees: new FormControl(0, Validators.required), //INT
      RiskManagerAssign: new FormControl(false), //BOOL
      RiskManagerFT: new FormControl(false), //BOOL
      MailGuide: new FormControl(false), //BOOL
      EvacDrills: new FormControl(false), //BOOL
      RollCall: new FormControl(false), //BOOL
      BldgUpdate: new FormControl(false), //BOOL
      UpdateDate: new FormControl(''), //DATE
      RetroFit: new FormControl(false), //BOOL
      RetroFitDate: new FormControl(''), //DATE
      Fault: new FormControl(false), //BOOL
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public getListRiskManagersService() {
    this.generalInfoService.getListRiskManagers().subscribe((response: any) => {
      this.riskManagers = response;
    });
  };

  public getList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListEmployeeConcentrations').subscribe((response: any) => {
      if (!response) {
        this.employeeConcentrationForm.reset();
        this.initForm();
        this.employeeConcentrationForm.patchValue({ 'MunicipalityID': data.municipalityID, 'MunicipalityYear': data.municipalityYear });
      } else {
        this.employeeConcentrationForm.patchValue({
          'EmployeeConcentrationID': response.employeeConcentrationID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'SurveyDate': response.surveyDate,
          'RiskManager': response.riskManager,
          'FullTimeEmployees': response.fullTimeEmployees,
          'PartTimeEmployees': response.partTimeEmployees,
          'SeasonalEmployees': response.seasonalEmployees,
          'RiskManagerAssign': response.riskManagerAssign,
          'RiskManagerFT': response.riskManagerFT,
          'MailGuide': response.mailGuide,
          'EvacDrills': response.evacDrills,
          'RollCall': response.rollCall,
          'BldgUpdate': response.bldgUpdate,
          'UpdateDate': response.updateDate,
          'RetroFit': response.retroFit,
          'RetroFitDate': response.retroFitDate,
          'Fault': response.fault,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public getTableList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListEmployeeLocations').subscribe((response: any) => {
      if (!response) {
        return
      }
      else {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
      }
    });
  };

  public getDetails(employeeConcentrationID) {
    this.schedulesService.getDetail(employeeConcentrationID, 'GetDetailEmployeeConcentrations').subscribe((response: any) => {
      console.log(response);
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.EmployeeConcentrationID == 0) {
      this.schedulesService.add(form.getRawValue(), 'AddEmployeeConcentrations').subscribe((response: any) => {
        if (response.error == 0) {
          this.getList(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else if (form.valid) {
      this.schedulesService.update(form.getRawValue(), 'UpdateEmployeeConcentrations').subscribe((response: any) => {
        if (response.error == 0) {
          this.getList(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else {
      this.notifier.notify("error", "Submitted form is invalid");
    }
  };

  public openNewDialog() {
    const dialogRef = this.dialog.open(NewEmployeeLocationDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.add(result, 'AddEmployeeLocations').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public openUpdateDialog(data) {
    const dialogRef = this.dialog.open(UpdateEmployeeLocationDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.update(result, 'UpdateEmployeeLocations').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeleteEmployeeLocationDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.delete(result, 'DeleteEmployeeLocations').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };
};
