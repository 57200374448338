//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { first } from 'rxjs/operators';

//Import Services
import { MunicipalitiesService } from '../../shared/services/municipalities/municipalities.service';
import { AuthService } from '../../shared/services/auth/auth.service';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<CreateAccountComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService, private municipalitiesService: MunicipalitiesService, public authService: AuthService) {
    this.accountRegistrationForm = new FormGroup({
      Email: new FormControl('', [Validators.required, Validators.email]),
      FirstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      LastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      Municipalities: new FormControl('', Validators.required),
      PhoneNumber: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      TwoFactor: new FormControl(false),
      UserRole: new FormControl('', [Validators.required])
    });
  };

  public municipalities: any[];
  public accountRegistrationForm: FormGroup;

  ngOnInit() {
    this.getListMunicipalitiesService();
  };

  //Get municipalities, populates the dropdown field
  getListMunicipalitiesService() {
    this.municipalitiesService.getListMunicipalities(this.authService.activeAccount.userID).pipe(first()).subscribe({
      next: (response) => {
        this.municipalities = response;
      },
      error: error => {
        this.notifier.notify('error', 'Unable to load records. Please reload the page and try again. If the problem persists, contact your system administrator.');
      }
    });
  };

  //Close modal and pass form data to controller
  submitUserAccountForm(form) {
    //Check if PhoneNumber exists
    if (form.value.PhoneNumber == '' || form.value.PhoneNumber == null && form.value.TwoFactor == true) {
      this.notifier.notify('error', 'Please enter phone number to enable Two Factor Auth');
    }
    //Check if user role is admin or manager
    else if (form.value.UserRole === 'Admin' || form.value.UserRole === 'ReadOnly') {
      var selectAllMunicipalities = [];
      for (let i = 0; i < this.municipalities.length; i++) {
        selectAllMunicipalities.push(this.municipalities[i].municipalityID);
      }
      this.accountRegistrationForm.patchValue({ 'Municipalities': selectAllMunicipalities });
      //Then check if form is valid
      if (form.valid) {
        form.value.Municipalities = form.value.Municipalities.join();
        this.dialogRef.close(form.value);
      }
      else {
        this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
      }
    }
    //check if form is valid
    else if (form.valid) {
      form.value.Municipalities = form.value.Municipalities.join();
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  userRoleChanged() {
    if (this.accountRegistrationForm.controls['Municipalities'].value.length != 0) {
      this.accountRegistrationForm.patchValue({ 'Municipalities': '' });
    }
  };

  onNoClick() {
    this.dialogRef.close();
  };

};
