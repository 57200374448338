<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Update Pilot</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="updatePilotForm" (ngSubmit)="submitUpdatePilotForm(updatePilotForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Pilot Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--NameofPilot -->
            <mat-label>Name of Pilot</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="NameofPilot">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--DOB -->
            <mat-label>DOB (DD/MM/YYYY)</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="DOB" [matDatepicker]="DOB">
              <mat-datepicker-toggle matSuffix [for]="DOB"></mat-datepicker-toggle>
              <mat-datepicker #DOB></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <mat-checkbox formControlName="Stud">Stud</mat-checkbox>
          </div>
          <div class="col-md-1">
            <mat-checkbox formControlName="Pvt">Pvt</mat-checkbox>
          </div>
          <div class="col-md-1">
            <mat-checkbox formControlName="Coml">Coml</mat-checkbox>
          </div>
          <div class="col-md-1">
            <mat-checkbox formControlName="ASEL">ASEL</mat-checkbox>
          </div>
          <div class="col-md-1">
            <mat-checkbox formControlName="AMEL">AMEL</mat-checkbox>
          </div>
          <div class="col-md-1">
            <mat-checkbox formControlName="Instrumt">Instrumt</mat-checkbox>
          </div>
          <div class="col-md-1">
            <mat-checkbox formControlName="ATP">ATP</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Other -->
            <mat-label>Other</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Other">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--DateLastPhysical -->
            <mat-label>Date Last Physical (DD/MM/YYYY)</mat-label>

            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="DateLastPhysical" [matDatepicker]="DateLastPhysical">
              <mat-datepicker-toggle matSuffix [for]="DateLastPhysical"></mat-datepicker-toggle>
              <mat-datepicker #DateLastPhysical></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Class -->
            <mat-label>Class</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Class">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Total -->
            <mat-label>Total</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Total">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--RetractGear -->
            <mat-label>Retract Gear</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="RetractGear">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--MultiEngine -->
            <mat-label>Multi Engine</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="MultiEngine">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Last90days -->
            <mat-label>Last 90 days</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Last90days">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Last12Mos -->
            <mat-label>Last 12 Mos</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Last12Mos">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Last12MosPilot -->
            <mat-label>Last 12 Mos Pilot</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Last12MosPilot">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Next12MosPilot -->
            <mat-label>Next 12 Mos Pilot</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Next12MosPilot">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--FAACrtNoPilot -->
            <mat-label>FAA Crt No Pilot</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FAACrtNoPilot">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--DateLastBiennialRev -->
            <mat-label>Date Last Biennial Rev (DD/MM/YYYY)</mat-label>

            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="DateLastBiennialRev" [matDatepicker]="DateLastBiennialRev">
              <mat-datepicker-toggle matSuffix [for]="DateLastBiennialRev"></mat-datepicker-toggle>
              <mat-datepicker #DateLastBiennialRev></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
