<div mat-dialog-content>
  <div class="dialog-form delete-dialog">
    <div class="row">
      <div class="col-sm-3">
        <img src="../../../assets/images/icons/remove.svg" class="icon-remove" />
      </div>
      <div class="col-sm-9">
        <div class="deletion-message">
          <h5>Are you sure you want to delete?</h5>
          <p>{{this.data.firstName}} {{this.data.lastName}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="dialog-action-buttons">
          <div mat-dialog-actions class="float-right">
            <button mat-button (click)="onNoClick()">Cancel</button>
            <button mat-raised-button type="button" color="warn" (click)="confimRiskManagerDeletion()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
