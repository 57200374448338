//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-location-exposure-dialog',
  templateUrl: './new-location-exposure-dialog.component.html',
  styleUrls: ['./new-location-exposure-dialog.component.scss']
})

export class NewLocationExposureDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewLocationExposureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.locationExposureForm = new FormGroup({
      CrimeLocationExposure_yearId: new FormControl(0), //INT
      CrimeLocationExposureID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Location: new FormControl(''), //STRING
      Cash: new FormControl(0), //INT
      RetailChecks: new FormControl(0, Validators.required), //INT
      CreditCardReceipts: new FormControl(0, Validators.required), //INT
      Safe: new FormControl(false, Validators.required), //Bool
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  ngOnInit(): void {
    this.locationExposureForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear
    });
  };

  public locationExposureForm: FormGroup;

  //Close modal and pass form data to controller
  submitLocationExposureForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
