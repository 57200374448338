//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-employee-location-dialog',
  templateUrl: './delete-employee-location-dialog.component.html',
  styleUrls: ['./delete-employee-location-dialog.component.scss']
})
export class DeleteEmployeeLocationDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteEmployeeLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  //Close modal and pass form data to controller
  confimEmployeeLocationDeletion() {
    this.dialogRef.close(this.data.employeeLocations_yearId);
  };

  //Close without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
