<div class="forgot-password-section-wrapper h-100">
  <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-12 col-lg-6 col-xl-6 align-self-center">
        <section class="mat-elevation-z8 white-box form-container">
          <div class="section-title">
            <h3>Forgot password</h3>
          </div>
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPasswordForm(forgotPasswordForm)">
            <div class="row">
              <div class="col-md-12">
                <mat-label>Email</mat-label>
                <mat-form-field class="field-full-width" appearance="fill">
                  <input matInput formControlName="Email" placeholder="" />
                  <mat-error *ngIf="forgotPasswordForm.controls['Email'].hasError('required')">This field is required</mat-error>
                  <mat-error *ngIf="forgotPasswordForm.controls['Email'].hasError('email')">Must be a valid email address</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- ActionButtons -->
            <div class="row">
              <div class="col-md-6 text-left">
                <button type="button" mat-button routerLink="/login">Cancel</button>
              </div>
              <div class="col-md-6 text-right">
                <button type="submit" mat-raised-button color="primary" [disabled]="forgotPasswordForm.invalid" class="submit-btn">Send reset password email</button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</div>

