<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Add Crime Loss Experience</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="lossExperienceForm" (ngSubmit)="submitLossExperienceForm(lossExperienceForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Loss experience information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--LossDate-->
            <mat-label>Loss Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="LossDate" [matDatepicker]="LossDate">
              <mat-datepicker-toggle matSuffix [for]="LossDate"></mat-datepicker-toggle>
              <mat-datepicker #LossDate></mat-datepicker>
              <mat-error *ngIf="lossExperienceForm.controls['LossDate'].hasError('required')">This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--LossDescription-->
            <mat-label>Loss Description <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="LossDescription">
              <mat-error *ngIf="lossExperienceForm.controls['LossDescription'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--CorrectiveAction-->
            <mat-label>Corrective Action <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="CorrectiveAction">
              <mat-error *ngIf="lossExperienceForm.controls['CorrectiveAction'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--TotalAmount-->
            <mat-label>Total Amount</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" currencyMask maxlength="20" matInput formControlName="TotalAmount">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--EffectiveDate-->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
