<div class="cyber-liability-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Cyber Liability</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="cyberLiabilityForm" (ngSubmit)="submitForm(cyberLiabilityForm)">
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>I. MANAGEMENT OF CREDIT CARD EXPOSURES</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--AcceptCredit-->
          <mat-checkbox formControlName="AcceptCredit">1. The Applicant accepts credit cards for goods sold or services rendered</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--SecurityStandards-->
          <mat-checkbox formControlName="SecurityStandards"> A. The Applicant is compliant with applicable data security standards issued by financial institutions the Applicant transacts business with (e.g. PCI standards)</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--CurrentStatus-->
          <mat-label>If the Applicant is not compliant with applicable data security standards, please describe the current status of any compliance work and the estimated date of completion:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="CurrentStatus" placeholder="" />
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>II. COMPUTER SYSTEMS CONTROLS</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ConductTraining-->
          <mat-checkbox formControlName="ConductTraining">1. The Applicant conducts training for every employee user of the information systems in security issues and procedures for its computer systems</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--InformationSecurity-->
          <mat-checkbox formControlName="InformationSecurity">2. The Applicant has a written information security policy in place</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--AuditSecurity-->
          <mat-checkbox formControlName="AuditSecurity">3. The Applicant has a program in place to test or audit security controls on an annual or more frequent basis</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--AuditScope-->
          <mat-label>If yes, please summarize the scope of such audits and/or tests:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AuditScope" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--ExitProcess-->
          <mat-checkbox formControlName="ExitProcess">4. The Applicant terminates all associated computer access and user accounts as part of the regular exit process when an employee leaves the company</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--CommunicationEncrypted-->
          <mat-checkbox formControlName="CommunicationEncrypted">5. The Applicant has and enforces policies concerning when internal and external communication should be encrypted</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--PortableMedia-->
          <mat-checkbox formControlName="PortableMedia">A. All laptop computers and portable media (e.g. 'thumb drives', back-up tapes) are protected by encryption</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--SoftwarePatches-->
          <mat-checkbox formControlName="SoftwarePatches">6. The Applicant enforces a software update process including installation of software 'patches'</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--CriticalPatches-->
          <mat-checkbox formControlName="CriticalPatches">Critical patches are installed within 30 days of release</mat-checkbox>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>7. How often are anti-virus software signatures updated?</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--AntiVirus-->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="AntiVirus" placeholder="">
              <mat-option value="Automatic Updates">Automatic Updates</mat-option>
              <mat-option value="Weekly">Weekly</mat-option>
              <mat-option value="Monthly">Monthly</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--UpdateFirewall-->
          <mat-checkbox formControlName="UpdateFirewall">8. The Applicant has and regularly maintains and/or updates a Firewall</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--KnownIntrusions-->
          <mat-checkbox formControlName="KnownIntrusions">9. The Applicant has suffered known intrusions (i.e., unauthorized access or security breach) or denial of service attacks relating to its computer systems in the most recent three year time period from the date of this Application</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--DescribeDamage-->
          <mat-label>If yes, describe any such intrusions or attacks, including any damage caused by any such intrusions, including lost time, lost business income, or costs to repair any damage to systems or to reconstruct data or software, describe the damage that occurred, and state value of any lost time, income and the costs of any repair or reconstruction:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DescribeDamage" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--ProvideTraining-->
          <mat-checkbox formControlName="ProvideTraining">10. The Applicant provides training to key employees regarding the Privacy Policy and the handling of personally identifiable information.</mat-checkbox>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>III. TYPES OF PRIVATE INFORMATION ACCESSED, PROCESSED OR STORED</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--NameAddress-->
          <mat-checkbox formControlName="NameAddress">Names and addresses</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--NameAddressDescription-->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="NameAddressDescription" placeholder="">
              <mat-option value="Employee">Employee</mat-option>
              <mat-option value="Residents">Residents</mat-option>
              <mat-option value="Employee and Residents">Employee and Residents</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--FinancialAccount-->
          <mat-checkbox formControlName="FinancialAccount">2. Financial account information</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--FinancialAccountDescription-->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="FinancialAccountDescription" placeholder="">
              <mat-option value="Employee">Employee</mat-option>
              <mat-option value="Residents">Residents</mat-option>
              <mat-option value="Employee and Residents">Employee and Residents</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--LicenseNumbers-->
          <mat-checkbox formControlName="LicenseNumbers">3. Drivers license numbers?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--LicenseNumbersDescription-->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="LicenseNumbersDescription" placeholder="">
              <mat-option value="Employee">Employee</mat-option>
              <mat-option value="Residents">Residents</mat-option>
              <mat-option value="Employee and Residents">Employee and Residents</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--SocialSecurity-->
          <mat-checkbox formControlName="SocialSecurity">4. Social Security Numbers</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--SocialSecurityDescription-->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="SocialSecurityDescription" placeholder="">
              <mat-option value="Employee">Employee</mat-option>
              <mat-option value="Residents">Residents</mat-option>
              <mat-option value="Employee and Residents">Employee and Residents</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--ProtectedHealth-->
          <mat-checkbox formControlName="ProtectedHealth">5. Protected Health Information</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--ProtectedHealthDescription-->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="ProtectedHealthDescription" placeholder="">
              <mat-option value="Employee">Employee</mat-option>
              <mat-option value="Residents">Residents</mat-option>
              <mat-option value="Employee and Residents">Employee and Residents</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>6. Please provide a rough percentage of the information stored in each format. (Must total to 100%)</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ElectronicallyStored-->
          <mat-label>Electronically Stored <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="ElectronicallyStored" placeholder="" />
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--PaperFormat-->
          <mat-label>Paper Format  <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="PaperFormat" placeholder="" />
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--EmployeeAccess-->
          <mat-checkbox formControlName="EmployeeAccess">7. All employees have access, or if access is limited to certain individuals, then describe below.</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--EmployeeAccessDescription-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EmployeeAccessDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>IV. MISCELLANEOUS</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ThirdPartyPayments-->
          <mat-checkbox formControlName="ThirdPartyPayments">1. Third-Party Service Providers are used for processing payments on the Applicant's behalf</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--SponsorWifi-->
          <mat-checkbox formControlName="SponsorWifi">2. The Applicant currently sponsor public access Wi-Fi for residents (e.g., 'hotspots')</mat-checkbox>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>V. WEBSITE MEDIA CONTENT</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ReviewInfringement-->
          <mat-checkbox formControlName="ReviewInfringement">1. You have a procedure for review and approval of material published to pages on your Internet site as well as social media messages posted on behalf of the municipality in order to avoid the publication of infringing or improper material</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate-->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="cyberLiabilityForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
