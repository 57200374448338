//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { FormValidator } from '../../../../../shared/pipes/FormValidator';

@Component({
  selector: 'app-new-locations-property-values-dialog',
  templateUrl: './new-locations-property-values-dialog.component.html',
  styleUrls: ['./new-locations-property-values-dialog.component.scss']
})

export class NewLocationsPropertyValuesDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewLocationsPropertyValuesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.newLocationsPropertyValuesForm = new FormGroup({
      ScheduleProperties_yearId: new FormControl(0), //INT
      PropertyID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Street: new FormControl('', [Validators.required, Validators.maxLength(200)]), //STRING
      City: new FormControl('', [Validators.required, Validators.maxLength(160)]), //STRING
      State: new FormControl('', Validators.compose([Validators.minLength(2), Validators.maxLength(2), Validators.required, FormValidator.patternValidator(/^[A-Z]*$/, { isCapital: true })])), //STRING
      Zip: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required, FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])), //STRING
      Type: new FormControl('', Validators.maxLength(200)), //STRING
      SqFt: new FormControl('', Validators.maxLength(50)), //STRING
      Floors: new FormControl('', Validators.maxLength(50)), //STRING
      Block: new FormControl('', Validators.maxLength(50)), //STRING
      Lot: new FormControl('', Validators.maxLength(50)), //STRING
      YearBuilt: new FormControl('', Validators.maxLength(50)), //STRING
      Construction: new FormControl('', Validators.maxLength(200)), //STRING
      ProtectionClass: new FormControl('', Validators.maxLength(50)), //STRING
      MilesFromCoast: new FormControl('', Validators.maxLength(200)), //STRING
      FloodZone: new FormControl('', Validators.maxLength(200)), //STRING
      BuildingValue: new FormControl(0, Validators.required),  //INT
      ContentsValue: new FormControl(0, Validators.required),  //INT
      Sprinklers: new FormControl('', [Validators.maxLength(50)]), //STRING
      Alarms: new FormControl('', Validators.maxLength(50)), //STRING
      SmokeDetectors: new FormControl('', Validators.maxLength(50)), //STRING
      OtherProtection: new FormControl('', Validators.maxLength(50)), //STRING
      Occupancy: new FormControl('', Validators.maxLength(50)), //STRING
      RentalIncome: new FormControl(0, Validators.required), //INT
      AdditionalInterest: new FormControl('', Validators.maxLength(100)), //STRING
      EffectiveDate: new FormControl('', Validators.required)  //DATE
    });
  };

  public newLocationsPropertyValuesForm: FormGroup;

  ngOnInit(): void {
    this.newLocationsPropertyValuesForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear
    });
  };

  //Close modal and pass form
  submitNewLocationsPropertyValuesForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal with no changes
  onNoClick(): void {
    this.dialogRef.close();
  };
};
