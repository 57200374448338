//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MunicipalitiesService {
  constructor(private http: HttpClient) { }

  getListMunicipalities(data?: string): Observable<any> {
    return this.http.get('/api/General/GetListMunicipalities', { params: { userID: data } });
  };

  getListMunicipalitiesYears(municipalityID): Observable<any> {
    return this.http.get('/api/General/GetListMunicipalityYears', { params: { municipalityID } });
  };

  getDetailMunicipalities(id, year): Observable<any> {
    return this.http.get('/api/General/GetDetailMunicipalities', { params: { id, year } });
  };

  getDepartments(): Observable<any> {
    return this.http.get('/api/General/GetDepartments');
  };

  addMunicipality(data): Observable<any> {
    return this.http.post('/api/General/AddMunicipalities', data);
  };

  updateMunicipality(municipality): Observable<any> {
    return this.http.put('/api/General/UpdateMunicipalities', municipality);
  };

  //Risk Managers
  getListRiskManagers() {
    return this.http.get('/api/General/GetListRiskManagers');
  };

  addRiskManagers(data) {
    return this.http.post('/api/General/AddRiskManagers', data);
  };

  updateRiskManagers(data) {
    return this.http.put('/api/General/UpdateRiskManagers', data);
  };

  deleteRiskManagers(data: string) {
    return this.http.delete('/api/General/DeleteRiskManagers', { params: { id: data } });
  };

};
