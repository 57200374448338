//Import Modules
import { Component, OnInit } from '@angular/core';
//Import Services
import { RenewalApplicationsService } from '../../shared/services/renewal-applications/renewal-applications.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService) { }

  public activeMunicipality;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMunicipality = response;
      }
    });
  };
}
