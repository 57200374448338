//Import Modules
import { Component, OnInit, ViewChild, AfterViewInit, AfterViewChecked } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

export interface DepartmentColumns {
  department: string;
  departmentID: number;
}

@Component({
  selector: 'app-departments-helper',
  templateUrl: './departments-helper.component.html',
  styleUrls: ['./departments-helper.component.scss']
})
export class DepartmentsHelperComponent implements OnInit {
  constructor(private http: HttpClient, private notifier: NotifierService) { };

  @ViewChild(MatSort) sort: MatSort;

  public DepartmentColumns: string[] = ['department', 'departmentID'];
  public dataSource = new MatTableDataSource<DepartmentColumns>();

  ngOnInit(): void {
    this.loadDepartments();
  };

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  };

  loadDepartments() {
    this.http.get('/api/General/GetDepartments').subscribe((response: any) => {
      this.dataSource.data = response as DepartmentColumns[];
    });
  };
};
