<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Register risk manager</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="newRiskManagerForm" (ngSubmit)="submitNewRiskManagerForm(newRiskManagerForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Risk Manager Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <mat-label>First Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="FirstName" matInput>
              <mat-error *ngIf="newRiskManagerForm.controls['FirstName'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-5">
            <mat-label>Last Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="LastName" matInput>
              <mat-error *ngIf="newRiskManagerForm.controls['LastName'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-label>Sex</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <mat-select formControlName="Sex">
                <mat-option value="M">Male</mat-option>
                <mat-option value="F">Female</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-label>Company</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="Company" matInput>
              <mat-error>r>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label>Address 1</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="Address1" matInput>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label>Address 2</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="Address2" matInput>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-label>City</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="City" matInput>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-label>State <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="State" matInput mask="SS" oninput="this.value = this.value.toUpperCase()">
              <mat-error *ngIf="newRiskManagerForm.controls['State'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 2</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-label>Zip <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" formControlName="Zip" matInput mask="00000">
              <mat-error *ngIf="newRiskManagerForm.controls['Zip'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 5</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" cdkFocusInitial class="submit-btn">Create</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
