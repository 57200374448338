//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-loss-experience-dialog',
  templateUrl: './new-loss-experience-dialog.component.html',
  styleUrls: ['./new-loss-experience-dialog.component.scss']
})
export class NewLossExperienceDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewLossExperienceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.lossExperienceForm = new FormGroup({
      Crimeloss_yearId: new FormControl(0), //INT
      CrimeLossID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      LossDate: new FormControl('', Validators.required), //DATE
      LossDescription: new FormControl('', [Validators.required, Validators.maxLength(250)]), //STRING
      CorrectiveAction: new FormControl('', [Validators.required, Validators.maxLength(250)]), //STRING
      TotalAmount: new FormControl(0), //INT
      EffectiveDate: new FormControl('', [Validators.required, Validators.required]) //DATE
    });
  };

  public lossExperienceForm: FormGroup;

  ngOnInit(): void {
    this.lossExperienceForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear
    });
  };

  //Close modal and pass form data to controller
  submitLossExperienceForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

}
