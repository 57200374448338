import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-log-detail',
  templateUrl: './log-detail.component.html',
  styleUrls: ['./log-detail.component.scss']
})
export class LogDetailComponent implements OnInit {

  public differences: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.parseDifferences();
  }

  // Method to parse the differences string
  parseDifferences(): void {
    try {
      // Parse the differences string into an object
      if (this.data.differences) {
        this.differences = JSON.parse(this.data.differences);
      }
    } catch (error) {
      console.error('Error parsing differences JSON:', error);
    }
  }

  // Helper method to get the keys of the differences, excluding certain fields
  getDifferenceKeys(): string[] {
    if (this.differences) {
      // Exclude 'LastModifiedBy' and 'LastModified' fields
      return Object.keys(this.differences).filter(key => key !== 'LastModifiedBy' && key !== 'LastModified');
    }
    return [];
  }
}
