//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralInfoService {
  constructor(private http: HttpClient) { }

  getDetailGeneralInfo(municipalityID, municipalityYear: string): Observable<any> {
    return this.http.get('/api/General/GetDetailGeneralInfo', { params: { municipalityID, municipalityYear } });
  };

  getListRiskManagers(): Observable<any> {
    return this.http.get('/api/General/GetListRiskManagers');
  };

  updateGeneral(data): Observable<any> {
    return this.http.put('/api/General/UpdateGeneralInfo', data);
  };

};
