<div class="specific-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Specific</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="specificForm" (ngSubmit)="submitForm(specificForm)">
      <!--Airports-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="Airports">Airports</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-label>Town Owned or Operated?</mat-label>
            <input matInput formControlName="AirportsOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput formControlName="AirportsOwnedDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Amusement Parks-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="AmusementParks">Amusement Parks</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AmusementOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AmusementOwnedDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Mechanical Amusement Devices?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MechanicalAmusement" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Auditorium, Arena, Convention or Exhibition Center-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="ExhibitionCenter">Auditorium, Arena, Convention or Exhibition Center</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ExhibitionOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ExhibitionOwnedDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Square Footage/Capacity:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FootageCapacity" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Blasting Operations-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="BlastingOperations">Blasting Operations</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="BlastingOperationsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Cemetery-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="Cemetery">Cemetery</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="CemeteryDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Chemical Spraying-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="ChemicalSpraying">Chemical Spraying</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ChemicalSprayingDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Dams, Levees, or Dikes-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="DamDikes">Dams, Levees, or Dikes</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DamDikesDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Number of Dams, Levees, Dikes:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="NumbDamDikes" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Day Care Center, Day Camp, or Nursery-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="DayCare">Day Care Center, Day Camp, or Nursery</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description (Town Owned or Operated):</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DayCareOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Number of Facilities:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="NumbFacilities" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Number of Children:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="NumbChildren" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Electric Utility-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="ElectricUtility">Electric Utility</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ElectricUtilityDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Total Payroll</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TotalPayroll" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--EMT's, Paramedics-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="EMTParamedics">EMT's, Paramedics</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EMTParamedicsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Personnel:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EMTNumbPersonnel" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Calls per Year:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EMTNumbCalls" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Firefighters-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="Firefighters">Firefighters</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FirefightersDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Paid Personnel:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FireNumbPersonnel" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Volunteer Personnel:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FireNumbVolunteer" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Total Payroll:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FireTotalPayroll" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Fireworks (Town Sponsored)-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="TownFireworks">Fireworks (Town Sponsored)</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TownFireWorksDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Number of Events per Year:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TownFireWorksEvents" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Garbage/Refuse Collection-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="GarbageRefuse">Garbage/Refuse Collection</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GarbageRefuseDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>List Customers:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GarbageRefuseCustomers" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Gas Utility-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="GasUtility">Gas Utility</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GasUtilityDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Golf Courses-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="GolfCourses">Golf Courses</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GolfCoursesOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GolfCoursesDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Number of Courses:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GolfCoursesNumb" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Total Sales/Receipts:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GolfCoursesSales" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Housing Projects (Town Owned or Operated)-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="HousingProjectsTown">Housing Projects (Town Owned or Operated)</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="HousingProjectsTownDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Lakes or Reservoirs-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="LakesReservoirs">Lakes or Reservoirs</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LakesReservoirsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Lakes, Reservoirs:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LakesReservoirsNumb" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--LandfillSitesOwned-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="LandfillSites">Landfills/Dump/Refuse or Incinerator Sites</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LandfillSitesOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LandfillSitesDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Law Enforcement, Jails, or Detention Facilities-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="LawFacilities">Law Enforcement, Jails, or Detention Facilities</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawFacilitiesOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawFacilitiesDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Total payroll:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawFacilitiesPayroll" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Officers/Jailers Carrying Firearms: Full Time:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawFirearmsFull" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Officers/Jailers Carrying Firearms: Part Time:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawFirearmsPart" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of All Other Personnel:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawPersonnel" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Jails/Holding Cells:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawCells" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Average Length of Stay:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawLengthStay" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of dogs and/or horses</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="LawDogHorse" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Medical Care Facilities (Clinics, Nursing Homes, Hospitals)-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="MedicalFacilities">Medical Care Facilities (Clinics, Nursing Homes, Hospitals)</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MedicalFacilitiesOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MedicalFacilitiesDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Beds:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MedicalFacilitiesBeds" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Patients:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MedicalFacilitiesPatients" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Mowing Operations-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="MowingOperations">Mowing Operations</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="MowingOperationsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Parking Authority-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="ParkingAuthority">Parking Authority</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ParkingAuthorityDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Parks & Playgrounds (Town Owned)-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="ParksPlaygrounds">Parks & Playgrounds (Town Owned)</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Number and Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ParksPlaygroundsNumb" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Pistol Range-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="PistolRange">Pistol Range</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PistolRangeDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Racetracks-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="RaceTracks">Racetracks</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description (Town Owned or Operated):</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RaceTracksOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Schools/Colleges (Town Owned or Operated)-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="SchoolsColleges">Schools/Colleges (Town Owned or Operated)</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SchoolsCollegesDescription" placeholder=":" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Skating (Ice/Roller Skate/Blade/Skateboarding)-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="Skating">Skating (Ice/Roller Skate/Blade/Skateboarding)</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SkatingOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SkatingDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Ski Facilities & Similar Areas-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="SkiAreas">Ski Facilities & Similar Areas</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description (Town Owned or Operated):</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SkiAreasDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Special Events (Carnivals/Fairs/Parades/All Other)-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="SpecialEvents">Special Events (Carnivals/Fairs/Parades/All Other)</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Mechanical Amusement Devices?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SpecialAmusementDevices" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SpecialEventsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Number of Events per Year:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SpecialEventsYear" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Stadiums, Bleachers, Grandstands-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="Stadiums">Stadiums, Bleachers, Grandstands</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Town Owned or Operated?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StadiumsOwned" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StadiumsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># With Capacity Over 5,000:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StadiumsCapacity5k" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Total Capacity:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StadiumsTotalCapacity" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Streets, Roads, Bridges-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="Streets">Streets, Roads, Bridges</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StreetsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Number of Miles Paved:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StreetsPaved" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Unpaved:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StreetsUnpaved" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Bridges - # and Span of Each:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StreetsBridges" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Swimming Pools-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="SwimmingPools">Swimming Pools</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SwimmingPoolsDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># of Swimming Pools:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SwimmingPoolsNumb" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label># and Height of Diving Platforms:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SwimmingPoolsDiving" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Wastewater (Sewer) Utility-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="SewerUtility">Wastewater (Sewer) Utility</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SewerUtilityDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Total payroll:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SewerUtilityPayroll" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Water Utility-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="WaterUtility">Water Utility</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WaterUtilityDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Total payroll:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WaterUtilityPayroll" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Waterfront Properties-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="WaterfrontProperties">Waterfront Properties</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Beaches - total miles:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WaterfrontPropertiesMiles" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Waterfront Properties - Description:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WaterfrontPropertiesDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Boats - # of Town Owned or Operated Boats:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WaterfrontPropertiesBoats" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Piers/Docks - # of Town Owned or Operated Slips:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WaterfrontPropertiesPiers" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Marinas - # of Town Owned or Operated:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="WaterfrontPropertiesMarinas" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Zoo-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="Zoo">Zoo</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-label>Description (Town Owned or Operated):</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ZooDescription" placeholder="" />
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate -->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is requried</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="specificForm.disabled" class="submit-btn float-right">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
