//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-tank-location',
  templateUrl: './new-tank-location.component.html',
  styleUrls: ['./new-tank-location.component.scss']
})

export class NewTankDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewTankDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.tanksForm = new FormGroup({
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]),
      StorageTankInventoryDetailsID: new FormControl(0),
      TankNo: new FormControl('', [Validators.maxLength(50)]),
      UST_AST: new FormControl('', [Validators.maxLength(50)]),
      InstallDateYear: new FormControl('', [Validators.maxLength(50)]),
      Capacity: new FormControl('', [Validators.maxLength(50)]),
      Contents: new FormControl('', Validators.required),
      TankConstructionMaterial: new FormControl('', Validators.required),
      OverfillSpillProtection: new FormControl('', Validators.required),
      TankLeakDetection: new FormControl('', Validators.required),
      ASTDiking: new FormControl('', Validators.required),
      PipingConstruction: new FormControl('', Validators.required),
      PipingLeakDetection: new FormControl('', Validators.required),
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public tanksForm: FormGroup;

  ngOnInit() {    
    this.tanksForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear,
      'StorageTankInventoryDetailsID': this.data.StorageTankInventoryDetailsID
    });
  };

  //Close modal and pass form data to controller
  submitTank(form) {
    console.log('form submitted: ', form);
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
