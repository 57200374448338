<div class="reset-password-section-wrapper h-100">
  <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-12 col-lg-6 col-xl-6 align-self-center">
        <section class="mat-elevation-z8 white-box form-container">
          <div class="section-title">
            <h6>Reset password</h6>
          </div>
          <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm(resetPasswordForm)">
            <div class="row">
              <div class="col-md-12">
                <mat-label>Password <span class="field-required-symbol">*</span></mat-label>
                <mat-form-field class="field-full-width" appearance="fill">
                  <input type="password" matInput formControlName="Password" placeholder="" />
                  <mat-error *ngIf="resetPasswordForm.controls['Password'].hasError('required')">This field is Required!</mat-error>
                  <mat-error *ngIf="resetPasswordForm.controls['Password'].hasError('minlength')">Must be at least 6 characters</mat-error>
                  <mat-error *ngIf="resetPasswordForm.controls['Password'].hasError('hasCapitalCase')">Must contain at least 1 in Capital Case</mat-error>
                  <mat-error *ngIf="resetPasswordForm.controls['Password'].hasError('hasSmallCase')">Must contain at least 1 Letter in Small Case</mat-error>
                  <mat-error *ngIf="resetPasswordForm.controls['Password'].hasError('hasSpecialCharacter')">Must contain at least 1 special character</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-label>Confirm password <span class="field-required-symbol">*</span></mat-label>
                <mat-form-field class="field-full-width" appearance="fill">
                  <input type="password" matInput formControlName="ConfirmPassword" placeholder="" />
                  <mat-error *ngIf="resetPasswordForm.controls['ConfirmPassword'].hasError('required')"> Password is Required!</mat-error>
                  <mat-error *ngIf="resetPasswordForm.controls['ConfirmPassword'].hasError('NoPassswordMatch')">Password do not match</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- ActionButtons -->
            <div class="form-action-buttons">
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" mat-raised-button color="primary" [disabled]="resetPasswordForm.invalid" class="submit-btn">Change password</button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</div>

