<div class="fine-arts-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Fine Arts</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" [disabled]="!activeMunicipality" (click)="openNewDialog()">Add New Art</button>
          <button mat-raised-button type="button" color="primary" class="mb-2 ml-2" [disabled]="!activeMunicipality" (click)="exportExcel()">Export to Excel</button>
        </div>
      </div>
      <!-- Filter -->
      <div class="col-md-4">
        <mat-form-field class="field-full-width">
          <input matInput type="text" (keyup)="filterTable($event.target.value)" placeholder="Filter results">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table class="datatable" mat-table [dataSource]="dataSource" matSort matSortActive="location" matSortDirection="asc" [@.disabled]="true">
          <!-- Location -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
            <td mat-cell *matCellDef="let element"> {{element.location}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- year -->
          <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Year </th>
            <td mat-cell *matCellDef="let element"> {{element.year}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- artist -->
          <ng-container matColumnDef="artist">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Artist </th>
            <td mat-cell *matCellDef="let element"> {{element.artist}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- description -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            <td mat-footer-cell *matFooterCellDef>Total</td>
          </ng-container>
          <!-- appraisedValue -->
          <ng-container matColumnDef="appraisedValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Appraised Value </th>
            <td mat-cell *matCellDef="let element"> {{element.appraisedValue | currency}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateAppraisedValue() | currency}}</td>
          </ng-container>
          <!-- Action -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openUpdateDialog(element)">mode_edit</mat-icon>
              <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteDialog(element)">delete</mat-icon>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>



