<div class="crime-position-bond-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Crime Position Bond Application</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="bondApplicationForm" (ngSubmit)="submitForm(bondApplicationForm)">
      <!--Applicant-->
      <div class="row">
        <div class="col-md-12">
          <mat-label>Select Applicant:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select #applicantValue (selectionChange)="changeActiveApplication($event.value)" [disabled]="!activeMunicipality">
              <mat-option [value]="0">New Applicant</mat-option>
              <mat-option *ngFor="let application of availableApplications" [value]="application">{{application.applicantCity}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Only show the form if there is an applicant selected -->
      <div #bondApplicationFormHandle>
        <div class="row section-title">
          <div class="col-md-12 mb-2">
            <h5>Public Official Application and Indemnity Agreement</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--ApplicantFirstName-->
            <mat-label>First Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantFirstName" placeholder="" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantFirstName'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantMiddleName-->
            <mat-label>Middle Name</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantMiddleName" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <!--ApplicantLastName-->
          <div class="col-md-4">
            <mat-label>Last Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantLastName" placeholder="" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantLastName'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantStreetName-->
            <mat-label>Street Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantStreetName" placeholder="" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantStreetName'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantCity-->
            <mat-label>City <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantCity" placeholder="" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantCity'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!-- State -->
            <mat-label>State <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <mat-select formControlName="ApplicantState">
                <mat-option value="AL">Alabama</mat-option>
                <mat-option value="AK">Alaska</mat-option>
                <mat-option value="AZ">Arizona</mat-option>
                <mat-option value="AR">Arkansas</mat-option>
                <mat-option value="CA">California</mat-option>
                <mat-option value="CO">Colorado</mat-option>
                <mat-option value="CT">Connecticut</mat-option>
                <mat-option value="DE">Delaware</mat-option>
                <mat-option value="DC">District Of Columbia</mat-option>
                <mat-option value="FL">Florida</mat-option>
                <mat-option value="GA">Georgia</mat-option>
                <mat-option value="HI">Hawaii</mat-option>
                <mat-option value="ID">Idaho</mat-option>
                <mat-option value="IL">Illinois</mat-option>
                <mat-option value="IN">Indiana</mat-option>
                <mat-option value="IA">Iowa</mat-option>
                <mat-option value="KS">Kansas</mat-option>
                <mat-option value="KY">Kentucky</mat-option>
                <mat-option value="LA">Louisiana</mat-option>
                <mat-option value="ME">Maine</mat-option>
                <mat-option value="MD">Maryland</mat-option>
                <mat-option value="MA">Massachusetts</mat-option>
                <mat-option value="MI">Michigan</mat-option>
                <mat-option value="MN">Minnesota</mat-option>
                <mat-option value="MS">Mississippi</mat-option>
                <mat-option value="MO">Missouri</mat-option>
                <mat-option value="MT">Montana</mat-option>
                <mat-option value="NE">Nebraska</mat-option>
                <mat-option value="NV">Nevada</mat-option>
                <mat-option value="NH">New Hampshire</mat-option>
                <mat-option value="NJ">New Jersey</mat-option>
                <mat-option value="NM">New Mexico</mat-option>
                <mat-option value="NY">New York</mat-option>
                <mat-option value="NC">North Carolina</mat-option>
                <mat-option value="ND">North Dakota</mat-option>
                <mat-option value="OH">Ohio</mat-option>
                <mat-option value="OK">Oklahoma</mat-option>
                <mat-option value="OR">Oregon</mat-option>
                <mat-option value="PA">Pennsylvania</mat-option>
                <mat-option value="RI">Rhode Island</mat-option>
                <mat-option value="SC">South Carolina</mat-option>
                <mat-option value="SD">South Dakota</mat-option>
                <mat-option value="TN">Tennessee</mat-option>
                <mat-option value="TX">Texas</mat-option>
                <mat-option value="UT">Utah</mat-option>
                <mat-option value="VT">Vermont</mat-option>
                <mat-option value="VA">Virginia</mat-option>
                <mat-option value="WA">Washington</mat-option>
                <mat-option value="WV">West Virginia</mat-option>
                <mat-option value="WI">Wisconsin</mat-option>
                <mat-option value="WY">Wyoming</mat-option>
              </mat-select>
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantState'].hasError('required')">This field is requried</mat-error>
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantState'].hasError('isCapital')">Must be 2 characters long, capital letters only</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantCountry-->
            <mat-label>Applicant Country <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantCountry" placeholder="" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantCountry'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantZipCode-->
            <mat-label>Applicant Zip code <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantZipCode" placeholder="" mask="00000" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantZipCode'].hasError('required')">This field is required</mat-error>
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantZipCode'].hasError('isNumbersOnly')">This field accepts numbers only</mat-error>
              <mat-error>Must be 5 characters long</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantPhone-->
            <mat-label>Applicant Phone <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantPhone" placeholder="" mask="(000) 000-0000" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantPhone'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 20, numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantFax-->
            <mat-label>Fax</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantFax" placeholder="" mask="(000) 000-0000" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantFax'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 20, numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantEmail-->
            <mat-label>Email <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="email" matInput formControlName="ApplicantEmail" placeholder="" />
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantEmail'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 20, numbers only</mat-error>
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantEmail'].hasError('email')">Must be a valid email address</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ApplicantSSN-->
            <mat-label>Social Security Number <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantSSN" [type]="hideApplicantSSN ? 'password' : 'text'" placeholder="" mask="000-00-0000">
              <mat-icon matSuffix (click)="hideApplicantSSN = !hideApplicantSSN">{{hideApplicantSSN ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="bondApplicationForm.controls['ApplicantSSN'].hasError('required')">This field is required</mat-error>
              <mat-error>Character length for this field is 15</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--TypeOfBond-->
            <mat-label>Type of Bond Required</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="TypeOfBond" placeholder="" />
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--BondAmount-->
            <mat-label>Amount of Bond</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="BondAmount" placeholder="" mask="separator.2" thousandSeparator="," />
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--BondEffectiveDate-->
            <mat-label>Bond Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="BondEffectiveDate" [matDatepicker]="BondEffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="BondEffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #BondEffectiveDate></mat-datepicker>
              <mat-error>This field is requred</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsSpecialBondIssue-->
            <mat-checkbox formControlName="IsSpecialBondIssue">The Is a special bond issue, what is the purpose?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--WhatPurpose-->
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="WhatPurpose" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsBondDeclinedByAnotherCompany-->
            <mat-checkbox formControlName="IsBondDeclinedByAnotherCompany">Has an application for this bond declined by another company? If yes, which surety and why?</mat-checkbox>
          </div>
          <div class="col-md-6">
            <!--BondDeclinedByAnotherCompanySurety-->
            <mat-label>Surety</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="BondDeclinedByAnotherCompanySurety" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--BondDeclinedByAnotherCompanyReason-->
            <mat-label>Why</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="BondDeclinedByAnotherCompanyReason" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <!--IsCurrentlyBonded-->
          <div class="col-md-12">
            <mat-checkbox formControlName="IsCurrentlyBonded">Currently bonded? If yes, give name of surety and reason for change.</mat-checkbox>
          </div>
          <div class="col-md-6">
            <!--IsCurrentlyBondedSurety-->
            <mat-label>Surety</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="IsCurrentlyBondedSurety" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--IsCurrentlyBondedReason-->
            <mat-label>Reason</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="IsCurrentlyBondedReason" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row section-title">
          <div class="col-md-12 mb-2">
            <h5>Has the Applicant professionally or personally</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--IsLawSuitOrJudgments-->
            <mat-checkbox formControlName="IsLawSuitOrJudgments">Had any lawsuits or judgments against them?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsBankruptcy-->
            <mat-checkbox formControlName="IsBankruptcy">Ever failed in business or declared bankruptcy?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsConvictedCrime-->
            <mat-checkbox formControlName="IsConvictedCrime">Ever been convicted of a crime?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsLicenseSuspended-->
            <mat-checkbox formControlName="IsLicenseSuspended">Ever had their license suspended, revoked or denied, or been subject to any legal/administrative proceedings resulting in disciplinary action?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsPartyToABondClaim-->
            <mat-checkbox formControlName="IsPartyToABondClaim">Ever been party to a surety bond claim?</mat-checkbox>
          </div>
          <div class="col-md-6">
            <!--ObligeeFirstName-->
            <mat-label>Obligee's First Name</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ObligeeFirstName" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--ObligeeMiddleName-->
            <mat-label>Middle Name</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ObligeeMiddleName" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--ObligeeLastName-->
            <mat-label>Obligee's Last Name</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ObligeeLastName" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--ObligeeStreetName-->
            <mat-label>Street Name</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ObligeeStreetName" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--ObligeeCity-->
            <mat-label>City</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ObligeeCity" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!-- State -->
            <mat-label>State</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <mat-select formControlName="ObligeeState">
                <mat-option value="AL">Alabama</mat-option>
                <mat-option value="AK">Alaska</mat-option>
                <mat-option value="AZ">Arizona</mat-option>
                <mat-option value="AR">Arkansas</mat-option>
                <mat-option value="CA">California</mat-option>
                <mat-option value="CO">Colorado</mat-option>
                <mat-option value="CT">Connecticut</mat-option>
                <mat-option value="DE">Delaware</mat-option>
                <mat-option value="DC">District Of Columbia</mat-option>
                <mat-option value="FL">Florida</mat-option>
                <mat-option value="GA">Georgia</mat-option>
                <mat-option value="HI">Hawaii</mat-option>
                <mat-option value="ID">Idaho</mat-option>
                <mat-option value="IL">Illinois</mat-option>
                <mat-option value="IN">Indiana</mat-option>
                <mat-option value="IA">Iowa</mat-option>
                <mat-option value="KS">Kansas</mat-option>
                <mat-option value="KY">Kentucky</mat-option>
                <mat-option value="LA">Louisiana</mat-option>
                <mat-option value="ME">Maine</mat-option>
                <mat-option value="MD">Maryland</mat-option>
                <mat-option value="MA">Massachusetts</mat-option>
                <mat-option value="MI">Michigan</mat-option>
                <mat-option value="MN">Minnesota</mat-option>
                <mat-option value="MS">Mississippi</mat-option>
                <mat-option value="MO">Missouri</mat-option>
                <mat-option value="MT">Montana</mat-option>
                <mat-option value="NE">Nebraska</mat-option>
                <mat-option value="NV">Nevada</mat-option>
                <mat-option value="NH">New Hampshire</mat-option>
                <mat-option value="NJ">New Jersey</mat-option>
                <mat-option value="NM">New Mexico</mat-option>
                <mat-option value="NY">New York</mat-option>
                <mat-option value="NC">North Carolina</mat-option>
                <mat-option value="ND">North Dakota</mat-option>
                <mat-option value="OH">Ohio</mat-option>
                <mat-option value="OK">Oklahoma</mat-option>
                <mat-option value="OR">Oregon</mat-option>
                <mat-option value="PA">Pennsylvania</mat-option>
                <mat-option value="RI">Rhode Island</mat-option>
                <mat-option value="SC">South Carolina</mat-option>
                <mat-option value="SD">South Dakota</mat-option>
                <mat-option value="TN">Tennessee</mat-option>
                <mat-option value="TX">Texas</mat-option>
                <mat-option value="UT">Utah</mat-option>
                <mat-option value="VT">Vermont</mat-option>
                <mat-option value="VA">Virginia</mat-option>
                <mat-option value="WA">Washington</mat-option>
                <mat-option value="WV">West Virginia</mat-option>
                <mat-option value="WI">Wisconsin</mat-option>
                <mat-option value="WY">Wyoming</mat-option>
              </mat-select>
              <mat-error *ngIf="bondApplicationForm.controls['ObligeeState'].hasError('required')">This field is requried</mat-error>
              <mat-error *ngIf="bondApplicationForm.controls['ObligeeState'].hasError('isCapital')">Must be 2 characters long, capital letters only</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--ObligeeCountry-->
            <mat-label>Country</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ObligeeCountry" placeholder="" />
              <mat-error>Character length for this field is 100, numbers only</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--ObligeeZipCode-->
            <mat-label>Zip</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ObligeeZipCode" placeholder="" mask="00000" />
              <mat-error *ngIf="bondApplicationForm.controls['ObligeeZipCode'].hasError('isNumbersOnly')">Character length for this field 5 characters long, numbers only</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row section-title">
          <div class="col-md-12 mb-2">
            <h5>Please Provide the Following Additional Information</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--IsExperiencedPublicOfficialLosses-->
            <mat-checkbox formControlName="IsExperiencedPublicOfficialLosses">Has the Applicant experienced any public official losses in the last five year? If yes, please provide details.</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--ExperiencedPublicOfficialLossesDetails-->
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ExperiencedPublicOfficialLossesDetails" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--TitleOfApplicant-->
            <mat-label>Official Title of Applicant</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="TitleOfApplicant" placeholder="" />
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--TermsInYear-->
            <mat-label>Term of Office in Years <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="TermsInYear" placeholder="" />
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--Elected-->
            <mat-checkbox formControlName="Elected">Elected</mat-checkbox>
          </div>
          <div class="col-md-3">
            <!--Appointed-->
            <mat-checkbox formControlName="Appointed">Appointed</mat-checkbox>
          </div>
          <div class="col-md-3">
            <!--StartDate-->
            <mat-label>Start Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="StartDate" [matDatepicker]="StartDate">
              <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
              <mat-datepicker #StartDate></mat-datepicker>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--EndDate-->
            <mat-label>End Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="EndDate" [matDatepicker]="EndDate">
              <mat-datepicker-toggle matSuffix [for]="EndDate"></mat-datepicker-toggle>
              <mat-datepicker #EndDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsApplicantOccupiedPositionPreviously-->
            <mat-checkbox formControlName="IsApplicantOccupiedPositionPreviously">Has the Applicant previously occupied this position? If yes, during what period?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--PositionDuration-->
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="PositionDuration" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsApplicantCurrentlyHaveLiability-->
            <mat-checkbox formControlName="IsApplicantCurrentlyHaveLiability">Does the Applicant currently have E&O or Liability Insurance? If so, please provide the following information.</mat-checkbox>
          </div>
          <div class="col-md-4">
            <!--LiabilityCompanyName-->
            <mat-label>Liability Company Name</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="LiabilityCompanyName" placeholder="" />
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--LiabilityLimit-->
            <mat-label>Liability Limit</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="LiabilityLimit" placeholder="" mask="separator.2" thousandSeparator="," />
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--LiabilityPolicyNumber-->
            <mat-label>Policy Number</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="LiabilityPolicyNumber" placeholder="" />
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row section-title">
          <div class="col-md-12 mb-2">
            <h5>Agent's Recommendation</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--DescribeAgentApplicantRelationship-->
            <mat-label>Describe the length and nature of your relationship with the Applicant:</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="DescribeAgentApplicantRelationship" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsAgentRecommendApplicant-->
            <mat-checkbox formControlName="IsAgentRecommendApplicant">Do you recommend the Applicant for this bond? Why?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--AgentRecommendReson-->
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="AgentRecommendReson" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--AmountHandledAnnually-->
            <mat-label>1. Amount of money handled during an annual term</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="AmountHandledAnnually" placeholder="" mask="separator.2" thousandSeparator="," />
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--LargetsAmountUnderYourControl-->
            <mat-label>2. Largest amount at any one time under your control</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="LargetsAmountUnderYourControl" placeholder="" mask="separator.2" thousandSeparator="," />
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsFundDeposited-->
            <mat-checkbox formControlName="IsFundDeposited">3. Are funds deposited as received?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsAggreedToUseDepositories-->
            <mat-checkbox formControlName="IsAggreedToUseDepositories">4. Have you agreed to use only depositories designated by your superiors?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsApplicantHaveWithdrawAuthority-->
            <mat-checkbox formControlName="IsApplicantHaveWithdrawAuthority">5. Does the Applicant have the authority to withdraw funds from depository by check?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsCounterSignatureRequired-->
            <mat-checkbox formControlName="IsCounterSignatureRequired">Is countersignature required?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--CounterSignatureByWhom-->
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="CounterSignatureByWhom" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsApplicantCustodianOfSecurities-->
            <mat-checkbox formControlName="IsApplicantCustodianOfSecurities">6. Is Applicant a custodian of securities?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--CustodianAmount-->
            <mat-label>What amount?</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="CustodianAmount" placeholder="" />
              <mat-error>Character length for this field is 20</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--SecurityKept-->
            <mat-label>Where are securities kept</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="SecurityKept" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsJointControl-->
            <mat-checkbox formControlName="IsJointControl">Is there joint control? If yes, by whom?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--JointControlByWhom-->
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="JointControlByWhom" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsAccountAuditedAnnually-->
            <mat-checkbox formControlName="IsAccountAuditedAnnually">7. Are your accounts audited on an annual basis? If yes, by whom?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--AccountAuditedByWhom-->
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="AccountAuditedByWhom" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsCPARecommendations-->
            <mat-checkbox formControlName="IsCPARecommendations">8. Did the CPA make recommendations during the last audit? If yes, attach a copy of the recommendations and management's response?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsApplicantCollectTaxes-->
            <mat-checkbox formControlName="IsApplicantCollectTaxes">9. Does the Applicant collect taxes?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--ApplicantMakeReportofInSolvenciesDelinquencies-->
            <mat-label>10.To whom and when does the Applicant make a report of insolvencies and delinquencies?</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input matInput formControlName="ApplicantMakeReportofInSolvenciesDelinquencies" placeholder="" />
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <!--IsApplicantResponsibleForFundInvestment-->
            <mat-checkbox formControlName="IsApplicantResponsibleForFundInvestment">11. If yes, is the Applicant responsible for investment of funds?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsObligeeApprovedInvestmentPolicy-->
            <mat-checkbox formControlName="IsObligeeApprovedInvestmentPolicy">Is there a published investment policy which has been approved by obligee?</mat-checkbox>
          </div>
          <div class="col-md-12">
            <!--IsOtherThanApplicantCheckingPolicy-->
            <mat-checkbox formControlName="IsOtherThanApplicantCheckingPolicy">12. Is there someone other than the Applicant checking at least annually to be sure the investment policy is being followed?</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--EffectiveDate-->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="bondApplicationForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
