//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  constructor(private http: HttpClient, private router: Router) { };

  public getAllDepartments() {
    return this.http.get('/api/General/GetDepartments');
  };

  public departmentCreate(data) {
    return this.http.post('/api/General/AddDepartments', data);
  };

  public departmentEdit(data) {
    return this.http.put('/api/General/UpdateDepartments', data);
  };

  public departmentDelete(data: string) {
    return this.http.delete('/api/General/DeleteDepartments', { params: { id: data } });
  };

};

