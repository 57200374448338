<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Add New Tank</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">

    <form [formGroup]="tanksForm" (ngSubmit)="submitTank(tanksForm)">
      <div class="row">
        <div class="col-md-12">
          <div class="form-section-title">
            <h6>TANK INFORMATION</h6>
          </div>
        </div>
      </div>

      <!-- Tank Details -->
      <div class="row">
        <!-- Tank # -->
        <div class="col-md-4">
          <span class="label">Tank #</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="TankNo" placeholder="Tank Number" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <!-- UST/AST -->
        <div class="col-md-4">
          <span class="label">UST/AST</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="UST_AST" placeholder="UST/AST" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <!-- Install Date Year -->
        <div class="col-md-4">
          <span class="label">Install Date Year</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="InstallDateYear" placeholder="Install Date Year" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <!-- Capacity -->
        <div class="col-md-4">
          <span class="label">Capacity</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Capacity" placeholder="Capacity" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <!-- Contents -->
        <div class="col-md-4">
          <span class="label">Contents</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="Contents" placeholder="Select">
              <mat-option value="A">A. Unleaded Gasoline</mat-option>
              <mat-option value="B">B. Gasohol</mat-option>
              <mat-option value="C">C. Diesel</mat-option>
              <mat-option value="D">D. Kerosene</mat-option>
              <mat-option value="E">E. Waste Oil/ Used Oil</mat-option>
              <mat-option value="F">F. Fuel Oil</mat-option>
              <mat-option value="G">G. Generic Gasoline</mat-option>
              <mat-option value="H">H. Pesticide</mat-option>
              <mat-option value="I">I. Ammonia compound</mat-option>
              <mat-option value="J">J. Chlorine compound</mat-option>
              <mat-option value="K">K. Haz. Substance (CERCLA)</mat-option>
              <mat-option value="L">L. Mineral Acids</mat-option>
              <mat-option value="M">M. Grades 5&amp;amp;6 bunker 'C' oils</mat-option>
              <mat-option value="N">N. Petroleum-base  additive(E85)</mat-option>
              <mat-option value="O">O. Misc. petroleum-base</mat-option>
              <mat-option value="P">P.Heating Oil</mat-option>
              <mat-option value="Qs">Q.Other</mat-option>
            </mat-select>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <!-- Tank Construction Material -->
        <div class="col-md-4">
          <span class="label">Tank Construction Material</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="TankConstructionMaterial" placeholder="Select">
              <mat-option value="A">A. Steel</mat-option>
              <mat-option value="B">B. Fiberglass</mat-option>
              <mat-option value="C">C. FRP Clad Steel</mat-option>
              <mat-option value="D">D. Concrete</mat-option>
              <mat-option value="E">E. Polyethylene</mat-option>
              <mat-option value="F">F. Other EPA/DEP Approved</mat-option>
              <mat-option value="G">G. Cathodic Protection Sacrificial Anode</mat-option>
              <mat-option value="H">H. Cathodic Protection -Impressed Current</mat-option>
              <mat-option value="I">I.  Double Walled(DW)-Single Material</mat-option>
              <mat-option value="J">J. Double Walled (DW)-Dual Material</mat-option>
              <mat-option value="K">K. (DW)Synthetic Liner in Tank Construction</mat-option>
              <mat-option value="L">L. (DW)Pipeless UST with Secondary Containment</mat-option>
              <mat-option value="M">M. Internal Lining STI. STI-P3</mat-option>
            </mat-select>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Additional Fields -->
      <div class="row">
        <!-- Overfill/Spill Protection -->
        <div class="col-md-4">
          <span class="label">Overfill/Spill Protection</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="OverfillSpillProtection" placeholder="Select">
              <mat-option value="A">A. Ball Check Valve</mat-option>
              <mat-option value="B">B. Spill Containment Bucket</mat-option>
              <mat-option value="C">C. Flow Shut-off</mat-option>
              <mat-option value="D">D. Tight Fill</mat-option>
              <mat-option value="E">E. Level Gauges, High Level Alarms</mat-option>
              <mat-option value="F">F. Other EPA/DEP Approved Protection Method</mat-option>
            </mat-select>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <!-- Tank Leak Detection -->
        <div class="col-md-4">
          <span class="label">Tank Leak Detection</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="TankLeakDetection" placeholder="Select">
              <mat-option value="A">A. Groundwater Monitoring Wells</mat-option>
              <mat-option value="B">B. Interstitial Monitoring</mat-option>
              <mat-option value="C">C. Vapor Monitoring Wells</mat-option>
              <mat-option value="D">D. Visual Inspections of AST Systems</mat-option>
              <mat-option value="E">E. Other EPA/DEP Approved</mat-option>
              <mat-option value="F">F. SPCC Plan - AST</mat-option>
              <mat-option value="G">G. Interstitial Space -Double Walled Tank</mat-option>
              <mat-option value="H">H. Manual Tank Gauging - UST</mat-option>
              <mat-option value="I">I. Statistical Inventory Reconciliation-(SIR)(USTs)</mat-option>
              <mat-option value="J">J. Automatic Tank Gauging System (USTs)</mat-option>
              <mat-option value="K">K. Interstitial Monitoring of AST tank bottom</mat-option>
              <mat-option value="L">L. Annual Tightness Test with Inventory-(USTs)</mat-option>
              <mat-option value="M">M. Grades 5&amp;amp;6 bunker 'C' oils</mat-option>
              <mat-option value="N">N. Petroleum-base  additive(E85)</mat-option>
              <mat-option value="O">O. Misc. petroleum-base</mat-option>
            </mat-select>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <!-- AST Diking & Base Construction -->
        <div class="col-md-4">
          <span class="label">AST Diking & Base Construction</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="ASTDiking" placeholder="Select">
              <mat-option value="A">A. Concrete, Synthetic Material, clays</mat-option>
              <mat-option value="B">B. Other EPA/DEP approved secondary containment system</mat-option>
              <mat-option value="C">C. Dirt/Earth</mat-option>
            </mat-select>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Final Row for Piping -->
      <div class="row">
        <!-- Piping Construction Material -->
        <div class="col-md-4">
          <span class="label">Piping Construction Material</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="PipingConstruction" placeholder="Select">
              <mat-option value="A">A. Steel</mat-option>
              <mat-option value="B">B. Fiberglass</mat-option>
              <mat-option value="C">C. Double walled</mat-option>
              <mat-option value="D">D. Approved Synthetic Material</mat-option>
              <mat-option value="E">E. Other EPA/DEP Approved Piping Material</mat-option>
              <mat-option value="F">F. External Protective Coating</mat-option>
              <mat-option value="G">G. C/P with sacrificial anode or impressed current</mat-option>
            </mat-select>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <!-- Piping Leak Detection -->
        <div class="col-md-4">
          <span class="label">Piping Leak Detection</span>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="PipingLeakDetection" placeholder="Select">
              <mat-option value="A">A. Electronic Line Leak Detector with Flow Shutoff</mat-option>
              <mat-option value="B">B. Interstitial Monitoring – Piping Filter</mat-option>
              <mat-option value="C">C. External Monitoring</mat-option>
              <mat-option value="D">D. Mechanical Line Leak Detector</mat-option>
              <mat-option value="E">E. Interstitial Monitoring of double wall piping</mat-option>
              <mat-option value="F">F. Suction Pump Check Valve</mat-option>
            </mat-select>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>


      <!-- Submit Button -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>


  </div>
</mat-dialog-content>
