<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Update Equipment</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="updateEquipmentForm" (ngSubmit)="submitUpdateEquipmentForm(updateEquipmentForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Eqipment Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--DepartmentID -->
            <mat-label>Department <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <mat-select formControlName="DepartmentID">
                <mat-option *ngFor="let department of departments" [value]="department.departmentID">{{department.department}}</mat-option>
              </mat-select>
              <mat-error *ngIf="updateEquipmentForm.controls['DepartmentID'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--SerialNumber -->
            <mat-label>Serial Number <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="SerialNumber">
              <mat-error *ngIf="updateEquipmentForm.controls['SerialNumber'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--Manufacturer -->
            <mat-label>Manufacturer <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Manufacturer">
              <mat-error *ngIf="updateEquipmentForm.controls['Manufacturer'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Model -->
            <mat-label>Model</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Model">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--EquipYear -->
            <mat-label>Equip Year</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EquipYear">
              <mat-error>Character length for this field is 4</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--InsuranceLimit -->
            <mat-label>Insurance Limit</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="InsuranceLimit">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--GrossWeight -->
            <mat-label>GrossWeight</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="GrossWeight">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--EquipDescription -->
            <mat-label>Equip Description</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EquipDescription">
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--EquipNumber -->
            <mat-label>Equip Number</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EquipNumber">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--GarageLocation -->
            <mat-label>Garage Location</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="GarageLocation">
              <mat-error>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Financing Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Leased -->
            <mat-checkbox formControlName="Leased">Leased</mat-checkbox>
          </div>
          <div class="col-md-2">
            <!--LeaseAddIns -->
            <mat-checkbox formControlName="LeaseAddIns">Lease Add Ins</mat-checkbox>
          </div>
          <div class="col-md-2">
            <!--LeaseLossPayee -->
            <mat-checkbox formControlName="LeaseLossPayee">Lease Loss Payee</mat-checkbox>
          </div>
          <div class="col-md-3">
            <!--LeasingCompany -->
            <mat-label>Leasing Company</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="LeasingCompany">
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Financed -->
            <mat-checkbox formControlName="Financed">Financed</mat-checkbox>
          </div>
          <div class="col-md-2">
            <!--FinanceLossPayee -->
            <mat-checkbox formControlName="FinanceLossPayee">Finance Loss Payee</mat-checkbox>
          </div>
          <div class="col-md-3">
            <!--FinanceCompany -->
            <mat-label>Finance Company</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FinanceCompany">
              <mat-error>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-5">
            <!--Comments -->
            <mat-label>Comments</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Comments">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Update</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

