// Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-new-pilot-dialog',
  templateUrl: './new-pilot-dialog.component.html',
  styleUrls: ['./new-pilot-dialog.component.scss'],
  providers: [DatePipe] // Provide DatePipe
})
export class NewPilotDialogComponent implements OnInit {
  public newPilotForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NewPilotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifier: NotifierService,
    private datePipe: DatePipe // Inject DatePipe
  ) {
    // Initialize form group with form controls
    this.newPilotForm = new FormGroup({
      QuestionnairePilot_yearId: new FormControl(0), // INT
      QuestionnairePilotID: new FormControl(0), // INT
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), // STRING
      NameofPilot: new FormControl('', [Validators.maxLength(50)]), // STRING
      DOB: new FormControl('', Validators.required), // DATE (keeps Date object)
      Stud: new FormControl(false), // BOOL
      Pvt: new FormControl(false), // BOOL
      Coml: new FormControl(false), // BOOL
      ASEL: new FormControl(false), // BOOL
      AMEL: new FormControl(false), // BOOL
      Instrumt: new FormControl(false), // BOOL
      ATP: new FormControl(false), // BOOL
      Other: new FormControl('', Validators.maxLength(50)), // STRING
      DateLastPhysical: new FormControl(''), // STRING
      Class: new FormControl('', Validators.maxLength(50)), // STRING
      Total: new FormControl(0), // INT
      RetractGear: new FormControl('', Validators.maxLength(50)), // STRING
      MultiEngine: new FormControl('', Validators.maxLength(50)), // STRING
      Last90days: new FormControl(0), // INT
      Last12Mos: new FormControl(0), // INT
      Last12MosPilot: new FormControl('', Validators.maxLength(50)), // STRING
      Next12MosPilot: new FormControl('', Validators.maxLength(50)), // STRING
      FAACrtNoPilot: new FormControl('', Validators.maxLength(50)), // STRING
      DateLastBiennialRev: new FormControl(''), // DATE (keeps Date object)
      EffectiveDate: new FormControl('', Validators.required) // DATE
    });
  }

  ngOnInit(): void {
    // Set initial values for form fields if data is passed
    this.newPilotForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear
    });

    // Subscribe to changes in DOB and DateLastBiennialRev fields to preserve Date object
    this.newPilotForm.get('DOB').valueChanges.subscribe(value => {
      this.formatDateField('DOB', value);
    });
    this.newPilotForm.get('DateLastPhysical').valueChanges.subscribe(value => {
      this.formatDateField('DateLastPhysical', value);
    });
    this.newPilotForm.get('DateLastBiennialRev').valueChanges.subscribe(value => {
      this.formatDateField('DateLastBiennialRev', value);
    });
  }

  // Helper method to format date fields (without losing the Date object)
  formatDateField(field: string, value: any) {
    if (value instanceof Date) {
      // Ensure it's a valid Date object, but don't format it here to preserve validation
      this.newPilotForm.get(field).setValue(value, { emitEvent: false });
    }
  }

  // Close modal and pass form data to controller
  submitNewPilotForm(form) {
    if (form.valid) {
      // Convert date fields to the required format (d0/M0/0000) before submission
      const formattedDOB = this.datePipe.transform(form.value.DOB, 'dd/MM/yyyy');
      const formattedDateLastPhysical = this.datePipe.transform(form.value.DateLastPhysical, 'dd/MM/yyyy');
      const formattedDateLastBiennialRev = this.datePipe.transform(form.value.DateLastBiennialRev, 'dd/MM/yyyy');      

      // Create the final object with formatted dates
      const finalFormValue = {
        ...form.value,
        DOB: formattedDOB, // Format the DOB field
        DateLastPhysical: formattedDateLastPhysical, // Format the DateLastBiennialRev field
        DateLastBiennialRev: formattedDateLastBiennialRev // Format the DateLastBiennialRev field
      };

      // Close dialog and return form value
      this.dialogRef.close(finalFormValue);
      this.notifier.notify('success', 'New pilot record created successfully!');
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  }

  // Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  }
}
