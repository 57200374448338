<mat-toolbar id="main-toolbar" color="primary" layout="fill">
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
  </button>
  <img id="app-logo" src="../../assets/images/logo/pejif-original-logo.svg" class="img-fluid" />
  <span class="fill-space"></span>
  <span class="user-info">
    <span class="name">{{authService?.activeAccount?.fullName}}</span>
    <span class="email">{{authService?.activeAccount?.email}}</span>
  </span>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Profile menu">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/dashboard/user-profile">
      <mat-icon>edit</mat-icon>
      <span>My Account</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container class="admin-main-container">
  <mat-sidenav #sidenav mode="side" opened class="admin-sidenav-menu">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content class="admin-sidenav-content">
    <div class="row" [ngClass]="{'notAllowed': isReadOnly}">
      <div class="col-md-12" [ngClass]="{'readOnly': isReadOnly}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


