//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-location-dialog',
  templateUrl: './delete-tank-dialog.component.html',
  styleUrls: ['./delete-tank-dialog.component.scss']
})
export class DeleteTankDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteTankDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
  };

  //Close modal and pass form data to controller
  confimLocationDeletion() {
    this.dialogRef.close(this.data.storageTank_yearID);
  };

  //Close without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
