<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Departments Id's</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="fill" class="field-full-width">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Department Name" #input>
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="departmentID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
          <td mat-cell *matCellDef="let element"> {{element.departmentID}} </td>
        </ng-container>
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Name </th>
          <td mat-cell *matCellDef="let element"> {{element.department}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="DepartmentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: DepartmentColumns;"></tr>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="dialog-action-buttons">
        <div mat-dialog-actions class="float-right">
          <button mat-button mat-dialog-close>Close</button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
