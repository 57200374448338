//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-location-exposure-dialog',
  templateUrl: './delete-location-exposure-dialog.component.html',
  styleUrls: ['./delete-location-exposure-dialog.component.scss']
})
export class DeleteLocationExposureDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteLocationExposureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  //Close modal and pass form data to controller
  confimLocationExposureDeletion() {
    this.dialogRef.close(this.data.crimeLocationExposure_yearId);
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };

}
