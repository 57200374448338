<div class="storage-tank-marina-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <form [formGroup]="marinaQuestionnaireForm" (ngSubmit)="submitForm(marinaQuestionnaireForm)">
      <div class="row">
        <div class="col-md-12">
          <h5> Answer the following questions in relation to any facility identified as a “marina” or any storage tank(s) located within one (1) mile of a body of water</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-label>1. Please provide the facility name, full address and photo of the storage tank(s) and associated piping and appurtenances connected there to <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FacilityDetails" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['FacilityDetails'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--IsSpillPrevention-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsSpillPrevention">2. Has a Spill Prevention, Control and Countermeasure Plan been completed within the past five (5) years? (If “Yes”, please provide a copy of the report.)</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h6>3. What is the distance from the storage tank to the nearest body of water? Also, please provide a description of the environment surrounding the tank?</h6>
        </div>
      </div>
      <div class="row">
        <!--ESLessThan2000-->
        <div class="col-md-4">
          <mat-label>Less Than 2000 feet <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ESLessThan2000" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['ESLessThan2000'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <!--ESLessThan1Mile-->
        <div class="col-md-4">
          <mat-label>Less Than 1 mile <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ESLessThan1Mile" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['ESLessThan1Mile'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <!--ESMoreThan1Mile-->
        <div class="col-md-4">
          <mat-label>More Than 1 mile <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ESMoreThan1Mile" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['ESMoreThan1Mile'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h6>4. What is the distance from the facility to the nearest recreational swimming area on this body of water?</h6>
        </div>
      </div>
      <div class="row">
        <!--RSLessThan2000-->
        <div class="col-md-4">
          <mat-label>Less Than 2000 feet <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RSLessThan2000" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['RSLessThan2000'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <!--RSLessThan1Mile-->
        <div class="col-md-4">
          <mat-label>Less Than 1 mile <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RSLessThan1Mile" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['RSLessThan1Mile'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <!--RSMoreThank1Mile-->
        <div class="col-md-4">
          <mat-label>More than 1 mile <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RSMoreThank1Mile" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['RSMoreThank1Mile'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--IsPipingAssociatedStorageTank-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsPipingAssociatedStorageTank">5. Is all piping associated with the storage tank double-walled?</mat-checkbox>
        </div>
      </div>
      <!--IsPipingAssociatedUV-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsPipingAssociatedUV"> 6. Is the piping associated with the storage tank UV Resistant?</mat-checkbox>
        </div>
      </div>
      <!--IsPipingTested-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsPipingTested">7. What year was the piping associated with the storage tank installed? Has the piping ever been tested? (If “Yes”, provide a copy of the test results.)</mat-checkbox>
        </div>
      </div>
      <!--PipingDesc-->
      <div class="row">
        <div class="col-md-12">
          <mat-label>Piping Desc<span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PipingDesc" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['PipingDesc'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--IsPipingExtendsUnderWater-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsPipingExtendsUnderWater">8. Does the facility have piping that extends under the water? (If “Yes”, please describe and provide the Spill Prevention, Control and Countermeasure Plan in place for this piping.)</mat-checkbox>
        </div>
      </div>
      <!--ExtendsUnderWaterDesc-->
      <div class="row">
        <div class="col-md-12">
          <mat-label>Extends Under Water Desc<span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ExtendsUnderWaterDesc" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['ExtendsUnderWaterDesc'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--IsPipingExtendsOverWater-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsPipingExtendsOverWater"> 9. Does the facility have piping that extends over the water, including along bulkheads, docks or floating docks? (If “Yes”, please describe and provide the Spill Prevention, Control and Countermeasure Plan in place for this piping.)</mat-checkbox>
        </div>
      </div>
      <!--ExtendsOverWaterDesc-->
      <div class="row">
        <div class="col-md-12">
          <mat-label>Extends Over Water Desc<span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ExtendsOverWaterDesc" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['ExtendsOverWaterDesc'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--IsShutOffValve-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsShutOffValve">10. Does the facility have a shut-off valve located on land that will stop the flow of product in the event of a release? (If “Yes”, please describe the placement of the valve and shut-off process.)</mat-checkbox>
        </div>
      </div>
      <!--ShutOffValveDesc-->
      <div class="row">
        <div class="col-md-12">
          <mat-label>Shut Off Valve Desc<span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ShutOffValveDesc" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['ShutOffValveDesc'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--IsStorageTankProtected-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsStorageTankProtected">11. Are all dispensers associated with the storage tank protected from impact from boats or watercraft? (If “Yes”, please describe how.)</mat-checkbox>
        </div>
      </div>
      <!--StorageTankDesc-->
      <div class="row">
        <div class="col-md-12">
          <mat-label>Storage Tank Desc<span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="StorageTankDesc" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['StorageTankDesc'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--IsAboveground-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="IsAboveground">12. If the facility has aboveground storage tanks, do they have secondary containment? (If “Yes”, please describe.)</mat-checkbox>
        </div>
      </div>
      <!--AbovegroundDesc-->
      <div class="row">
        <div class="col-md-12">
          <mat-label>Above ground Desc<span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AbovegroundDesc" placeholder="" />
            <mat-error *ngIf="marinaQuestionnaireForm.controls['AbovegroundDesc'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 200</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate -->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is requried</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="marinaQuestionnaireForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
