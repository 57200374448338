<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Update Payroll</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="updatePayrollForm" (ngSubmit)="submitUpdatePayrollForm(updatePayrollForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Payroll Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--ClassificationDescription -->
            <mat-label>Classification Description</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="ClassificationDescription">
              <mat-error>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--ClassCode -->
            <mat-label>Class Code</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="ClassCode">
              <mat-error>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--FullYear -->
            <mat-label>Full Year <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FullYear" [matDatepicker]="FullYear">
              <mat-datepicker-toggle matSuffix [for]="FullYear"></mat-datepicker-toggle>
              <mat-datepicker #FullYear></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Fiscal Year -->
            <mat-label>Fiscal Year</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="FiscalYear">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Payroll -->
            <mat-label>Payroll</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Payroll">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--FullTime -->
            <mat-label>Full Time</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="FullTime">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--PartTime -->
            <mat-label>Part Time</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="PartTime">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Seasonal -->
            <mat-label>Seasonal</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Seasonal">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Volunteer -->
            <mat-label>Volunteer</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Volunteer">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Update</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

