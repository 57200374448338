//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-location-dialog',
  templateUrl: './new-location-dialog.component.html',
  styleUrls: ['./new-location-dialog.component.scss']
})
export class NewLocationDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.newLocationForm = new FormGroup({
      StorageTankLocationDetails_yearId: new FormControl(0), //INT
      StorageTankLocationDetailsID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      CompanyName: new FormControl('', Validators.maxLength(50)), //STRING
      Address: new FormControl('', Validators.maxLength(50)), //STRING
      USTLocation: new FormControl(0), //INT
      ASTLocation: new FormControl('', Validators.maxLength(20)), //STRING
      Contamination: new FormControl('', Validators.maxLength(50)), //STRING
      FacilityType: new FormControl('', Validators.maxLength(50)), //STRING
      FacilityTypeOther: new FormControl('', Validators.maxLength(50)), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public newLocationForm: FormGroup;

  ngOnInit() {
    this.newLocationForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear
    });
  };

  //Close modal and pass form data to controller
  submitNewLocationForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
