//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-risk-manager-dialog',
  templateUrl: './delete-risk-manager-dialog.component.html',
  styleUrls: ['./delete-risk-manager-dialog.component.scss']
})
export class DeleteRiskManagerDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteRiskManagerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  //Close modal and pass form data to controller
  confimRiskManagerDeletion() {
    this.dialogRef.close(this.data.riskManagerID);
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };
}
