//Import Modules
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { NotifierService } from "angular-notifier";

//Import Services
import { SchedulesService } from '../../../shared/services/schedules/schedules.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

//Import Components
import { NewVehicleDialogComponent } from './dialog/new-vehicle-dialog/new-vehicle-dialog.component';
import { UpdateVehicleDialogComponent } from './dialog/update-vehicle-dialog/update-vehicle-dialog.component';
import { DeleteVehicleDialogComponent } from './dialog/delete-vehicle-dialog/delete-vehicle-dialog.component';

//Export to Excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private schedulesService: SchedulesService, private authService: AuthService, private notifier: NotifierService) { }
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public activeMunicipality;
  public sort;

  //Vehicles table
  public displayedColumns: string[] = ["departmentName", "vehicleYear", "make", "model", "vin", "costNew", "classCode", "action"];
  public data;
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMunicipality = response;
        this.getTableList(response);
      }
    });
  };

  public getTableList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListVehicles').subscribe((response: any) => {
      if (!response) {
        return
      }
      else {
        this.data = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
      }
    });
  };

  public getDetails(employeeConcentrationID) {
    this.schedulesService.getDetail(employeeConcentrationID, 'GetDetailEmployeeConcentrations').subscribe((response: any) => {
      console.log(response);
    });
  };

  public openNewDialog() {
    const dialogRef = this.dialog.open(NewVehicleDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.add(result, 'AddVehicle').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public openUpdateDialog(data) {
    const dialogRef = this.dialog.open(UpdateVehicleDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.update(result, 'UpdateVehicle').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeleteVehicleDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.delete(result, 'DeleteVehicle').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  }

  public calculateCostNew() {
    return this.dataSource.filteredData.map(t => t.costNew).reduce((acc, value) => acc + value, 0);
  }

  public filterTable = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Vehicle');

    worksheet.columns = [
      { header: 'Department', key: 'departmentName', width: 22 },
      { header: 'Year', key: 'vehicleYear', width: 12 },
      { header: 'Make', key: 'make', width: 20 },
      { header: 'Model', key: 'model', width: 29 },
      { header: 'VIN', key: 'vin', width: 24 },
      { header: 'Cost New', key: 'costNew', width: 17 },
      { header: 'Class Code', key: 'classCode', width: 12 },
    ];

    worksheet.getColumn(6).numFmt = '"$"#,##0.00';

    this.data.forEach(e => {
      worksheet.addRow({
        departmentName: e.departmentName,
        vehicleYear: e.vehicleYear,
        make: e.make,
        model: e.model,
        vin: e.vin,
        costNew: e.costNew,
        classCode: e.classCode
      }, "n");
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Vehicles.xlsx');
    })

  }
}
