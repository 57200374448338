//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//Import Components
import { DepartmentsHelperComponent } from './dialog/departments-helper/departments-helper.component';

//Import Services
import { AuthService } from '../../shared/services/auth/auth.service';
import { RenewalApplicationsService } from '../../shared/services/renewal-applications/renewal-applications.service';
import { UploadService } from '../../shared/services/upload/upload.service';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.scss']
})
export class UploadsComponent implements OnInit {
  constructor(private notifier: NotifierService, private uploadService: UploadService, private renewalApplicationsService: RenewalApplicationsService, private authService: AuthService, private dialog: MatDialog) {
    this.uploadForm = new FormGroup({
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl(''), //STRING
      File: new FormControl('', Validators.required), //STRING
      UploadTable: new FormControl('', Validators.required), //STRING
    });
  };

  public activeMuniciaplity;
  public uploadForm: FormGroup;
  public selectedFile: any;

  ngOnInit(): void {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMuniciaplity = response;
        this.uploadForm.patchValue({
          'MunicipalityID': this.activeMuniciaplity.municipalityID,
          'MunicipalityYear': this.activeMuniciaplity.municipalityYear
        })
      }
    });
  };

  public selectFile(event) {
    if (event.target.files && event.target.files.length) {
      this.uploadForm.patchValue({
        File: event.target.files[0]
      });
    };
  };

  //Open departments dialog
  openDepartmentsDialog() {
    const dialogRef = this.dialog.open(DepartmentsHelperComponent, {
      width: '600px'
    });
  };

  //Submit form to back end
  onSubmit(form) {
    if (form.valid) {
      this.uploadService.uploadData(form.value).subscribe((response: any) => {
        if (response.error == 0) {
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
}
