<div class="additional-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Employee Concentration</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="employeeConcentrationForm" (ngSubmit)="submitForm(employeeConcentrationForm)">
      <div class="row">
        <div class="col-md-4">
          <!--SurveyDate-->
          <mat-label>Survey Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="SurveyDate" [matDatepicker]="SurveyDate">
            <mat-datepicker-toggle matSuffix [for]="SurveyDate"></mat-datepicker-toggle>
            <mat-datepicker #SurveyDate></mat-datepicker>
            <mat-error *ngIf="employeeConcentrationForm.controls['SurveyDate'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--RiskManager-->
          <mat-label>Risk Manager <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="RiskManager">
              <mat-option *ngFor="let manager of riskManagers" [value]="manager.firstName + ' ' + manager.lastName">{{manager.firstName}} {{manager.lastName}}</mat-option>
            </mat-select>
            <mat-error *ngIf="employeeConcentrationForm.controls['RiskManager'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--FullTimeEmployees-->
          <mat-label>Full Time Employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="FullTimeEmployees" placeholder="" />
            <mat-error *ngIf="employeeConcentrationForm.controls['FullTimeEmployees'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--PartTimeEmployees-->
          <mat-label>Part Time Employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="PartTimeEmployees" placeholder="" />
            <mat-error *ngIf="employeeConcentrationForm.controls['PartTimeEmployees'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--SeasonalEmployees-->
          <mat-label>Seasonal Employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="SeasonalEmployees" placeholder="" />
            <mat-error *ngIf="employeeConcentrationForm.controls['SeasonalEmployees'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h6> FOR ALL LOCATIONS WHERE TEN (10) OR MORE EMPLOYEES WORK</h6>
        </div>
      </div>
      <div class="row" *ngIf="dataSource">
        <div class="col-md-12">
          <table class="datatable" mat-table [dataSource]="dataSource" matSort matSortActive="propLocation" matSortDirection="asc" [@.disabled]="true">
            <!-- propLocation -->
            <ng-container matColumnDef="propLocation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Prop Location </th>
              <td mat-cell *matCellDef="let element"> {{element.propLocation}} </td>
            </ng-container>
            <!-- hoursOfOperation -->
            <ng-container matColumnDef="hoursOfOperation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Hours Of Operation </th>
              <td mat-cell *matCellDef="let element"> {{element.hoursOfOperation}} </td>
            </ng-container>
            <!-- floorsOccupied -->
            <ng-container matColumnDef="floorsOccupied">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Floors Occupied </th>
              <td mat-cell *matCellDef="let element"> {{element.floorsOccupied}} </td>
            </ng-container>
            <!-- numberOfStories -->
            <ng-container matColumnDef="numberOfStories">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Number Of Stories </th>
              <td mat-cell *matCellDef="let element"> {{element.numberOfStories}} </td>
            </ng-container>
            <!-- yearBuilt -->
            <ng-container matColumnDef="yearBuilt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Year Built </th>
              <td mat-cell *matCellDef="let element"> {{element.yearBuilt}} </td>
            </ng-container>
            <!-- Action -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openUpdateDialog(element)">mode_edit</mat-icon>
                <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteDialog(element)">delete</mat-icon>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <button mat-raised-button type="button" color="primary" class="my-5" (click)="openNewDialog()">Add New Employee Location</button>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h6>Loss Control and Safety</h6>
        </div>
      </div>
      <div class="row">
        <!--RiskManagerAssign-->
        <div class="col-md-6">
          <mat-checkbox formControlName="RiskManagerAssign">Risk Manager</mat-checkbox>
        </div>
        <!--RiskManagerFT-->
        <div class="col-md-6">
          <mat-checkbox formControlName="RiskManagerFT">Full Time?</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <!--MailGuide-->
        <div class="col-md-6">
          <mat-checkbox formControlName="MailGuide">Does the applicant have guidelines for handling suspicious mail and packages?</mat-checkbox>
        </div>
        <!--EvacDrills-->
        <div class="col-md-6">
          <mat-checkbox formControlName="EvacDrills">Does the applicant conduct periodic fire and emergency evacuation drills?</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <!--RollCall-->
        <div class="col-md-6">
          <mat-checkbox formControlName="RollCall">If yes, does the applicant have procedures in place to account for all employees?</mat-checkbox>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h6>Miscellaneous</h6>
        </div>
      </div>
      <div class="row">
        <!--BldgUpdate-->
        <div class="col-md-6">
          <mat-checkbox formControlName="BldgUpdate">Has the building been updated (example: electrical, sprinkler system)? If yes, when?</mat-checkbox>
        </div>
        <div class="col-md-6">
          <!--UpdateDate-->
          <mat-label>Update Date</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="UpdateDate" [matDatepicker]="UpdateDate">
            <mat-datepicker-toggle matSuffix [for]="UpdateDate"></mat-datepicker-toggle>
            <mat-datepicker #UpdateDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <!--RetroFit-->
        <div class="col-md-6">
          <mat-checkbox formControlName="RetroFit">Has the building been retro-fitted (earthquake)? If yes, when?</mat-checkbox>
        </div>
        <div class="col-md-6">
          <mat-label>Retro-fitted Date</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RetroFitDate" [matDatepicker]="RetroFitDate">
            <mat-datepicker-toggle matSuffix [for]="RetroFitDate"></mat-datepicker-toggle>
            <mat-datepicker #RetroFitDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <!--Fault-->
        <div class="col-md-6">
          <mat-checkbox formControlName="Fault">Not in Earthquake Fault</mat-checkbox>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-3">
          <h6>Entry information</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate -->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is requried</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="employeeConcentrationForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
