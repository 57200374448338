<div class="users-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Registered Users</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" (click)="openRegisterNewAccountDialog()">Register New User</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table mat-table class="datatable" [dataSource]="dataSource" matSort matSortActive="lastName" matSortDirection="asc" [@.disabled]="true">
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>
          <ng-container matColumnDef="emailConfirmed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Confirmed</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon class="confirmed" *ngIf="element.emailConfirmed">check_circle_outline</mat-icon>
              <mat-icon class="not-confirmed" *ngIf="!element.emailConfirmed">do_not_disturb_alt</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="municipalities">
            <th mat-header-cell *matHeaderCellDef>Municipalities</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" [disabled]="element.municipalities.length == 0">
                <mat-icon>expand_more</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let municipality of element.municipalities">
                  <span>{{municipality.fullName}}</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef> Role</th>
            <td mat-cell *matCellDef="let element"> {{element.role}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <div style="white-space: nowrap;">
                <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openEditAccountDialog(element)">mode_edit</mat-icon>
                <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteAccountDialog(element)">delete</mat-icon>
                <mat-icon mat-raised-button *ngIf="!element.emailConfirmed" class="ml-1" type="button" color="primary" (click)="resendActivationEmail(element.email)">forward_to_inbox</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
