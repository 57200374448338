//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

//Import Services
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.scss']
})
export class AdditionalComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.additionalForm = new FormGroup({
      NonCompliance: new FormControl(false),
      EmploymentPractices: new FormControl(false),
      HumanResources: new FormControl(false),
      JobDescriptions: new FormControl(false),
      StandardizedApplication: new FormControl(false),
      LegalCounselReview: new FormControl(false),
      EmploymentTesting: new FormControl(false),
      DriverTesting: new FormControl(false),
      MVRObtained: new FormControl(false),
      MVRRequired: new FormControl(false),
      WrittenConsent: new FormControl(false),
      DrivingStandards: new FormControl(false),
      ShowEvidence: new FormControl(false),
      RegulationsComplied: new FormControl(false),
      CDLPolicy: new FormControl(false),
      PostAccidentGuidelines: new FormControl(false),
      PostMVATesting: new FormControl(false),
      GeneralTesting: new FormControl(false),
      PsychologicalTests: new FormControl(false),
      ResultsReviewed: new FormControl(false),
      PsychoInterview: new FormControl(false),
      EvalPerformance: new FormControl(false),
      AdviceRequired: new FormControl(false),
      ProhibitWrongdoing: new FormControl(false),
      EmploymentHandbook: new FormControl(false),
      EmploymentHandbookUpdated: new FormControl(false),
      LaborRelationsReview: new FormControl(false),
      SignForHandbook: new FormControl(false),
      PracticeManual: new FormControl(false),
      TerminationProcedures: new FormControl(false),
      GrievanceProcedures: new FormControl(false),
      EmploymentTraining: new FormControl(false),
      DiscriminationTraining: new FormControl(false),
      EmployeeHIT: new FormControl(false),
      EmployeeCDG: new FormControl(false),
      PerformanceReviews: new FormControl(false),
      DisciplinaryProgram: new FormControl(false),
      TerminationHearings: new FormControl(false),
      TimeoffPolicies: new FormControl(false),
      HarassmentSO: new FormControl(false),
      TrainingMandatory: new FormControl(false),
      ComplaintStructure: new FormControl(false),
      PublicOfficials: new FormControl(false),
      OfficialsActivities: new FormControl(false),
      OfficialsTraining: new FormControl(false),
      LandStatutes: new FormControl(false),
      MasterPlan: new FormControl(false),
      LastRevised: new FormControl(false),
      LawEnforcement: new FormControl(false),
      HiringScreening: new FormControl(false),
      EducationRequirements: new FormControl(false),
      OperationsManual: new FormControl(false),
      TrainingManualAcademy: new FormControl(false),
      JailOperations: new FormControl(false),
      DeptAccreditation: new FormControl(false),
      PoliceChiefs: new FormControl(false),
      NjLeap: new FormControl(false),
      MunicipalAttorney: new FormControl(false),
      EntityAttorney: new FormControl(false),
      EntityAttorneyConsulted: new FormControl(false),
      EntityAttorneyAttend: new FormControl(false),
      GrandOfficials: new FormControl(false),
      ClaimImproper: new FormControl(false),
      ClaimViolation: new FormControl(false),
      ClaimZoning: new FormControl(false),
      ClaimWrongful: new FormControl(false),
      RequiredComply: new FormControl(false),
      ContinuousClaims: new FormControl(false),
      ExposureAdditionalID: new FormControl(0),
      RenewalApplicationID: new FormControl(0),
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]),
      NonComplianceDescription: new FormControl('', Validators.maxLength(200)),
      EmploymentPracticesDescription: new FormControl('', Validators.maxLength(200)),
      HumanResourcesDescription: new FormControl('', Validators.maxLength(200)),
      JobDescriptionsDescription: new FormControl('', Validators.maxLength(200)),
      StandardizedApplicationDescription: new FormControl('', Validators.maxLength(200)),
      LegalCounselReviewDescription: new FormControl('', Validators.maxLength(200)),
      EmploymentTestingDescription: new FormControl('', Validators.maxLength(200)),
      DriverTestingDescription: new FormControl('', Validators.maxLength(200)),
      MVRObtainedDescription: new FormControl('', Validators.maxLength(200)),
      MVRRequiredDescription: new FormControl('', Validators.maxLength(200)),
      WrittenConsentDescription: new FormControl('', Validators.maxLength(200)),
      DrivingStandardsDescription: new FormControl('', Validators.maxLength(200)),
      ShowEvidenceDescription: new FormControl('', Validators.maxLength(200)),
      RegulationsCompliedDescription: new FormControl('', Validators.maxLength(200)),
      CDLPolicyDescription: new FormControl('', Validators.maxLength(200)),
      PostAccidentGuidelinesDescription: new FormControl('', Validators.maxLength(200)),
      PostMVATestingDescription: new FormControl('', Validators.maxLength(200)),
      GeneralTestingDescription: new FormControl('', Validators.maxLength(200)),
      PsychologicalTestsDescription: new FormControl('', Validators.maxLength(200)),
      ResultsReviewedDescription: new FormControl('', Validators.maxLength(200)),
      PsychoInterviewDescription: new FormControl('', Validators.maxLength(200)),
      EvalPerformanceDescription: new FormControl('', Validators.maxLength(200)),
      AdviceRequiredDescription: new FormControl('', Validators.maxLength(200)),
      ProhibitWrongdoingDescription: new FormControl('', Validators.maxLength(200)),
      EmploymentHandbookDescription: new FormControl('', Validators.maxLength(200)),
      EmploymentHandbookUpdatedDescription: new FormControl('', Validators.maxLength(200)),
      LaborRelationsReviewDescription: new FormControl('', Validators.maxLength(200)),
      SignForHandbookDescription: new FormControl('', Validators.maxLength(200)),
      PracticeManualDescription: new FormControl('', Validators.maxLength(200)),
      TerminationProceduresDescription: new FormControl('', Validators.maxLength(200)),
      GrievanceProceduresDescription: new FormControl('', Validators.maxLength(200)),
      EmploymentTrainingDescription: new FormControl('', Validators.maxLength(200)),
      DiscriminationTrainingDescription: new FormControl('', Validators.maxLength(200)),
      EmployeeHITDescription: new FormControl('', Validators.maxLength(200)),
      EmployeeCDGDescription: new FormControl('', Validators.maxLength(200)),
      PerformanceReviewsDescription: new FormControl('', Validators.maxLength(200)),
      DisciplinaryProgramDescription: new FormControl('', Validators.maxLength(200)),
      TerminationHearingsDescription: new FormControl('', Validators.maxLength(200)),
      TimeoffPoliciesDescription: new FormControl('', Validators.maxLength(200)),
      HarassmentSODescription: new FormControl('', Validators.maxLength(200)),
      TrainingMandatoryDescription: new FormControl('', Validators.maxLength(200)),
      ComplaintStructureDescription: new FormControl('', Validators.maxLength(200)),
      PublicOfficialsDescription: new FormControl('', Validators.maxLength(200)),
      OfficialsActivitiesDescription: new FormControl('', Validators.maxLength(200)),
      OfficialsTrainingDescription: new FormControl('', Validators.maxLength(200)),
      LandStatutesDescription: new FormControl('', Validators.maxLength(200)),
      MasterPlanDescription: new FormControl('', Validators.maxLength(200)),
      LastRevisedDescription: new FormControl('', Validators.maxLength(200)),
      LawEnforcementDescription: new FormControl('', Validators.maxLength(200)),
      HiringScreeningDescription: new FormControl('', Validators.maxLength(200)),
      EducationRequirementsDescription: new FormControl('', Validators.maxLength(200)),
      OperationsManualDescription: new FormControl('', Validators.maxLength(200)),
      TrainingManualAcademyDescription: new FormControl('', Validators.maxLength(200)),
      JailOperationsDescription: new FormControl('', Validators.maxLength(200)),
      DeptAccreditationDescription: new FormControl('', Validators.maxLength(200)),
      PoliceChiefsDescription: new FormControl('', Validators.maxLength(200)),
      NjLeapDescription: new FormControl('', Validators.maxLength(200)),
      MunicipalAttorneyDescription: new FormControl('', Validators.maxLength(200)),
      EntityAttorneyDescription: new FormControl('', Validators.maxLength(200)),
      EntityAttorneyConsultedDescription: new FormControl('', Validators.maxLength(200)),
      EntityAttorneyAttendDescription: new FormControl('', Validators.maxLength(200)),
      GrandOfficialsDescription: new FormControl('', Validators.maxLength(200)),
      ClaimImproperDescription: new FormControl('', Validators.maxLength(200)),
      ClaimViolationDescription: new FormControl('', Validators.maxLength(200)),
      ClaimZoningDescription: new FormControl('', Validators.maxLength(200)),
      ClaimWrongfulDescription: new FormControl('', Validators.maxLength(200)),
      RequiredComplyDescription: new FormControl('', Validators.maxLength(200)),
      ContinuousClaimsDescription: new FormControl('', Validators.maxLength(200)),
      EffectiveDate: new FormControl('', Validators.required)
    });

    //NonCompliance
    this.additionalForm.controls['NonCompliance'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('NonComplianceDescription').enable() : this.additionalForm.get('NonComplianceDescription').disable();
    });

    //EmploymentPractices
    this.additionalForm.controls['EmploymentPractices'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EmploymentPracticesDescription').enable() : this.additionalForm.get('EmploymentPracticesDescription').disable();
    });

    //HumanResources
    this.additionalForm.controls['HumanResources'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('HumanResourcesDescription').enable() : this.additionalForm.get('HumanResourcesDescription').disable();
    });

    //JobDescriptions
    this.additionalForm.controls['JobDescriptions'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('JobDescriptionsDescription').enable() : this.additionalForm.get('JobDescriptionsDescription').disable();
    });

    //StandardizedApplication
    this.additionalForm.controls['StandardizedApplication'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('StandardizedApplicationDescription').enable() : this.additionalForm.get('StandardizedApplicationDescription').disable();
    });

    //LegalCounselReview
    this.additionalForm.controls['LegalCounselReview'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('LegalCounselReviewDescription').enable() : this.additionalForm.get('LegalCounselReviewDescription').disable();
    });

    //EmploymentTesting
    this.additionalForm.controls['EmploymentTesting'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EmploymentTestingDescription').enable() : this.additionalForm.get('EmploymentTestingDescription').disable();
    });

    //DriverTesting
    this.additionalForm.controls['DriverTesting'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('DriverTestingDescription').enable() : this.additionalForm.get('DriverTestingDescription').disable();
    });

    //DriverTesting
    this.additionalForm.controls['DriverTesting'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('DriverTestingDescription').enable() : this.additionalForm.get('DriverTestingDescription').disable();
    });

    //MVRObtained
    this.additionalForm.controls['MVRObtained'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('MVRObtainedDescription').enable() : this.additionalForm.get('MVRObtainedDescription').disable();
    });

    //MVRRequired
    this.additionalForm.controls['MVRRequired'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('MVRRequiredDescription').enable() : this.additionalForm.get('MVRRequiredDescription').disable();
    });

    //WrittenConsent
    this.additionalForm.controls['WrittenConsent'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('WrittenConsentDescription').enable() : this.additionalForm.get('WrittenConsentDescription').disable();
    });

    //DrivingStandards
    this.additionalForm.controls['DrivingStandards'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('DrivingStandardsDescription').enable() : this.additionalForm.get('DrivingStandardsDescription').disable();
    });

    //ShowEvidence
    this.additionalForm.controls['ShowEvidence'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ShowEvidenceDescription').enable() : this.additionalForm.get('ShowEvidenceDescription').disable();
    });

    //RegulationsComplied
    this.additionalForm.controls['RegulationsComplied'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('RegulationsCompliedDescription').enable() : this.additionalForm.get('RegulationsCompliedDescription').disable();
    });

    //CDLPolicy
    this.additionalForm.controls['CDLPolicy'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('CDLPolicyDescription').enable() : this.additionalForm.get('CDLPolicyDescription').disable();
    });

    //CDLPolicy
    this.additionalForm.controls['CDLPolicy'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('CDLPolicyDescription').enable() : this.additionalForm.get('CDLPolicyDescription').disable();
    });

    //PostAccidentGuidelines
    this.additionalForm.controls['PostAccidentGuidelines'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PostAccidentGuidelinesDescription').enable() : this.additionalForm.get('PostAccidentGuidelinesDescription').disable();
    });

    //PostMVATesting
    this.additionalForm.controls['PostMVATesting'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PostMVATestingDescription').enable() : this.additionalForm.get('PostMVATestingDescription').disable();
    });

    //GeneralTesting
    this.additionalForm.controls['GeneralTesting'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('GeneralTestingDescription').enable() : this.additionalForm.get('GeneralTestingDescription').disable();
    });

    //PsychologicalTests
    this.additionalForm.controls['PsychologicalTests'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PsychologicalTestsDescription').enable() : this.additionalForm.get('PsychologicalTestsDescription').disable();
    });

    //ResultsReviewed
    this.additionalForm.controls['ResultsReviewed'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ResultsReviewedDescription').enable() : this.additionalForm.get('ResultsReviewedDescription').disable();
    });

    //PsychoInterview
    this.additionalForm.controls['PsychoInterview'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PsychoInterviewDescription').enable() : this.additionalForm.get('PsychoInterviewDescription').disable();
    });

    //EvalPerformance
    this.additionalForm.controls['EvalPerformance'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EvalPerformanceDescription').enable() : this.additionalForm.get('EvalPerformanceDescription').disable();
    });

    //AdviceRequired
    this.additionalForm.controls['AdviceRequired'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('AdviceRequiredDescription').enable() : this.additionalForm.get('AdviceRequiredDescription').disable();
    });

    //AdviceRequired
    this.additionalForm.controls['ProhibitWrongdoing'].valueChanges.subscribe(value => {
      ;
      value ? this.additionalForm.get('ProhibitWrongdoingDescription').enable() : this.additionalForm.get('ProhibitWrongdoingDescription').disable();
    });

    //EmploymentHandbook
    this.additionalForm.controls['EmploymentHandbook'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EmploymentHandbookDescription').enable() : this.additionalForm.get('EmploymentHandbookDescription').disable();
    });

    //EmploymentHandbookUpdated
    this.additionalForm.controls['EmploymentHandbookUpdated'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EmploymentHandbookUpdatedDescription').enable() : this.additionalForm.get('EmploymentHandbookUpdatedDescription').disable();
    });

    //LaborRelationsReview
    this.additionalForm.controls['LaborRelationsReview'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('LaborRelationsReviewDescription').enable() : this.additionalForm.get('LaborRelationsReviewDescription').disable();
    });

    //SignForHandbook
    this.additionalForm.controls['SignForHandbook'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('SignForHandbookDescription').enable() : this.additionalForm.get('SignForHandbookDescription').disable();
    });

    //PracticeManual
    this.additionalForm.controls['PracticeManual'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PracticeManualDescription').enable() : this.additionalForm.get('PracticeManualDescription').disable();
    });

    //TerminationProcedures
    this.additionalForm.controls['TerminationProcedures'].valueChanges.subscribe(value => {
      ;
      value ? this.additionalForm.get('TerminationProceduresDescription').enable() : this.additionalForm.get('TerminationProceduresDescription').disable();
    });

    //GrievanceProcedures
    this.additionalForm.controls['GrievanceProcedures'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('GrievanceProceduresDescription').enable() : this.additionalForm.get('GrievanceProceduresDescription').disable();
    });

    //EmploymentTraining
    this.additionalForm.controls['EmploymentTraining'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EmploymentTrainingDescription').enable() : this.additionalForm.get('EmploymentTrainingDescription').disable();
    });

    //DiscriminationTraining
    this.additionalForm.controls['DiscriminationTraining'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('DiscriminationTrainingDescription').enable() : this.additionalForm.get('DiscriminationTrainingDescription').disable();
    });

    //EmployeeHIT
    this.additionalForm.controls['EmployeeHIT'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EmployeeHITDescription').enable() : this.additionalForm.get('EmployeeHITDescription').disable();
    });

    //EmployeeCDG
    this.additionalForm.controls['EmployeeCDG'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EmployeeCDGDescription').enable() : this.additionalForm.get('EmployeeCDGDescription').disable();
    });

    //PerformanceReviews
    this.additionalForm.controls['PerformanceReviews'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PerformanceReviewsDescription').enable() : this.additionalForm.get('PerformanceReviewsDescription').disable();
    });

    //PerformanceReviews
    this.additionalForm.controls['DisciplinaryProgram'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('DisciplinaryProgramDescription').enable() : this.additionalForm.get('DisciplinaryProgramDescription').disable();
    });

    //TerminationHearings
    this.additionalForm.controls['TerminationHearings'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('TerminationHearingsDescription').enable() : this.additionalForm.get('TerminationHearingsDescription').disable();
    });

    //TimeoffPolicies
    this.additionalForm.controls['TimeoffPolicies'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('TimeoffPoliciesDescription').enable() : this.additionalForm.get('TimeoffPoliciesDescription').disable();
    });

    //HarassmentSO
    this.additionalForm.controls['HarassmentSO'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('HarassmentSODescription').enable() : this.additionalForm.get('HarassmentSODescription').disable();
    });

    //TrainingMandatory
    this.additionalForm.controls['TrainingMandatory'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('TrainingMandatoryDescription').enable() : this.additionalForm.get('TrainingMandatoryDescription').disable();
    });

    //ComplaintStructure
    this.additionalForm.controls['ComplaintStructure'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ComplaintStructureDescription').enable() : this.additionalForm.get('ComplaintStructureDescription').disable();
    });

    //PublicOfficials
    this.additionalForm.controls['PublicOfficials'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PublicOfficialsDescription').enable() : this.additionalForm.get('PublicOfficialsDescription').disable();
    });

    //OfficialsActivities
    this.additionalForm.controls['OfficialsActivities'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('OfficialsActivitiesDescription').enable() : this.additionalForm.get('OfficialsActivitiesDescription').disable();
    });

    //OfficialsTraining
    this.additionalForm.controls['OfficialsTraining'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('OfficialsTrainingDescription').enable() : this.additionalForm.get('OfficialsTrainingDescription').disable();
    });

    //LandStatutes
    this.additionalForm.controls['LandStatutes'].valueChanges.subscribe(value => {
      ;
      value ? this.additionalForm.get('LandStatutesDescription').enable() : this.additionalForm.get('LandStatutesDescription').disable();
    });

    //MasterPlan
    this.additionalForm.controls['MasterPlan'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('MasterPlanDescription').enable() : this.additionalForm.get('MasterPlanDescription').disable();
    });

    //LastRevised
    this.additionalForm.controls['LastRevised'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('LastRevisedDescription').enable() : this.additionalForm.get('LastRevisedDescription').disable();
    });

    //LawEnforcement
    this.additionalForm.controls['LawEnforcement'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('LawEnforcementDescription').enable() : this.additionalForm.get('LawEnforcementDescription').disable();
    });

    //HiringScreening
    this.additionalForm.controls['HiringScreening'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('HiringScreeningDescription').enable() : this.additionalForm.get('HiringScreeningDescription').disable();
    });

    //EducationRequirements
    this.additionalForm.controls['EducationRequirements'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EducationRequirementsDescription').enable() : this.additionalForm.get('EducationRequirementsDescription').disable();
    });

    //OperationsManual
    this.additionalForm.controls['OperationsManual'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('OperationsManualDescription').enable() : this.additionalForm.get('OperationsManualDescription').disable();
    });

    //TrainingManualAcademy
    this.additionalForm.controls['TrainingManualAcademy'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('TrainingManualAcademyDescription').enable() : this.additionalForm.get('TrainingManualAcademyDescription').disable();
    });

    //JailOperations
    this.additionalForm.controls['JailOperations'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('JailOperationsDescription').enable() : this.additionalForm.get('JailOperationsDescription').disable();
    });

    //DeptAccreditation
    this.additionalForm.controls['DeptAccreditation'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('DeptAccreditationDescription').enable() : this.additionalForm.get('DeptAccreditationDescription').disable();
    });

    //PoliceChiefs
    this.additionalForm.controls['PoliceChiefs'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('PoliceChiefsDescription').enable() : this.additionalForm.get('PoliceChiefsDescription').disable();
    });

    //NjLeap
    this.additionalForm.controls['NjLeap'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('NjLeapDescription').enable() : this.additionalForm.get('NjLeapDescription').disable();
    });

    //MunicipalAttorney
    this.additionalForm.controls['MunicipalAttorney'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('MunicipalAttorneyDescription').enable() : this.additionalForm.get('MunicipalAttorneyDescription').disable();
    });

    //EntityAttorney
    this.additionalForm.controls['EntityAttorney'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EntityAttorneyDescription').enable() : this.additionalForm.get('EntityAttorneyDescription').disable();
    });

    //EntityAttorney
    this.additionalForm.controls['EntityAttorney'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EntityAttorneyDescription').enable() : this.additionalForm.get('EntityAttorneyDescription').disable();
    });

    //EntityAttorneyConsulted
    this.additionalForm.controls['EntityAttorneyConsulted'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EntityAttorneyConsultedDescription').enable() : this.additionalForm.get('EntityAttorneyConsultedDescription').disable();
    });

    //EntityAttorneyAttend
    this.additionalForm.controls['EntityAttorneyAttend'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('EntityAttorneyAttendDescription').enable() : this.additionalForm.get('EntityAttorneyAttendDescription').disable();
    });

    //GrandOfficials
    this.additionalForm.controls['GrandOfficials'].valueChanges.subscribe(value => {
      ;
      value ? this.additionalForm.get('GrandOfficialsDescription').enable() : this.additionalForm.get('GrandOfficialsDescription').disable();
    });

    //ClaimImproper
    this.additionalForm.controls['ClaimImproper'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ClaimImproperDescription').enable() : this.additionalForm.get('ClaimImproperDescription').disable();
    });

    //ClaimViolation
    this.additionalForm.controls['ClaimViolation'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ClaimViolationDescription').enable() : this.additionalForm.get('ClaimViolationDescription').disable();
    });

    //ClaimZoning
    this.additionalForm.controls['ClaimZoning'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ClaimZoningDescription').enable() : this.additionalForm.get('ClaimZoningDescription').disable();
    });

    //ClaimWrongful
    this.additionalForm.controls['ClaimWrongful'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ClaimWrongfulDescription').enable() : this.additionalForm.get('ClaimWrongfulDescription').disable();
    });

    //RequiredComply
    this.additionalForm.controls['RequiredComply'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('RequiredComplyDescription').enable() : this.additionalForm.get('RequiredComplyDescription').disable();
    });

    //ContinuousClaims
    this.additionalForm.controls['ContinuousClaims'].valueChanges.subscribe(value => {
      value ? this.additionalForm.get('ContinuousClaimsDescription').enable() : this.additionalForm.get('ContinuousClaimsDescription').disable();
    });
  };

  public activeMunicipality;
  public additionalForm: FormGroup;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.additionalForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getAdditional(response);
      }
    });
  };

  public getAdditional(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetExposureAdditional').subscribe((response: any) => {
      if (!response) {
        this.additionalForm.patchValue({
          'NonCompliance': false,
          'EmploymentPractices': false,
          'HumanResources': false,
          'JobDescriptions': false,
          'StandardizedApplication': false,
          'LegalCounselReview': false,
          'EmploymentTesting': false,
          'DriverTesting': false,
          'MVRObtained': false,
          'MVRRequired': false,
          'WrittenConsent': false,
          'DrivingStandards': false,
          'ShowEvidence': false,
          'RegulationsComplied': false,
          'CDLPolicy': false,
          'PostAccidentGuidelines': false,
          'PostMVATesting': false,
          'GeneralTesting': false,
          'PsychologicalTests': false,
          'ResultsReviewed': false,
          'PsychoInterview': false,
          'EvalPerformance': false,
          'AdviceRequired': false,
          'ProhibitWrongdoing': false,
          'EmploymentHandbook': false,
          'EmploymentHandbookUpdated': false,
          'LaborRelationsReview': false,
          'SignForHandbook': false,
          'PracticeManual': false,
          'TerminationProcedures': false,
          'GrievanceProcedures': false,
          'EmploymentTraining': false,
          'DiscriminationTraining': false,
          'EmployeeHIT': false,
          'EmployeeCDG': false,
          'PerformanceReviews': false,
          'DisciplinaryProgram': false,
          'TerminationHearings': false,
          'TimeoffPolicies': false,
          'HarassmentSO': false,
          'TrainingMandatory': false,
          'ComplaintStructure': false,
          'PublicOfficials': false,
          'OfficialsActivities': false,
          'OfficialsTraining': false,
          'LandStatutes': false,
          'MasterPlan': false,
          'LastRevised': false,
          'LawEnforcement': false,
          'HiringScreening': false,
          'EducationRequirements': false,
          'OperationsManual': false,
          'TrainingManualAcademy': false,
          'JailOperations': false,
          'DeptAccreditation': false,
          'PoliceChiefs': false,
          'NjLeap': false,
          'MunicipalAttorney': false,
          'EntityAttorney': false,
          'EntityAttorneyConsulted': false,
          'EntityAttorneyAttend': false,
          'GrandOfficials': false,
          'ClaimImproper': false,
          'ClaimViolation': false,
          'ClaimZoning': false,
          'ClaimWrongful': false,
          'RequiredComply': false,
          'ContinuousClaims': false,
          'ExposureAdditionalID': 0,
          'RenewalApplicationID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'NonComplianceDescription': '',
          'EmploymentPracticesDescription': '',
          'HumanResourcesDescription': '',
          'JobDescriptionsDescription': '',
          'StandardizedApplicationDescription': '',
          'LegalCounselReviewDescription': '',
          'EmploymentTestingDescription': '',
          'DriverTestingDescription': '',
          'MVRObtainedDescription': '',
          'MVRRequiredDescription': '',
          'WrittenConsentDescription': '',
          'DrivingStandardsDescription': '',
          'ShowEvidenceDescription': '',
          'RegulationsCompliedDescription': '',
          'CDLPolicyDescription': '',
          'PostAccidentGuidelinesDescription': '',
          'PostMVATestingDescription': '',
          'GeneralTestingDescription': '',
          'PsychologicalTestsDescription': '',
          'ResultsReviewedDescription': '',
          'PsychoInterviewDescription': '',
          'EvalPerformanceDescription': '',
          'AdviceRequiredDescription': '',
          'ProhibitWrongdoingDescription': '',
          'EmploymentHandbookDescription': '',
          'EmploymentHandbookUpdatedDescription': '',
          'LaborRelationsReviewDescription': '',
          'SignForHandbookDescription': '',
          'PracticeManualDescription': '',
          'TerminationProceduresDescription': '',
          'GrievanceProceduresDescription': '',
          'EmploymentTrainingDescription': '',
          'DiscriminationTrainingDescription': '',
          'EmployeeHITDescription': '',
          'EmployeeCDGDescription': '',
          'PerformanceReviewsDescription': '',
          'DisciplinaryProgramDescription': '',
          'TerminationHearingsDescription': '',
          'TimeoffPoliciesDescription': '',
          'HarassmentSODescription': '',
          'TrainingMandatoryDescription': '',
          'ComplaintStructureDescription': '',
          'PublicOfficialsDescription': '',
          'OfficialsActivitiesDescription': '',
          'OfficialsTrainingDescription': '',
          'LandStatutesDescription': '',
          'MasterPlanDescription': '',
          'LastRevisedDescription': '',
          'LawEnforcementDescription': '',
          'HiringScreeningDescription': '',
          'EducationRequirementsDescription': '',
          'OperationsManualDescription': '',
          'TrainingManualAcademyDescription': '',
          'JailOperationsDescription': '',
          'DeptAccreditationDescription': '',
          'PoliceChiefsDescription': '',
          'NjLeapDescription': '',
          'MunicipalAttorneyDescription': '',
          'EntityAttorneyDescription': '',
          'EntityAttorneyConsultedDescription': '',
          'EntityAttorneyAttendDescription': '',
          'GrandOfficialsDescription': '',
          'ClaimImproperDescription': '',
          'ClaimViolationDescription': '',
          'ClaimZoningDescription': '',
          'ClaimWrongfulDescription': '',
          'RequiredComplyDescription': '',
          'ContinuousClaimsDescription': '',
          'EffectiveDate': ''
        })
      } else {
        this.additionalForm.patchValue({
          'ExposureAdditionalID': response.exposureAdditionalID,
          'RenewalApplicationID': response.renewalApplicationID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'NonCompliance': response.nonCompliance,
          'EmploymentPractices': response.employmentPractices,
          'HumanResources': response.humanResources,
          'JobDescriptions': response.jobDescriptions,
          'StandardizedApplication': response.standardizedApplication,
          'LegalCounselReview': response.legalCounselReview,
          'EmploymentTesting': response.employmentTesting,
          'DriverTesting': response.driverTesting,
          'MVRObtained': response.mvrObtained,
          'MVRRequired': response.mvrRequired,
          'WrittenConsent': response.writtenConsent,
          'DrivingStandards': response.drivingStandards,
          'ShowEvidence': response.showEvidence,
          'RegulationsComplied': response.regulationsComplied,
          'CDLPolicy': response.cdlPolicy,
          'PostAccidentGuidelines': response.postAccidentGuidelines,
          'PostMVATesting': response.postMVATesting,
          'GeneralTesting': response.generalTesting,
          'PsychologicalTests': response.psychologicalTests,
          'ResultsReviewed': response.resultsReviewed,
          'PsychoInterview': response.psychoInterview,
          'EvalPerformance': response.evalPerformance,
          'AdviceRequired': response.adviceRequired,
          'ProhibitWrongdoing': response.prohibitWrongdoing,
          'EmploymentHandbook': response.employmentHandbook,
          'EmploymentHandbookUpdated': response.employmentHandbookUpdated,
          'LaborRelationsReview': response.laborRelationsReview,
          'SignForHandbook': response.signForHandbook,
          'PracticeManual': response.practiceManual,
          'TerminationProcedures': response.terminationProcedures,
          'GrievanceProcedures': response.grievanceProcedures,
          'EmploymentTraining': response.employmentTraining,
          'DiscriminationTraining': response.discriminationTraining,
          'EmployeeHIT': response.employeeHIT,
          'EmployeeCDG': response.employeeCDG,
          'PerformanceReviews': response.performanceReviews,
          'DisciplinaryProgram': response.disciplinaryProgram,
          'TerminationHearings': response.terminationHearings,
          'TimeoffPolicies': response.timeoffPolicies,
          'HarassmentSO': response.harassmentSO,
          'TrainingMandatory': response.trainingMandatory,
          'ComplaintStructure': response.complaintStructure,
          'PublicOfficials': response.publicOfficials,
          'OfficialsActivities': response.officialsActivities,
          'OfficialsTraining': response.officialsTraining,
          'LandStatutes': response.landStatutes,
          'MasterPlan': response.masterPlan,
          'LastRevised': response.lastRevised,
          'LawEnforcement': response.lawEnforcement,
          'HiringScreening': response.hiringScreening,
          'EducationRequirements': response.educationRequirements,
          'OperationsManual': response.operationsManual,
          'TrainingManualAcademy': response.trainingManualAcademy,
          'JailOperations': response.jailOperations,
          'DeptAccreditation': response.deptAccreditation,
          'PoliceChiefs': response.policeChiefs,
          'NjLeap': response.njLeap,
          'MunicipalAttorney': response.municipalAttorney,
          'EntityAttorney': response.entityAttorney,
          'EntityAttorneyConsulted': response.entityAttorneyConsulted,
          'EntityAttorneyAttend': response.entityAttorneyAttend,
          'GrandOfficials': response.grandOfficials,
          'ClaimImproper': response.claimImproper,
          'ClaimViolation': response.claimViolation,
          'ClaimZoning': response.claimZoning,
          'ClaimWrongful': response.claimWrongful,
          'RequiredComply': response.requiredComply,
          'ContinuousClaims': response.continuousClaims,
          'NonComplianceDescription': response.nonComplianceDescription,
          'EmploymentPracticesDescription': response.employmentPracticesDescription,
          'HumanResourcesDescription': response.humanResourcesDescription,
          'JobDescriptionsDescription': response.jobDescriptionsDescription,
          'StandardizedApplicationDescription': response.standardizedApplicationDescription,
          'LegalCounselReviewDescription': response.legalCounselReviewDescription,
          'EmploymentTestingDescription': response.employmentTestingDescription,
          'DriverTestingDescription': response.driverTestingDescription,
          'MVRObtainedDescription': response.mvrObtainedDescription,
          'MVRRequiredDescription': response.mvrRequiredDescription,
          'WrittenConsentDescription': response.writtenConsentDescription,
          'DrivingStandardsDescription': response.drivingStandardsDescription,
          'ShowEvidenceDescription': response.showEvidenceDescription,
          'RegulationsCompliedDescription': response.regulationsCompliedDescription,
          'CDLPolicyDescription': response.cdlPolicyDescription,
          'PostAccidentGuidelinesDescription': response.postAccidentGuidelinesDescription,
          'PostMVATestingDescription': response.postMVATestingDescription,
          'GeneralTestingDescription': response.generalTestingDescription,
          'PsychologicalTestsDescription': response.psychologicalTestsDescription,
          'ResultsReviewedDescription': response.resultsReviewedDescription,
          'PsychoInterviewDescription': response.psychoInterviewDescription,
          'EvalPerformanceDescription': response.evalPerformanceDescription,
          'AdviceRequiredDescription': response.adviceRequiredDescription,
          'ProhibitWrongdoingDescription': response.prohibitWrongdoingDescription,
          'EmploymentHandbookDescription': response.employmentHandbookDescription,
          'EmploymentHandbookUpdatedDescription': response.employmentHandbookUpdatedDescription,
          'LaborRelationsReviewDescription': response.laborRelationsReviewDescription,
          'SignForHandbookDescription': response.signForHandbookDescription,
          'PracticeManualDescription': response.practiceManualDescription,
          'TerminationProceduresDescription': response.terminationProceduresDescription,
          'GrievanceProceduresDescription': response.grievanceProceduresDescription,
          'EmploymentTrainingDescription': response.employmentTrainingDescription,
          'DiscriminationTrainingDescription': response.discriminationTrainingDescription,
          'EmployeeHITDescription': response.employeeHITDescription,
          'EmployeeCDGDescription': response.employeeCDGDescription,
          'PerformanceReviewsDescription': response.performanceReviewsDescription,
          'DisciplinaryProgramDescription': response.disciplinaryProgramDescription,
          'TerminationHearingsDescription': response.terminationHearingsDescription,
          'TimeoffPoliciesDescription': response.timeoffPoliciesDescription,
          'HarassmentSODescription': response.harassmentSODescription,
          'TrainingMandatoryDescription': response.trainingMandatoryDescription,
          'ComplaintStructureDescription': response.complaintStructureDescription,
          'PublicOfficialsDescription': response.publicOfficialsDescription,
          'OfficialsActivitiesDescription': response.officialsActivitiesDescription,
          'OfficialsTrainingDescription': response.officialsTrainingDescription,
          'LandStatutesDescription': response.landStatutesDescription,
          'MasterPlanDescription': response.masterPlanDescription,
          'LastRevisedDescription': response.lastRevisedDescription,
          'LawEnforcementDescription': response.lawEnforcementDescription,
          'HiringScreeningDescription': response.hiringScreeningDescription,
          'EducationRequirementsDescription': response.educationRequirementsDescription,
          'OperationsManualDescription': response.operationsManualDescription,
          'TrainingManualAcademyDescription': response.trainingManualAcademyDescription,
          'JailOperationsDescription': response.jailOperationsDescription,
          'DeptAccreditationDescription': response.deptAccreditationDescription,
          'PoliceChiefsDescription': response.policeChiefsDescription,
          'NjLeapDescription': response.njLeapDescription,
          'MunicipalAttorneyDescription': response.municipalAttorneyDescription,
          'EntityAttorneyDescription': response.entityAttorneyDescription,
          'EntityAttorneyConsultedDescription': response.entityAttorneyConsultedDescription,
          'EntityAttorneyAttendDescription': response.entityAttorneyAttendDescription,
          'GrandOfficialsDescription': response.grandOfficialsDescription,
          'ClaimImproperDescription': response.claimImproperDescription,
          'ClaimViolationDescription': response.claimViolationDescription,
          'ClaimZoningDescription': response.claimZoningDescription,
          'ClaimWrongfulDescription': response.claimWrongfulDescription,
          'RequiredComplyDescription': response.requiredComplyDescription,
          'ContinuousClaimsDescription': response.continuousClaimsDescription,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.ExposureAdditionalID == 0 && form.value.RenewalApplicationID == 0) {
      this.exposuresService.add(form.value, 'AddExposureAdditional').subscribe((response: any) => {
        if (response.error == 0) {
          this.getAdditional(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateExposureAdditional').subscribe((response: any) => {
        if (response.error == 0) {
          this.getAdditional(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

};
