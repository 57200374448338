//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { PasswordValidators } from '../../shared/pipes/password-validators';

import { AccountService } from '../service/account.service';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  constructor(public accountService: AccountService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private notifier: NotifierService) {
    this.createPasswordForm = fb.group({
      UserId: new FormControl('', Validators.required), //STRING
      Password: ['', Validators.compose([Validators.required, PasswordValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }), PasswordValidators.patternValidator(/[a-z]/, { hasSmallCase: true }), PasswordValidators.patternValidator(/[!@#$%^&*]/, { hasSpecialCharacter: true }), Validators.minLength(6)])], //STRING
      ConfirmPassword: ['', Validators.compose([Validators.required])], //STRING
      Token: new FormControl('', Validators.required) //STRING
    },
      {
        validator: PasswordValidators.passwordMatchValidator
      })
  };

  public createPasswordForm: FormGroup;

  ngOnInit() {
    this.extractUrlParams();
  };

  //Retrive user information from url string & patch the form
  extractUrlParams() {
    this.createPasswordForm.controls.UserId.setValue(this.route.snapshot.queryParams.UserId)
    this.createPasswordForm.controls.Token.setValue(this.route.snapshot.queryParams.token);
  };

  //Submit create account password form
  submitCreatePasswordForm(form) {
    if (form.valid) {
      this.accountService.accountCreatePassword(form.value).pipe(first()).subscribe({
        next: (response: any) => {
          if (response.error === 0) {
            this.router.navigateByUrl('/login');
          } else {
            this.notifier.notify('error', response.message[0].description);
          }
        },
        error: error => {
          this.notifier.notify('error', 'Something went wrong');
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
