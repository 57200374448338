// Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-update-pilot-dialog',
  templateUrl: './update-pilot-dialog.component.html',
  styleUrls: ['./update-pilot-dialog.component.scss'],
  providers: [DatePipe] // Provide DatePipe
})

export class UpdatePilotDialogComponent implements OnInit {
  public updatePilotForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<UpdatePilotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifier: NotifierService,
    private datePipe: DatePipe // Inject DatePipe
  ) {
    // Initialize form group with form controls
    this.updatePilotForm = new FormGroup({
      QuestionnairePilot_yearId: new FormControl(0), // INT
      QuestionnairePilotID: new FormControl(0), // INT
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), // STRING
      NameofPilot: new FormControl('', [Validators.maxLength(50)]), // STRING
      DOB: new FormControl(''), // DATE (keeps Date object)
      Stud: new FormControl(false), // BOOL
      Pvt: new FormControl(false), // BOOL
      Coml: new FormControl(false), // BOOL
      ASEL: new FormControl(false), // BOOL
      AMEL: new FormControl(false), // BOOL
      Instrumt: new FormControl(false), // BOOL
      ATP: new FormControl(false), // BOOL
      Other: new FormControl('', Validators.maxLength(50)), // STRING
      DateLastPhysical: new FormControl(''), // STRING
      Class: new FormControl('', Validators.maxLength(50)), // STRING
      Total: new FormControl(0), // INT
      RetractGear: new FormControl('', Validators.maxLength(50)), // STRING
      MultiEngine: new FormControl('', Validators.maxLength(50)), // STRING
      Last90days: new FormControl(0), // INT
      Last12Mos: new FormControl(0), // INT
      Last12MosPilot: new FormControl('', Validators.maxLength(50)), // STRING
      Next12MosPilot: new FormControl('', Validators.maxLength(50)), // STRING
      FAACrtNoPilot: new FormControl('', Validators.maxLength(50)), // STRING
      DateLastBiennialRev: new FormControl(''), // DATE (keeps Date object)
      EffectiveDate: new FormControl('', Validators.required) // DATE
    });
  }

  ngOnInit() {
    // Convert the string dates from 'dd/MM/yyyy' format into Date objects when patching the form
    this.updatePilotForm.patchValue({
      'MunicipalityID': this.data.municipalityID,
      'MunicipalityYear': this.data.municipalityYear,
      'QuestionnairePilotID': this.data.questionnairePilotID,
      'QuestionnairePilot_yearId': this.data.questionnairePilot_yearId,
      'NameofPilot': this.data.nameofPilot,
      'DOB': this.parseDateString(this.data.dob), // Convert DOB string to Date object
      'Stud': this.data.stud,
      'Pvt': this.data.pvt,
      'Coml': this.data.coml,
      'ASEL': this.data.aSEL,
      'AMEL': this.data.aMEL,
      'Instrumt': this.data.instrumt,
      'ATP': this.data.atp,
      'Other': this.data.other,
      'DateLastPhysical': this.parseDateString(this.data.dateLastPhysical), // Convert string to Date object
      'Class': this.data.class,
      'Total': this.data.total,
      'RetractGear': this.data.retractGear,
      'MultiEngine': this.data.multiEngine,
      'Last90days': this.data.last90days,
      'Last12MosPilot': this.data.last12MosPilot,
      'Last12Mos': this.data.last12Mos,
      'Next12MosPilot': this.data.next12MosPilot,
      'FAACrtNoPilot': this.data.faaCrtNoPilot,
      'DateLastBiennialRev': this.parseDateString(this.data.dateLastBiennialRev), // Convert string to Date object
      'EffectiveDate': this.data.effectiveDate 
    });

    // Subscribe to changes in DOB and DateLastBiennialRev fields to preserve Date object
    this.updatePilotForm.get('DOB').valueChanges.subscribe(value => {
      this.formatDateField('DOB', value);
    });
    this.updatePilotForm.get('DateLastPhysical').valueChanges.subscribe(value => {
      this.formatDateField('DateLastPhysical', value);
    });
    this.updatePilotForm.get('DateLastBiennialRev').valueChanges.subscribe(value => {
      this.formatDateField('DateLastBiennialRev', value);
    });

    
  }

  // Helper method to parse a 'dd/MM/yyyy' string into a Date object
  parseDateString(dateString: string): Date | null {
    if (dateString) {
      const parts = dateString.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-indexed
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    }
    return null; // Return null if the date string is empty or invalid
  }

  // Helper method to ensure Date object is preserved in the form control
  formatDateField(field: string, value: any) {
    if (value instanceof Date) {
      // Ensure the form control retains a valid Date object
      this.updatePilotForm.get(field).setValue(value, { emitEvent: false });
    }
  }

  // Close modal and pass form data to the controller
  submitUpdatePilotForm(form) {
    if (form.valid) {
      // Convert date fields to the required format (d0/M0/0000) before submission
      const formattedDOB = this.datePipe.transform(form.value.DOB, 'dd/MM/yyyy');
      const formattedDateLastPhysical = this.datePipe.transform(form.value.DateLastPhysical, 'dd/MM/yyyy');
      const formattedDateLastBiennialRev = this.datePipe.transform(form.value.DateLastBiennialRev, 'dd/MM/yyyy');

      

      // Create final object with formatted dates
      const finalFormValue = {
        ...form.value,
        DOB: formattedDOB, // Format DOB before submission
        DateLastPhysical: formattedDateLastPhysical, // Format DateLastPhysical before submission
        DateLastBiennialRev: formattedDateLastBiennialRev // Format DateLastBiennialRev before submission
      };

      // Close dialog and pass formatted data
      this.dialogRef.close(finalFormValue);
      this.notifier.notify('success', 'Pilot record updated successfully!');
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  }

  // Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  }
}
