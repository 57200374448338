//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenerateNewYearService {
  constructor(private http: HttpClient) { }

  //Get list of municipalites with years
  getMunicipalitiesWithYears(): Observable<any> {
    return this.http.get('/api/General/GetListGenerateNewYear');
  };

  //Get details about the municipality with the following "ID" 
  detailGenerateNewYear(id): Observable<any> {
    return this.http.post('/api/General/GetListGenerateNewYear', id);
  };

  //add new generate year ?? NOT SURE WHAT THIS DOES
  addGenerateNewYear(municipality): Observable<any> {
    return this.http.post('/api/General/AddGenerateNewYear', municipality);
  };
};

