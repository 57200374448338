//Imort Modules
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

//Import Services
import { AuthService } from '../auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    const authToken = this.authService.getToken();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = authToken ? request.clone({
      headers: request.headers.set('Authorization', `Bearer ${authToken}`)
    }) : request;

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) { // or any other condition you define to recognize token expiration
          
          //Fix the wrong password 401 unauthorized issue
          if (authReq.url != '/api/Auth/Login') {
            this.authService.alertAndLogout();
          }

        }
        return throwError(error);
      })
    );
  }
}
