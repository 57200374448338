<div class="crime-application-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Crime Application</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="crimeApplicationForm" (ngSubmit)="submitForm(crimeApplicationForm)">
      <div class="row">
        <div class="col-md-8">
          <!-- NAME -->
          <mat-label>Name <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="ApplicationName" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['ApplicationName'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!-- SIC CODE -->
          <mat-label>SIC Code</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="SICCode" placeholder="" />
            <mat-error>Character length for this field is 10</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!-- ApplicationAddress -->
          <mat-label>Address <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="ApplicationAddress" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['ApplicationAddress'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- CITY -->
          <mat-label>City <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="City" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['City'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- State -->
          <mat-label>State <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="State">
              <mat-option value="AL">Alabama</mat-option>
              <mat-option value="AK">Alaska</mat-option>
              <mat-option value="AZ">Arizona</mat-option>
              <mat-option value="AR">Arkansas</mat-option>
              <mat-option value="CA">California</mat-option>
              <mat-option value="CO">Colorado</mat-option>
              <mat-option value="CT">Connecticut</mat-option>
              <mat-option value="DE">Delaware</mat-option>
              <mat-option value="DC">District Of Columbia</mat-option>
              <mat-option value="FL">Florida</mat-option>
              <mat-option value="GA">Georgia</mat-option>
              <mat-option value="HI">Hawaii</mat-option>
              <mat-option value="ID">Idaho</mat-option>
              <mat-option value="IL">Illinois</mat-option>
              <mat-option value="IN">Indiana</mat-option>
              <mat-option value="IA">Iowa</mat-option>
              <mat-option value="KS">Kansas</mat-option>
              <mat-option value="KY">Kentucky</mat-option>
              <mat-option value="LA">Louisiana</mat-option>
              <mat-option value="ME">Maine</mat-option>
              <mat-option value="MD">Maryland</mat-option>
              <mat-option value="MA">Massachusetts</mat-option>
              <mat-option value="MI">Michigan</mat-option>
              <mat-option value="MN">Minnesota</mat-option>
              <mat-option value="MS">Mississippi</mat-option>
              <mat-option value="MO">Missouri</mat-option>
              <mat-option value="MT">Montana</mat-option>
              <mat-option value="NE">Nebraska</mat-option>
              <mat-option value="NV">Nevada</mat-option>
              <mat-option value="NH">New Hampshire</mat-option>
              <mat-option value="NJ">New Jersey</mat-option>
              <mat-option value="NM">New Mexico</mat-option>
              <mat-option value="NY">New York</mat-option>
              <mat-option value="NC">North Carolina</mat-option>
              <mat-option value="ND">North Dakota</mat-option>
              <mat-option value="OH">Ohio</mat-option>
              <mat-option value="OK">Oklahoma</mat-option>
              <mat-option value="OR">Oregon</mat-option>
              <mat-option value="PA">Pennsylvania</mat-option>
              <mat-option value="RI">Rhode Island</mat-option>
              <mat-option value="SC">South Carolina</mat-option>
              <mat-option value="SD">South Dakota</mat-option>
              <mat-option value="TN">Tennessee</mat-option>
              <mat-option value="TX">Texas</mat-option>
              <mat-option value="UT">Utah</mat-option>
              <mat-option value="VT">Vermont</mat-option>
              <mat-option value="VA">Virginia</mat-option>
              <mat-option value="WA">Washington</mat-option>
              <mat-option value="WV">West Virginia</mat-option>
              <mat-option value="WI">Wisconsin</mat-option>
              <mat-option value="WY">Wyoming</mat-option>
            </mat-select>
            <mat-error *ngIf="crimeApplicationForm.controls['State'].hasError('required')">This field is requried</mat-error>
            <mat-error *ngIf="crimeApplicationForm.controls['State'].hasError('isCapital')">Must be 2 characters long, capital letters only</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- ZIP -->
          <mat-label>ZIP <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Zip" placeholder="" mask="00000" />
            <mat-error *ngIf="crimeApplicationForm.controls['Zip'].hasError('required')">This field is required</mat-error>
            <mat-error *ngIf="crimeApplicationForm.controls['Zip'].hasError('isNumbersOnly')">This field accepts numbers only</mat-error>
            <mat-error>Must be 5 characters long</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- BusinessActivity -->
          <mat-label>Business Activity <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="BusinessActivity" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['BusinessActivity'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- TaxReceipts -->
          <mat-label>Tax Receipts <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" currencyMask maxlength="20" matInput formControlName="TaxReceipts" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['TaxReceipts'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- PolicyTerm -->
          <mat-label>Policy Term</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="PolicyTerm" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- Organization -->
          <mat-label>Organization</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="Organization" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-6">
          <h5 class="mb-3">Desired Coverage</h5>
        </div>
        <div class="col-md-6">
          <h5 class="mb-3">Limits of Liability</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 align-self-center">
          <h6>Coverage Form O: Employee Dishonesty <span class="field-required-symbol">*</span></h6>
        </div>
        <div class="col-md-6">
          <!--CovOLimits-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="CovOLimits" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['CovOLimits'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 align-self-center">
          <h6>Coverage Form B: Forgery or Alteration <span class="field-required-symbol">*</span></h6>
        </div>
        <div class="col-md-6">
          <!--CovBLimits-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="CovBLimits" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['CovBLimits'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 align-self-center">
          <h6>Coverage Form C: Theft, Disappearance, Destruction (Money and Securities) <span class="field-required-symbol">*</span></h6>
        </div>
        <div class="col-md-6">
          <!--CovCLimits-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="CovCLimits" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['CovCLimits'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 align-self-center">
          <h6>Coverage Form D: Robbery and Safe Burglary <span class="field-required-symbol">*</span></h6>
        </div>
        <div class="col-md-6">
          <!--CovDLimits-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="CovDLimits" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['CovDLimits'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 align-self-center">
          <h6>Coverage Form F: Computer Fraud <span class="field-required-symbol">*</span></h6>
        </div>
        <div class="col-md-6">
          <!--CovFLimits-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="CovFLimits" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['CovFLimits'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 align-self-center">
          <h6>Other <span class="field-required-symbol">*</span></h6>
        </div>
        <div class="col-md-6">
          <!--OtherLimits-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="OtherLimits" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['OtherLimits'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 align-self-center">
          <h6>Deductible <span class="field-required-symbol">*</span></h6>
        </div>
        <div class="col-md-6">
          <!--Deductible-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="Deductible" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['Deductible'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--PriorInsurer-->
          <mat-label>Prior Insurer</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="PriorInsurer" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--PriorLimit-->
          <mat-label>Prior Limit <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="PriorLimit" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['PriorLimit'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--PriorDeductible-->
          <mat-label>Prior Deductible <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input currencyMask maxlength="20" matInput formControlName="PriorDeductible" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['PriorDeductible'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--DutyCov-->
          <mat-checkbox formControlName="DutyCov">Is Faithful Performance of Duty Coverage desired?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--EntityExclude-->
          <mat-label>List any department, board, commission, or sub-entity that carries its own separate bond or policy and, if applicable, list any other entity that should be excluded from this policy. (If none, write 'none'.)</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EntityExclude" placeholder="" />
            <mat-error>Character length for this field is 250</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12">
          <h5>Do your statutes/ordinances allow the Public Employee Dishonesty Coverage to include coverage for the following positions? Check all that applys</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--Treasurer-->
          <mat-checkbox formControlName="Treasurer">Treasurers</mat-checkbox>
        </div>
        <div class="col-md-4">
          <!--TaxCollector-->
          <mat-checkbox formControlName="TaxCollector">Tax Collectors</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--OtherBonded-->
          <mat-checkbox formControlName="OtherBonded">Other positions previously bonded separately</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Statutory-->
          <mat-label>If checked, please cite statutory provision and identify the other positions by name</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="Statutory" placeholder="" />
            <mat-error>Character length for this field is 250</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12">
          <h5>If an Obligee other than the Named Insured needs to be indemnified under this insurance, please provide the name and address of the Obligee:</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ObligeeName-->
          <mat-label>Obligee Name <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="ObligeeName" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['ObligeeName'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--ObligeeAddress-->
          <mat-label>Obligee Address <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="ObligeeAddress" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['ObligeeAddress'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12">
          <h5>Loss Experience, list all crime losses sustained during the last three years whether reimbursed or not.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--LossExperience-->
          <mat-checkbox formControlName="LossExperience">Check here if none</mat-checkbox>
        </div>
        <!--Table-->
        <div class="col-md-12 my-5" *ngIf="crimeApplicationForm.controls['LossExperience'].value === false">
          <table *ngIf="lossExperienceDataSource" class="datatable" mat-table [dataSource]="lossExperienceDataSource">
            <ng-container matColumnDef="lossDate">
              <th mat-header-cell *matHeaderCellDef> Loss Date </th>
              <td mat-cell *matCellDef="let element"> {{element.lossDate | date}} </td>
            </ng-container>
            <ng-container matColumnDef="lossDescription">
              <th mat-header-cell *matHeaderCellDef> Loss Description </th>
              <td mat-cell *matCellDef="let element"> {{element.lossDescription}} </td>
            </ng-container>
            <ng-container matColumnDef="correctiveAction">
              <th mat-header-cell *matHeaderCellDef> Corrective Action </th>
              <td mat-cell *matCellDef="let element"> {{element.correctiveAction}} </td>
            </ng-container>
            <ng-container matColumnDef="totalAmount">
              <th mat-header-cell *matHeaderCellDef> Total Amount </th>
              <td mat-cell *matCellDef="let element"> {{element.totalAmount | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="mr-1" type="button" color="primary" (click)="updateLossExperience(element)">Edit</button>
                <button mat-raised-button class="ml-1" type="button" color="warn" (click)="deleteLossExperience(element)">Delete</button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsLossExperience"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsLossExperience;"></tr>
          </table>
          <div class="table-button">
            <button type="button" class="mt-3" mat-raised-button color="primary" (click)="openAddLossExperienceDialog()" [disabled]="crimeApplicationForm.disabled">Add Loss Experience </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--TotalEmployees-->
          <mat-label>Total # of Employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="TotalEmployees" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['TotalEmployees'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--TotalPolicemen-->
          <mat-label># of Police Dept. Employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="TotalPolicemen" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['TotalPolicemen'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--TotalFiremen-->
          <mat-label># of Fire Dept. Employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="TotalFiremen" placeholder="" />
            <mat-error *ngIf="crimeApplicationForm.controls['TotalFiremen'].hasError('required')">This field is requried</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--OtherLocations-->
          <mat-label>Locations (other than main office)</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="OtherLocations" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12">
          <h5>Entities that practice segregation of duties and perform background checks on new employees have a better opportunity to either prevent or detect employee dishonesty. Segregation of duties means that no single employee can control a process or transaction from beginning to end.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--ReconDeposit-->
          <mat-checkbox formControlName="ReconDeposit">1. Do employees who reconcile the bank statement also: Make Deposits?</mat-checkbox>
        </div>
        <div class="col-md-4">
          <!--ReconWithdraw-->
          <mat-checkbox formControlName="ReconWithdraw">Make Withdrawals?</mat-checkbox>
        </div>
        <div class="col-md-4">
          <!--ReconSignCks-->
          <mat-checkbox formControlName="ReconSignCks">Sign Checks?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--IndependentAudit-->
          <mat-checkbox formControlName="IndependentAudit">2. Is there an independent audit by a CPA, public accountant, or equivalent?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--DateLastAudit-->
          <mat-label>Date of last audit completed <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="DateLastAudit" [matDatepicker]="DateLastAudit">
            <mat-datepicker-toggle matSuffix [for]="DateLastAudit"></mat-datepicker-toggle>
            <mat-datepicker #DateLastAudit></mat-datepicker>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--AuditRendered-->
          <mat-checkbox formControlName="AuditRendered">3. Is the audit rendered to a regulatory authority?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--AuditDiscrepencies-->
          <mat-checkbox formControlName="AuditDiscrepencies">4. Were any discrepancies or loose practices commented upon in the audit?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--InternalAuditDept-->
          <mat-checkbox formControlName="InternalAuditDept">5. Is there an Internal Audit Department under the control of an employee who is a public accountant or equivalent?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--RenderName-->
          <mat-label>If yes, to who are the reports rendered?</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="RenderName" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--AllLocationAudit-->
          <mat-checkbox formControlName="AllLocationAudit">Are all locations audited?</mat-checkbox>
        </div>
        <!--Table-->
        <div class="col-md-12 my-5">
          <h6>Please indicate maximum exposure for each location:</h6>
          <table class="datatable" mat-table [dataSource]="locationExposureDataSource" matSort>
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef> Location </th>
              <td mat-cell *matCellDef="let element"> {{element.location}} </td>
            </ng-container>
            <ng-container matColumnDef="cash">
              <th mat-header-cell *matHeaderCellDef> Cash </th>
              <td mat-cell *matCellDef="let element"> {{element.cash | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="retailChecks">
              <th mat-header-cell *matHeaderCellDef> Retail Checks </th>
              <td mat-cell *matCellDef="let element"> {{element.retailChecks | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="creditCardReceipts">
              <th mat-header-cell *matHeaderCellDef> Credit Card Receipts and Non-retail Checks* </th>
              <td mat-cell *matCellDef="let element"> {{element.creditCardReceipts | currency}} </td>
            </ng-container>
            <ng-container matColumnDef="safe">
              <th mat-header-cell *matHeaderCellDef> Is there a Safe? </th>
              <td mat-cell *matCellDef="let element"> {{element.safe | yesNo}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="mr-1" type="button" color="primary" (click)="updateLocationExposure(element)">Edit</button>
                <button mat-raised-button class="ml-1" type="button" color="warn" (click)="deleteLocationExposure(element)">Delete</button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsLocationExposure"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsLocationExposure;"></tr>
          </table>
          <div class="table-button">
            <button type="button" class="mt-3" mat-raised-button color="primary" (click)="openAddLocationExposureDialog()" [disabled]="crimeApplicationForm.disabled">Add Location Exposure </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate-->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>*A non-retail check is a check presented to you and immediately endorsed "for deposit only" and then recorded in your accounting process so that it could be recreated if it were stolen, lost or destroyed.</h5>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>NOTE Persons  by law to be individually bonded and treasurers or tax collectors by whatever titles known are automatically excluded from coverage under Coverage Form O</h5>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such a person to criminal and civil penalties.</h5>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="crimeApplicationForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
