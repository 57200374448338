//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';

//Import Services
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';

//Import Tanks Dialog
import { NewTankDialogComponent } from './dialog/new-tank-location/new-tank-location.component';
import { UpdateTankLocationComponent } from './dialog/update-tank-location/update-tank-location.component';
import { DeleteTankDialogComponent } from './dialog/delete-tank-dialog/delete-tank-dialog.component';


@Component({
  selector: 'app-storage-tank-inventory-location',
  templateUrl: './storage-tank-inventory-location.component.html',
  styleUrls: ['./storage-tank-inventory-location.component.scss']
})
export class StorageTankInventoryLocationComponent implements OnInit {
  constructor(public dialog: MatDialog,
    private notifier: NotifierService,
    private renewalApplicationsService: RenewalApplicationsService,
    private exposuresService: ExposuresService,
    private fb: FormBuilder) {

    // Create Details Form
    this.detailsForm = new FormGroup({
      StorageTankLocationDetailsID: new FormControl(0), //INT
      StorageTankInventoryDetails_yearId: new FormControl(0), //INT
      StorageTankInventoryDetailsID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      FacilityName: new FormControl('', Validators.maxLength(100)), //STRING
      FacilityAddress: new FormControl('', Validators.maxLength(100)), //STRING
      FacilityDate: new FormControl(''), //DATE
      FacilityId: new FormControl('', Validators.maxLength(50)), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });


  };

  //Forms
  public detailsForm: FormGroup;
  public tanksForm: FormGroup;

  public activeMunicipality;
  public activeLocation;
  public locationsDropdown;
  
  public allTanks;
  showTanksForm = false;


  //Tanks table
  public displayedColumns: string[] = ["tankNo", "usT_AST", "installDateYear", "capacity", "action"];
  public dataSourceTanks;


  ngOnInit() {
    this.detailsForm.disable();

    //Get municipality & load tank locations dropdown
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      this.activeMunicipality = response;
      this.getListStorageTankInventory(response);      
    });
  }

  // STEP 1: Load tank locations dropdown
  public getListStorageTankInventory(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetListStorageTankLocationDetails').subscribe((response: any) => {
      this.locationsDropdown = response;
    });
  }

  public changeActiveTankLocation(tankLocation) {
    this.getLocationDetails(tankLocation);
    this.activeLocation = tankLocation;
  }


  // Get Location Details
  public getLocationDetails(tankLocation) {

    this.detailsForm.disable();

    this.exposuresService.getDetailsInventory('GetDetailStorageTankInventoryDetails', tankLocation.storageTankLocationDetailsID, tankLocation.municipalityID, tankLocation.municipalityYear).subscribe((response: any) => {

      let activeTank = response;
      console.log(activeTank);

      // Fill form up with the location details
      if (activeTank) {
        
        this.detailsForm.patchValue({
          'StorageTankLocationDetailsID': tankLocation.storageTankLocationDetailsID,
          'StorageTankInventoryDetails_yearId': activeTank.storageTankInventoryDetails_yearId,
          'StorageTankInventoryDetailsID': activeTank.storageTankInventoryDetailsID,
          'MunicipalityID': activeTank.municipalityID,
          'MunicipalityYear': activeTank.municipalityYear,
          'FacilityName': activeTank.facilityName,
          'FacilityAddress': activeTank.facilityAddress,
          'FacilityDate': activeTank.facilityDate,
          'FacilityId': activeTank.facilityId,
          'EffectiveDate': activeTank.effectiveDate
        });

        this.detailsForm.enable();

        // Display list of tanks
        if (activeTank.storageTanks) {
          this.showTanksForm = true;

          // Populate the tanks array
          this.dataSourceTanks = new MatTableDataSource(activeTank.storageTanks);
        }

      }
      else {
        //New form
        this.detailsForm.reset();

        this.detailsForm.patchValue({
          'StorageTankLocationDetailsID': tankLocation.storageTankLocationDetailsID,
          'MunicipalityID': this.activeMunicipality.municipalityID,
          'MunicipalityYear': this.activeMunicipality.municipalityYear,
          'StorageTankInventoryDetailsID': 0,
          'StorageTankInventoryDetails_yearId': 0,
          'FacilityDate': "2024-09-25T04:00:00.000Z"
        });

        // Hide list of tanks
        this.showTanksForm = false;
        this.dataSourceTanks = [];

        this.detailsForm.enable();
      }
    });
  }

  public submitLocationDetails() {

    if (this.detailsForm.valid) {
      // Extract values from detailsForm
      const detailsFormData = this.detailsForm.value;
      
      // Combine detailsForm and tanksData into the final DTO
      const storageTankInventoryDetailDto = {
        StorageTankInventoryDetails_yearId: detailsFormData.StorageTankInventoryDetails_yearId,
        StorageTankInventoryDetailsID: detailsFormData.StorageTankInventoryDetailsID,
        StorageTankLocationDetailsID: detailsFormData.StorageTankLocationDetailsID,
        MunicipalityID: detailsFormData.MunicipalityID,
        MunicipalityYear: detailsFormData.MunicipalityYear,
        FacilityName: detailsFormData.FacilityName,
        FacilityAddress: detailsFormData.FacilityAddress,
        FacilityDate: detailsFormData.FacilityDate,
        FacilityId: detailsFormData.FacilityId,
        EffectiveDate: detailsFormData.EffectiveDate
      };
      

      // Send data to the backend
      if (storageTankInventoryDetailDto.StorageTankInventoryDetailsID === 0 &&
        storageTankInventoryDetailDto.StorageTankInventoryDetails_yearId === 0) {
        // Add new record
        this.exposuresService.add(storageTankInventoryDetailDto, 'AddStorageTankInventoryDetails').subscribe((response: any) => {
          if (response.error === 0) {
            //this.getListStorageTankInventory(storageTankInventoryDetailDto);
            this.notifier.notify('success', 'New record created successfully');
            this.showTanksForm = true;
          } else {
            this.notifier.notify('error', response.message);
          }
        });
      } else {
        // Update existing record
        this.exposuresService.update(storageTankInventoryDetailDto, 'UpdateStorageTankInventoryDetails').subscribe((response: any) => {
          if (response.error === 0) {
            //this.getListStorageTankInventory(storageTankInventoryDetailDto);
            this.notifier.notify('success', 'Record successfully updated');
            this.showTanksForm = true;
          } else {
            this.notifier.notify('error', response.message);
          }
        });
      }
    } else {
      // Handle invalid form scenario
      this.notifier.notify('error', 'There were errors in your submission. Please correct the highlighted fields and try again.');
    }
  }


  public openNewTankDialog() {
    
    const dialogRef = this.dialog.open(NewTankDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear,
        'StorageTankInventoryDetailsID': this.detailsForm.value.StorageTankInventoryDetailsID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.add(result, 'AddStorageTank').subscribe((response: any) => {
          if (response.error == 0) {
            this.getLocationDetails(this.activeLocation);
            this.showTanksForm = true;
            this.notifier.notify('success', 'New record created successfully');
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public updateTankDialog(data) {

    const dialogRef = this.dialog.open(UpdateTankLocationComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.update(result, 'UpdateStorageTank').subscribe((response: any) => {
          if (response.error == 0) {
            this.getLocationDetails(this.activeLocation);
            this.notifier.notify('success', "Record successfully updated");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  };

  public deleteTankDialog(data) {
    const dialogRef = this.dialog.open(DeleteTankDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.delete(result, 'DeleteStorageTank').subscribe((response: any) => {
          if (response.error == 0) {
            this.getLocationDetails(this.activeLocation);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      }
    });
  }

  get totalTanks(): number {
    return this.dataSourceTanks ? this.dataSourceTanks.data?.length : 0;
  }
}
