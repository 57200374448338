//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-fine-art-dialog',
  templateUrl: './delete-fine-art-dialog.component.html',
  styleUrls: ['./delete-fine-art-dialog.component.scss']
})
export class DeleteFineArtDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteFineArtDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  //Close modal and pass form data to controller
  confimFineArtDeletion() {
    this.dialogRef.close(this.data.scheduleFineArts_yearId);
  };

  //Close without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };
};
