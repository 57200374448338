//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-crime-positon-bond-worksheet-dialog',
  templateUrl: './delete-crime-positon-bond-worksheet-dialog.component.html',
  styleUrls: ['./delete-crime-positon-bond-worksheet-dialog.component.scss']
})
export class DeleteCrimePositonBondWorksheetDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteCrimePositonBondWorksheetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {};

  //Close modal and pass form data to controller
  confimPositionBondDeletion() {
    this.dialogRef.close(this.data.positionBondProgramID);
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };
}
