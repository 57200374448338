<div class="vin-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Vin</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2">Add New Employee Location</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table class="datatable" mat-table [dataSource]="dataSource" matSort>
          <!-- ClassDescription -->
          <ng-container matColumnDef="ClassDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Description </th>
            <td mat-cell *matCellDef="let element"> {{element.ClassDescription}} </td>
          </ng-container>
          <!-- ClassCode -->
          <ng-container matColumnDef="ClassCode">
            <th mat-header-cell *matHeaderCellDef> Class Code </th>
            <td mat-cell *matCellDef="let element"> {{element.ClassCode}} </td>
          </ng-container>
          <!-- Payroll -->
          <ng-container matColumnDef="Payroll">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Payroll </th>
            <td mat-cell *matCellDef="let element"> {{element.Payroll}} </td>
          </ng-container>
          <!-- FTEmpl -->
          <ng-container matColumnDef="FTEmpl">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FTEmpl </th>
            <td mat-cell *matCellDef="let element"> {{element.FTEmpl}} </td>
          </ng-container>
          <!-- PTEmpl -->
          <ng-container matColumnDef="PTEmpl">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PTEmpl </th>
            <td mat-cell *matCellDef="let element"> {{element.PTEmpl}} </td>
          </ng-container>
          <!-- ClassSeasonal -->
          <ng-container matColumnDef="ClassSeasonal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Seasonal </th>
            <td mat-cell *matCellDef="let element"> {{element.ClassSeasonal}} </td>
          </ng-container>
          <!-- Volunteer -->
          <ng-container matColumnDef="Volunteer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Volunteer </th>
            <td mat-cell *matCellDef="let element"> {{element.Volunteer}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>


