//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { first } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'app-two-step-auth',
  templateUrl: './two-step-auth.component.html',
  styleUrls: ['./two-step-auth.component.scss']
})
export class TwoStepAuthComponent implements OnInit {
  constructor(public authService: AuthService, private fb: FormBuilder, private router: Router, private notifier: NotifierService) {
    this.twoFactorForm = new FormGroup({
      TwoFactorCode: new FormControl('', Validators.required),
      RememberMe: new FormControl(true)
    });
  };

  public twoFactorForm: FormGroup;

  ngOnInit() { }

  //Submit two step auth form
  submitTwoStepAuth(form) {
    if (form.valid) {
      this.authService.accountAuthTwoFactorLogin(this.twoFactorForm.value).pipe(first()).subscribe({
        next: (response) => {
          this.router.navigateByUrl('/dashboard/general-info');
        },
        error: error => {
          this.notifier.notify('error', 'Invalid code, please try again');
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
