//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

//Import Services
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.generalForm = new FormGroup({
      RenewalApplicationID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      RiskManagerID: new FormControl(0), //INT
      ApplicationDate: new FormControl('', Validators.required), //DATE
      CoverageEffectiveDate: new FormControl('', Validators.required), //DATE
      CoverageEffectiveYear: new FormControl('', Validators.required), //DATE
      ContactName: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      ContactTitle: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      ContactPhone: new FormControl('', [Validators.required, Validators.maxLength(20)]), //STRING
      ContactFax: new FormControl('', [Validators.required, Validators.maxLength(20)]), //STRING
      ContactEmail: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      FullName: new FormControl('', Validators.maxLength(150)), //STRING
      Address1: new FormControl('', Validators.maxLength(100)), //STRING
      Address2: new FormControl('', Validators.maxLength(100)), //STRING
      City: new FormControl('', Validators.maxLength(50)), //STRING
      Zip: new FormControl('', Validators.maxLength(5)), //STRING
      State: new FormControl('', Validators.maxLength(2)), //STRING
      County: new FormControl('', Validators.maxLength(50)), //STRING
      PublicOfficialsElected: new FormControl(0, Validators.required), //INT
      PublicOfficialsAppointed: new FormControl(0, Validators.required), //INT
      PopulationNormal: new FormControl(0, Validators.required), //INT
      PopulationSeasonal: new FormControl(0, Validators.required), //INT
      EmployeeTotal: new FormControl({value: 0, disabled:true}, Validators.required), //INT
      GrossPayrollTotal: new FormControl({ value: 0, disabled: true }, Validators.required), //INT
      ContactorExpenditures: new FormControl(0, Validators.required), //INT
      QuasiMunicipalEntities: new FormControl('', Validators.maxLength(500)), //STRING
      OperatingExpenditureEstimate: new FormControl(0, Validators.required), //INT
      Locked: new FormControl(0), //BOOL
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public activeMunicipality;
  public generalForm: FormGroup;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.generalForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getGeneral(response);
      }
    });
  };

  public getGeneral(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetListRenewalApplication').subscribe((response: any) => {
      if (!response) {
        this.generalForm.patchValue({
          'RenewalApplicationID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'RiskManagerID': 0,
          'ApplicationDate': '',
          'CoverageEffectiveDate': '',
          'CoverageEffectiveYear': '',
          'ContactName': '',
          'ContactTitle': '',
          'ContactPhone': '',
          'ContactFax': '',
          'ContactEmail': '',
          'FullName': '',
          'Address1': '',
          'Address2': '',
          'City': '',
          'Zip': '',
          'State': '',
          'County': '',
          'PublicOfficialsElected': 0,
          'PublicOfficialsAppointed': 0,
          'PopulationNormal': 0,
          'PopulationSeasonal': 0,
          'EmployeeTotal': 0,
          'GrossPayrollTotal': 0,
          'ContactorExpenditures': 0,
          'QuasiMunicipalEntities': '',
          'OperatingExpenditureEstimate': 0,
          'Locked': false,
          'EffectiveDate': ''
        })
      } else {
        this.generalForm.patchValue({
          'RenewalApplicationID': response.renewalApplicationID,
          'ApplicationDate': response.applicationDate,
          'CoverageEffectiveDate': response.coverageEffectiveDate,
          'CoverageEffectiveYear': response.coverageEffectiveYear,
          'ContactName': response.contactName,
          'ContactTitle': response.contactTitle,
          'ContactPhone': response.contactPhone,
          'ContactFax': response.contactFax,
          'ContactEmail': response.contactEmail,
          'RiskManagerID': response.riskManagerID,
          'MunicipalityYear': response.municipalityYear,
          'FullName': response.fullName,
          'Address1': response.address1,
          'Address2': response.address2,
          'City': response.city,
          'Zip': response.zip,
          'State': response.state,
          'County': response.county,
          'PublicOfficialsElected': response.publicOfficialsElected,
          'PublicOfficialsAppointed': response.publicOfficialsAppointed,
          'PopulationNormal': response.populationNormal,
          'PopulationSeasonal': response.populationSeasonal,
          'EmployeeTotal': response.employeeTotal,
          'GrossPayrollTotal': response.grossPayrollTotal,
          'ContactorExpenditures': response.contactorExpenditures,
          'QuasiMunicipalEntities': response.quasiMunicipalEntities,
          'OperatingExpenditureEstimate': response.operatingExpenditureEstimate,
          'Locked': response.locked,
          'MunicipalityID': response.municipalityID,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public submitForm(form) {

    if (form.valid && form.value.RenewalApplicationID == 0) {
      this.exposuresService.add(form.value, 'AddRenewalApplication').subscribe((response: any) => {
        if (response.error == 0) {
          this.getGeneral(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateRenewalApplication').subscribe((response: any) => {
        if (response.error == 0) {
          this.getGeneral(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
