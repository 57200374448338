<section id="sidebar-section">
  <div class="change-client-dropdown">
    <form [formGroup]="selectMunicipalityForm">
      <div class="col-md-12">
        <mat-form-field class="field-full-width" appearance="fill">
          <mat-label>Select Municipality</mat-label>
          <mat-select formControlName="Municipality" (selectionChange)="changeMunicipality($event.value)">
            <!-- Filter input placed as a separate mat-option -->
            <mat-option>
              <div style="display: flex; align-items: center; gap: 5px;">
                <!-- Bind input to filterControl instead of using ngModel -->
                <input matInput
                       [formControl]="filterControl"
                       (keyup)="onKey($event.target.value)"
                       placeholder="Filter Municipality"
                       autocomplete="off"
                       (click)="$event.stopPropagation()"
                       (mousedown)="$event.stopPropagation()"
                       style="width: 90%;">
                <!-- Clear button to reset filter -->
                <button mat-icon-button
                        *ngIf="filterControl.value"
                        (click)="clearFilter($event)"
                        tabindex="-1"
                        style="margin: 0; padding: 0;">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </mat-option>
            <!-- Display filtered options -->
            <mat-option *ngFor="let municipality of filteredMunicipalities" [value]="municipality.municipalityID">
              {{ municipality.shortName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field class="field-full-width" appearance="fill">
          <mat-label>Select year</mat-label>
          <mat-select formControlName="Year" (selectionChange)="submitMunicipalityForm(selectMunicipalityForm)">
            <mat-option *ngFor="let year of years" value="{{year}}">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="alert-box" *ngIf="showAlert">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-box-danger">
          <div class="alert-message">
            <span>Please select municipality and year</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-material-multilevel-menu [configuration]="config" [items]="appitems"></ng-material-multilevel-menu>
