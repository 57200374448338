<div class="users-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Departments</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" (click)="openAddDialog()">Register New Department</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table mat-table class="datatable" [dataSource]="dataSource" matSort matSortActive="departmentID" matSortDirection="asc" [@.disabled]="true">
          <ng-container matColumnDef="departmentID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Department ID </th>
            <td mat-cell *matCellDef="let element"> {{element.departmentID}} </td>
          </ng-container>
          <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Name </th>
            <td mat-cell *matCellDef="let element"> {{element.department}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <div style="white-space: nowrap;">
                <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openEditDialog(element)">mode_edit</mat-icon>
                <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteDialog(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
