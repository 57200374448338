//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FreezeUpdatesService {
  constructor(private http: HttpClient) {
    this.frozenMunicipalitySubject = new BehaviorSubject<any>(null);
    this.frozenMunicipality = this.frozenMunicipalitySubject.asObservable();
  };

  private frozenMunicipalitySubject: BehaviorSubject<any>;
  public frozenMunicipality: Observable<any>;

  public getFrozenMunicipalities(year) {
    return this.http.get('/api/General/GetListFreezeUpdates', { params: { 'year': year } }).pipe(map(response => {
      this.frozenMunicipalitySubject.next(response);
      return response;
    }));
  };

  public getYearsList() {
    return this.http.get('/api/General/GetListFreezeYears').pipe(map(response => {
      this.frozenMunicipalitySubject.next(response);
      return response;
    }));
  };

  public updateFreezeUpdate(municipality) {
    return this.http.put('/api/General/UpdateFreezeUpdate', municipality);
  };
};
