//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAccount, IAccountConfirmEmail, IChangeAccountPassword, IResetPasswordViewModel, IUpdateUserAccount, IUserConfirmEmailViewModel } from '../../shared/classes/account.class';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient, private router: Router) { };

  public getAccountDetails(data) {
    return this.http.get('/api/Account/GetUserDetails', { params: { id: data.userID } });
  };

  public getAllAccount() {
    return this.http.get<IAccount[]>('/api/Account/GetAllUsers');
  };

  public accountCreate(data: IAccount) {
    return this.http.post('/api/Account/AddUser', data);
  };

  public accountEdit(data: IUpdateUserAccount) {
    return this.http.put('/api/Account/UpdateUser', data);
  };

  public accountDelete(data: string) {
    return this.http.delete('/api/Account/DeleteUser', { params: { id: data } });
  };

  public accountCreatePassword(data: IAccountConfirmEmail) {
    return this.http.post('/api/Account/ConfirmUserEmail', data);
  };

  public accountForgotPassword(data: string) {
    return this.http.get('/api/Account/ForgotPassword', { params: { email: data } });
  };

  public accuntChangePassword(userId: string, currentPassword: string, newPassword: string) {
    return this.http.post('/api/Account/ChangeProfilePassword', { 'UserId': userId, 'CurrentPassword': currentPassword, 'NewPassword': newPassword });
  };

  public accountResetPassword(data: IResetPasswordViewModel) {
    return this.http.post('/api/Account/ResetPassword', data);
  };

  public accountConfirm(data: IUserConfirmEmailViewModel) {
    return this.http.post('/api/Account/ConfirmUserEmail', data);
  };

  public accountResendActivationEmail(data: string) {
    return this.http.get('/api/Account/ResendEmailConfirmation', { params: { email: data } });
  };
};

