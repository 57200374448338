//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExposuresService {
  constructor(private http: HttpClient) { }

  private endpoint = '/api/Exposures/'

  public get(municipalityID, municipalityYear, route): Observable<any> {
    return this.http.get(this.endpoint + route, { params: { municipalityID, municipalityYear } });
  };

  public getList(municipalityID, municipalityYear, route) {
    return this.http.get(this.endpoint + route, { params: { municipalityID, municipalityYear } });
  };

  public getDetail(id, route): Observable<any> {
    return this.http.get(this.endpoint + route, { params: { id: id } });
  };

  public getDetailsInventory(route, locationDetailsID, municipalityID, municipalityYear): Observable<any> {
    return this.http.get(this.endpoint + route, { params: { municipalityID, municipalityYear, locationDetailsID } });
  };

  public add(data, route): Observable<any> {
    return this.http.post(this.endpoint + route, data);
  };

  public update(data, route): Observable<any> {
    return this.http.put(this.endpoint + route, data);
  };

  public delete(yearId, route): Observable<any> {
    return this.http.delete(this.endpoint + route, { params: { yearId } });
  };
};
