<div class="dialog-title">
  <div class="row">
    <div class="col-md-12">
      <div class="dialog-title">
        <h4 mat-dialog-title>Register new department</h4>
      </div>
    </div>
  </div>
</div>
<div class="dialog-form">
  <form [formGroup]="departmentsForm" (ngSubmit)="submitDepartmentForm(departmentsForm)">
    <div class="form-section">
      <div class="row">
        <div class="col-md-12">
          <div class="form-section-title">
            <h6>Department Information</h6>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-label>Department Name <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" formControlName="Department" matInput>
            <mat-error *ngIf="departmentsForm.controls['Department'].hasError('required')">This field is required</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-action-buttons">
          <div mat-dialog-actions class="float-right">
            <button mat-button type="button" (click)="onNoClick()">Cancel</button>
            <button mat-button type="submit" mat-raised-button color="success" cdkFocusInitial class="submit-btn">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
