<div class="ust-liability-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>UST Liability</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="ustLiabilityForm" (ngSubmit)="submitForm(ustLiabilityForm)">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <!--ApplicantName-->
              <mat-label>1. Name of Applicant <span class="field-required-symbol">*</span></mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantName" placeholder="" />
                <mat-error *ngIf="ustLiabilityForm.controls['ApplicantName'].hasError('required')">This field is requried</mat-error>
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--ApplicantEmail-->
              <mat-label>E-mail Address</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantEmail" placeholder="" />
                <mat-error *ngIf="ustLiabilityForm.controls['ApplicantEmail'].hasError('email')">Must be a valid email address.</mat-error>
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--ApplicantMailingAddress-->
              <mat-label>Mailing Address <span class="field-required-symbol">*</span></mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantMailingAddress" placeholder="" />
                <mat-error *ngIf="ustLiabilityForm.controls['ApplicantMailingAddress'].hasError('required')">This field is requried</mat-error>
                <mat-error>Character length for this field is 250</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--ApplicantTelephone-->
              <mat-label>Telephone Number <span class="field-required-symbol">*</span></mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantTelephone" placeholder="" mask="(000) 000-0000" />
                <mat-error *ngIf="ustLiabilityForm.controls['ApplicantTelephone'].hasError('required')">This field is requried</mat-error>
                <mat-error>Character length for this field is 20</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--ApplicantURL-->
              <mat-label>URL http://</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantURL" placeholder="" />
                <mat-error>Character length for this field is 250</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--ApplicantIs-->
              <mat-label>The Applicant Is</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantIs" placeholder="" />
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <!--ApplicantContact-->
              <mat-label>Principal Contact <span class="field-required-symbol">*</span></mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantContact" placeholder="" />
                <mat-error *ngIf="ustLiabilityForm.controls['ApplicantContact'].hasError('required')">This field is requried</mat-error>
                <mat-error>Character length for this field is 100</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--ApplicantFax-->
              <mat-label>Fax Number</mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantFax" placeholder="" mask="(000) 000-0000" />
                <mat-error>Character length for this field is 20</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--ApplicantDateEstablish-->
              <mat-label>Date Established <span class="field-required-symbol">*</span></mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="ApplicantDateEstablish" [matDatepicker]="ApplicantDateEstablish">
                <mat-datepicker-toggle matSuffix [for]="ApplicantDateEstablish"></mat-datepicker-toggle>
                <mat-datepicker #ApplicantDateEstablish></mat-datepicker>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <!--FEIN-->
              <mat-label>Federal Employer Identification Number (FEIN) <span class="field-required-symbol">*</span></mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input matInput formControlName="FEIN" placeholder="" />
                <mat-error *ngIf="ustLiabilityForm.controls['FEIN'].hasError('required')">This field is requried</mat-error>
                <mat-error>Character length for this field is 50</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>2. Details of locations where the insured storage tanks are located (Continue on a separate sheet, if necessary)</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 my-5">
          <!--Table-->
          <table class="datatable" mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef> Company Name </th>
              <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef> Street Address, City, State and Zip Code </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container>
            <ng-container matColumnDef="ustLocation">
              <th mat-header-cell *matHeaderCellDef> No. of USTs at location </th>
              <td mat-cell *matCellDef="let element"> {{element.ustLocation}} </td>
            </ng-container>
            <ng-container matColumnDef="astLocation">
              <th mat-header-cell *matHeaderCellDef> No. of ASTs at location </th>
              <td mat-cell *matCellDef="let element"> {{element.astLocation}} </td>
            </ng-container>
            <ng-container matColumnDef="contamination">
              <th mat-header-cell *matHeaderCellDef> Pre-existing Contamination Present? * </th>
              <td mat-cell *matCellDef="let element"> {{element.contamination}} </td>
            </ng-container>
            <ng-container matColumnDef="facilityType">
              <th mat-header-cell *matHeaderCellDef> Facility Type** </th>
              <td mat-cell *matCellDef="let element"> {{element.facilityType}} </td>
            </ng-container>
            <ng-container matColumnDef="facilityTypeOther">
              <th mat-header-cell *matHeaderCellDef> Other facility Type </th>
              <td mat-cell *matCellDef="let element"> {{element.facilityTypeOther}} </td>
            </ng-container>
            <!-- Action -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <div style="white-space: nowrap;">
                  <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="updateStorageTankLocationService(element)">mode_edit</mat-icon>
                  <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="deleteStorageTankLocationService(element)">delete</mat-icon>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <button mat-raised-button type="button" color="primary" class="my-5" [disabled]="ustLiabilityForm.disabled" (click)="openNewStorageTankLocationDialog()">Add New Location</button>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>* Please complete the Storage Tank Inventory – By Location form as attached to this application.(If more than one location, please make duplicates of the inventory form and complete a separate form for each location.)</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--RecoveredExpensesAmount-->
          <mat-label>4. The Applicant's total gross revenues in the last filed tax return, excluding recovered expenses</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="RecoveredExpensesAmount" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--EndingPeriodMonth-->
          <mat-label>Month</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EndingPeriodMonth" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--EndingPeriodYear-->
          <mat-label>Year</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="EndingPeriodYear" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--GrossRevenuesFiscalYear-->
          <mat-label>5. The Applicant’s estimated gross revenues for the current fiscal year</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="GrossRevenuesFiscalYear" placeholder="" mask="separator.2" thousandSeparator="," />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--CoverageDate-->
          <mat-label>6. Desired effective date of coverage <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="CoverageDate" [matDatepicker]="CoverageDate">
            <mat-datepicker-toggle matSuffix [for]="CoverageDate"></mat-datepicker-toggle>
            <mat-datepicker #CoverageDate></mat-datepicker>
            <mat-error *ngIf="ustLiabilityForm.controls['CoverageDate'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>Desired Retroactive Date</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <!--PolicyInception-->
          <mat-checkbox formControlName="PolicyInception">Policy Inception</mat-checkbox>
        </div>
        <div class="col-md-6">
          <!--PolicyInceptionOther-->
          <mat-checkbox formControlName="PolicyInceptionOther">Other</mat-checkbox>
          <!--PolicyInceptionOtherDesc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PolicyInceptionOtherDesc" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12 mb-2">
          <h6>(In order to obtain retroactive coverage, you must provide copies of all prior policies for the corresponding time period.)</h6>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>7. Limits of Liability and Deductible requested</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--PerStorageTankIncidentAmount-->
          <mat-label>Per Storage Tank Incident</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PerStorageTankIncidentAmount" placeholder="" mask="separator.2" thousandSeparator="," />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--DeductibleAmount-->
          <mat-label>Deductible (per Storage Tank Incident)</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DeductibleAmount" placeholder="" mask="separator.2" thousandSeparator="," />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--AggregateAmount-->
          <mat-label>Aggregate</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="AggregateAmount" placeholder="" mask="separator.2" thousandSeparator="," />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--DefenseExpenseLimit-->
          <mat-label>Aggregate Legal Defense Expense Limit</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="DefenseExpenseLimit" placeholder="" mask="separator.2" thousandSeparator="," />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_08-->
          <mat-checkbox formControlName="Ans_08">8. The Applicant or other party to the proposed insurance’s storage tanks were new at the time of installation</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_09-->
          <mat-checkbox formControlName="Ans_09">9. The Applicant or other party to the proposed insurance’s had their storage tanks installed prior to 1975</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_10-->
          <mat-checkbox formControlName="Ans_10">10. The Applicant or any other party to the proposed insurance’s storage tanks are located within one (1) mile of a body of water? (If 'Yes', please complete the Marina Questionnaire form as attached to this application.)</mat-checkbox>
        </div>
        <div class="col-md-12">
          <button mat-raised-button type="button" color="primary" class="my-5" routerLink="/dashboard/exposures/storage-tank-marina-questionnaire">Marina Questionnaire</button>
        </div>
        <div class="col-md-12">
          <!--Ans_11-->
          <mat-checkbox formControlName="Ans_11">11. The Applicant or any other party to the proposed insurance’s facilities are located in the State of Florida</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_12-->
          <mat-checkbox formControlName="Ans_12"> 12. Single-Walled Storage Tanks (i.e., Bare Steel Tanks, Steel Tanks with Cathodic Protection, STIP ¾ Tanks or Tanks operating under ACT 100), with or without any form of tank lining are located at the Applicant’s or any other party to the proposed insurance’s facilities in the State of Florida (Only applicable if Question 9. is answered 'Yes').</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_13-->
          <mat-checkbox formControlName="Ans_13">13. Within the past five (5) years, the Applicant purchased this type of insurance coverage (If 'Yes', please provide information regarding any such coverage and all available loss information.)</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_14-->
          <mat-checkbox formControlName="Ans_14">14. There are currently, or have historically been, any hazardous, toxic, or regulated substances stored at any of the locations for which this application for insurance is being made other than these products: Gasoline, Diesel Fuel, Motor Oil, Fuel Oil, or Kerosene.</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_14_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_14_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_15-->
          <mat-checkbox formControlName="Ans_15">15. Tanks have been removed or closed and placed at the location(s) where the scheduled tanks are currently located</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_15_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_15_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_15_a-->
          <mat-checkbox formControlName="Ans_15_a">a. Scheduled storage tank(s) will be removed, closed or upgraded at the facilities for which coverage is sought under this policy within the next eighteen (18) months</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_16-->
          <mat-checkbox formControlName="Ans_16">16. The Applicant and any other parties to the proposed insurance maintains a Spill Prevention and Counter Control Plan with regard to any above ground tanks for which coverage is sought</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_16_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_16_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_17-->
          <mat-checkbox formControlName="Ans_17">17. Within the past five (5) years, there have been reportable spills of regulated substances, hazardous waste or any other pollutants, as defined by applicable environmental statutes or regulations, at the facility(ies) where the tanks the Applicant is seeking coverage for are located</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_17_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_17_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_18-->
          <mat-checkbox formControlName="Ans_18">18. Within the past ten (10) years, there have been repairs or upgrades performed on tanks</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_18_a-->
          <mat-checkbox formControlName="Ans_18_a">a. All underground storage tanks are compliant with 1998 regulations</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_18_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_18_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_19-->
          <mat-checkbox formControlName="Ans_19">19. Within the past five (5) years There have been claims been made or legal actions (including any regulatory proceedings) brought against the Applicant and/or any other party to the proposed insurance</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_19_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_19_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_20-->
          <mat-checkbox formControlName="Ans_20">20. The Applicant and/or any other party to the proposed insurance has knowledge of pollution conditions at any of the proposed covered locations</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_20_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_20_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_21-->
          <mat-checkbox formControlName="Ans_21">21. At the time of signing this application, the Applicant and/or any other party to the proposed insurance is aware of any circumstances that may reasonably be expected to give rise to a claim against any party to the proposed insurance.</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_21_Desc-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Ans_21_Desc" placeholder="" />
            <mat-error>Character length for this field is 450</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <button mat-raised-button type="button" color="primary" class="my-5" routerLink="/dashboard/exposures/storage-tank-inventory-location">Add Storage Tank Inventory By Location</button>
        </div>

      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate -->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is requried</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="ustLiabilityForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
