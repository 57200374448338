//Import Modules
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

//Import Services
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';

//Import Components
import { NewVinDialogComponent } from './dialog/new-vin-dialog/new-vin-dialog.component';

@Component({
  selector: 'app-vin',
  templateUrl: './vin.component.html',
  styleUrls: ['./vin.component.scss']
})
export class VinComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public employeeConcentration: FormGroup;
  public displayedColumns: string[] = ["ClassDescription", "ClassCode", "Payroll", "FTEmpl", "PTEmpl", "ClassSeasonal", "Volunteer"];
  public dataSourceData = [{ ClassDescription: "Animal Shelter/ Animal Control", ClassCode: "9726", Payroll: "10.00", FTEmpl: "0", PTEmpl: "0", Volunteer: "0" }, { ClassDescription: "Waterworks", ClassCode: "4726", Payroll: "0.00", FTEmpl: "0", PTEmpl: "0", Volunteer: "0" }];
  public dataSource = new MatTableDataSource(this.dataSourceData);

  ngOnInit() {
    this.dataSource.sort = this.sort;
  };

}
