<div class="payroll-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Payroll</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" [disabled]="!activeMunicipality" (click)="openNewDialog()">Add New Payroll</button>
          <button mat-raised-button type="button" color="primary" class="mb-2 ml-2" [disabled]="!activeMunicipality" (click)="exportExcel()">Export to Excel</button>
        </div>
      </div>
      <!-- Filter -->
      <div class="col-md-4">
        <mat-form-field class="field-full-width">
          <input matInput type="text" (keyup)="filterTable($event.target.value)" placeholder="Filter results">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table class="datatable" mat-table [dataSource]="dataSource" matSort matSortActive="classificationDescription" matSortDirection="asc" [@.disabled]="true">
          <!-- ClassDescription -->
          <ng-container matColumnDef="classificationDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Description </th>
            <td mat-cell *matCellDef="let element"> {{element.classificationDescription}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- ClassCode -->
          <ng-container matColumnDef="classCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Code </th>
            <td mat-cell *matCellDef="let element"> {{element.classCode}} </td>
            <td mat-footer-cell *matFooterCellDef> Payroll Totals</td>
          </ng-container>
          <!-- Payroll -->
          <ng-container matColumnDef="payroll">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Payroll </th>
            <td mat-cell *matCellDef="let element"> {{element.payroll | currency}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateTotalPayroll() | currency}} </td>
          </ng-container>
          <!-- FTEmpl -->
          <ng-container matColumnDef="fullTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FTEmpl </th>
            <td mat-cell *matCellDef="let element"> {{element.fullTime}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateFullTime() | currency}} </td>
          </ng-container>
          <!-- PTEmpl -->
          <ng-container matColumnDef="partTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PTEmpl </th>
            <td mat-cell *matCellDef="let element"> {{element.partTime}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculatePartTime() | currency}} </td>
          </ng-container>
          <!-- ClassSeasonal -->
          <ng-container matColumnDef="seasonal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Seasonal </th>
            <td mat-cell *matCellDef="let element"> {{element.seasonal}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateSeasonal() | currency}} </td>
          </ng-container>
          <!-- Volunteer -->
          <ng-container matColumnDef="volunteer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Volunteer </th>
            <td mat-cell *matCellDef="let element"> {{element.volunteer}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateVolunteer() | currency}} </td>
          </ng-container>
          <!-- Action -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openUpdateDialog(element)">mode_edit</mat-icon>
              <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteDialog(element)">delete</mat-icon>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
