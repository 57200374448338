<div class="site-environmental-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Site Environmental Liability</h3>
          <span><b>IMPORTANT</b> * Indicates Mandatory Field</span>
          <br />
          <span><b>NOTICE TO APPLICANT</b> The coverage applied for is solely as stated in the policy and any endorsement thereto. The policy provides liability coverage on a CLAIMS-MADE AND REPORTED basis, which covers only claims first made against an insured and reported to the Insurer, in writing, during the policy period. The policy also provides coverage for remediation costs on a DISCOVERED AND REPORTED basis, which covers only pollution conditions first discovered and reported to the Insurer, in writing, during the policy period.</span>
        </div>
      </div>
    </div>
    <form [formGroup]="siteEnvironmentalLiability" (ngSubmit)="submitForm(siteEnvironmentalLiability)">
      <div class="row">
        <div class="col-md-12">
          <!--Applicant_Name-->
          <mat-label>1. Name of Applicant <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant_Name" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Name'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>Public Entity Joint Insurance Fund</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <!--Applicant_Principal_Contact-->
          <mat-label>Principal Contact <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant_Principal_Contact" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Principal_Contact'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!--Applicant_Email-->
          <mat-label>E-mail Address <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant_Email" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Email'].hasError('required')">This field is requried</mat-error>
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Email'].hasError('email')">Must be a valid email address.</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!--Applicant_Principal_Contact2-->
          <mat-label>Principal Contact (Regarding Mold, Asbestos and Lead Health & Safety Issues) <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant_Principal_Contact2" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Principal_Contact2'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Applicant_Mailing_Address-->
          <mat-label>Mailing Address <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant_Mailing_Address" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Mailing_Address'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 250</mat-error>
          </mat-form-field>
        </div>
        <!--Applicant_Telephone-->
        <div class="col-md-6">
          <mat-label>Telephone <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant_Telephone" placeholder="" mask="(000) 000-0000" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Telephone'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 20</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!--Applicant_Fax-->
          <mat-label>Fax <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Applicant_Fax" placeholder="" mask="(000) 000-0000" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Applicant_Fax'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 20</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>2. Risk Class to be covered under this policy</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <!--IsAboveGround-->
              <mat-checkbox formControlName="IsAboveGround">Above Ground Storage Tanks</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsAirports-->
              <mat-checkbox formControlName="IsAirports">Airports</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsBusDepots-->
              <mat-checkbox formControlName="IsBusDepots">Bus Depots</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsEducationalFacilities-->
              <mat-checkbox formControlName="IsEducationalFacilities">Educational Facilities</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsElectricUtility-->
              <mat-checkbox formControlName="IsElectricUtility">Electric Utility</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsGasUtility-->
              <mat-checkbox formControlName="IsGasUtility">Gas Utility</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsGolfCourses-->
              <mat-checkbox formControlName="IsGolfCourses">Golf Courses</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsHazardousWasteFacilities-->
              <mat-checkbox formControlName="IsHazardousWasteFacilities">Hazardous Waste Facilities</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsHealthClinics-->
              <mat-checkbox formControlName="IsHealthClinics">Health Clinics</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsHospitals-->
              <mat-checkbox formControlName="IsHospitals">Hospitals</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsHousingAuthorities-->
              <mat-checkbox formControlName="IsHousingAuthorities">Housing Authorities</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsIrrigationDistricts-->
              <mat-checkbox formControlName="IsIrrigationDistricts">Irrigation Districts</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <!--IsMunicipalGarages-->
              <mat-checkbox formControlName="IsMunicipalGarages">Municipal Garages</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsLandfills-->
              <mat-checkbox formControlName="IsLandfills">Landfills</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsNursingHomes-->
              <mat-checkbox formControlName="IsNursingHomes">Nursing Homes/Assisted Living Communities</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsReclaimedWaterSales-->
              <mat-checkbox formControlName="IsReclaimedWaterSales">Reclaimed Water Sales/Use</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsRecyclingFacilities-->
              <mat-checkbox formControlName="IsRecyclingFacilities">Recycling Facilities (non-hazardous)</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsServiceWork-->
              <mat-checkbox formControlName="IsServiceWork">Service Work (outside of covered locations)</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsSewageDistricts-->
              <mat-checkbox formControlName="IsSewageDistricts">Sewage Districts</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsSprayingOperation-->
              <mat-checkbox formControlName="IsSprayingOperation">Spraying Operation (weed/pesticide)</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsUndergroundStorage-->
              <mat-checkbox formControlName="IsUndergroundStorage">Underground Storage Tanks</mat-checkbox>
            </div>
            <!--IsWastewaterTreatmentFacilities-->
            <div class="col-md-12">
              <mat-checkbox formControlName="IsWastewaterTreatmentFacilities">Wastewater Treatment Facilities</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsWaterDistricts-->
              <mat-checkbox formControlName="IsWaterDistricts">Water Districts</mat-checkbox>
            </div>
            <div class="col-md-12">
              <!--IsWaterTreatmentFacilities-->
              <mat-checkbox formControlName="IsWaterTreatmentFacilities">Water Treatment Facilities</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!--OtherFacilityTypes-->
          <mat-label>List other Facility Types Here <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="OtherFacilityTypes" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['OtherFacilityTypes'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 150</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Population-->
          <mat-label>Population (Municipalities) <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Population" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Population'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Enrollment-->
          <mat-label>Enrollment (School Districts)</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Enrollment" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--DesiredEffectiveDate-->
          <mat-label>Desired effective date of coverage</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="DesiredEffectiveDate" [matDatepicker]="DesiredEffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="DesiredEffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #DesiredEffectiveDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>6. Limits of Liability and Self-Insured Retention requested:</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--Limits_Liability_Per_Population_Condition-->
          <mat-label>Per Pollution Condition <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Limits_Liability_Per_Population_Condition" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Limits_Liability_Per_Population_Condition'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <!--Limits_Liability_Aggregate-->
        <div class="col-md-4">
          <mat-label>Member Aggregate <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Limits_Liability_Aggregate" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Limits_Liability_Per_Population_Condition'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <!--Self_Insured_Retention_Per_Pollution_Condition-->
        <div class="col-md-4">
          <mat-label>Self Insured Retention <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Self_Insured_Retention_Per_Pollution_Condition" placeholder="" />
            <mat-error *ngIf="siteEnvironmentalLiability.controls['Self_Insured_Retention_Per_Pollution_Condition'].hasError('required')">This field is requried</mat-error>
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_7-->
          <mat-checkbox formControlName="Ans_7">7. Within the past five (5) years has the applicant or any other party to this insurance purchased this type of insurance coverage?):</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_7-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information regarding any such coverage and all available loss information as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_7" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_8-->
          <mat-checkbox formControlName="Ans_8">8. Within the past five (5) years have any claims been made or legal actions (including any regulatory proceedings) been brought against the applicant or any other party to the proposed insurance?:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_8-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information regarding any such coverage and all available loss information as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_8" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_9-->
          <mat-checkbox formControlName="Ans_9">9. Does the applicant or any other party to the proposed insurance have knowledge of any pollution conditions at any of the proposed covered locations?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_9-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_9" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_10-->
          <mat-checkbox formControlName="Ans_10">10. Does the applicant or any other party to the proposed insurance have knowledge of injury to people or damage to property during the last five (5) years on or at projects where the applicant performed covered operations?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_10-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_10" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_11-->
          <mat-checkbox formControlName="Ans_11">11.Does the applicant or any other party to the proposed insurance have knowledge of any claims made or pollution conditions during the last five (5) years resulting from the transportation of the applicant’s or any other party’s waste, goods or products?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_11-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_11" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_12-->
          <mat-checkbox formControlName="Ans_12">12. Does the applicant or any other party to the proposed insurance have knowledge of any claims made with respect to pollution conditions on, at, under or migrating from any disposal sites to which the applicant’s or any other party’s waste is currently being, or has historically been, taken for recycling or disposal?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_12-->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_12" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_13-->
          <mat-checkbox formControlName="Ans_13">13. At the time of signing this application, is the applicant or any other party to the proposed insurance aware of any circumstances that may reasonably be expected to give rise to a claim against the applicant or any other party to the proposed insurance from the release of pollutants?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_13-->
          <mat-label>If “Yes,” is indicated with respect to questions 8., 9., 10., 11., 12., and/or 13., above, please provide a detailed description of the claim or circumstance (indicate the alleged incident, location, date, type of injury, etc.). Also, please provide a summary of any steps that may have been taken to avoid or mitigate the possibility of a similar loss occurring in the future.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_13" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>Supplemental Information for Storage Tank Coverage</h5>
          <h5>If you are seeking coverage for pollution conditions emanating from storage tanks, please complete the following.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--IsSTCApplicable-->
          <mat-checkbox formControlName="IsSTCApplicable">If you are not, please confirm that the items below are not applicable by checking here:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_14-->
          <mat-checkbox formControlName="Ans_14">The 14. Are all of the storage tanks to be covered pursuant to this insurance (hereinafter Storage Tanks) compliant with all applicable federal, state and local laws and regulations? field is required.</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_14-->
          <mat-label>a. If “No” is indicated above, please provide a written explanation of outstanding compliance issues as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_14" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_15-->
          <mat-checkbox formControlName="Ans_15">15. Are any of the Storage Tanks located within the State of Florida?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_16-->
          <mat-checkbox formControlName="Ans_16">The 16. If the applicant answered “Yes” to Question 15., above, are any of the Florida-based Storage Tanks single-walled storage tanks (i.e., bare steel tanks, steel tanks with cathodic protection, STIP ¾ tanks or tanks operating under ACT 100), regardless of whether such single-walled storage tanks have any form of tank lining? field is required.</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_17-->
          <mat-checkbox formControlName="Ans_17">17. Have any other storage tanks been removed or closed-in-place in the locations where the Storage Tanks are currently situated?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_17-->
          <mat-label>The field a. If “Yes” is indicated above, please provide detailed information identifying the specific storage tanks to be covered pursuant to this insurance, which are situated at the common location. must be a string or array type with a maximum length of '200'.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_17" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_18-->
          <mat-checkbox formControlName="Ans_18">The 18. Will any of the applicant’s or other parties” storage tanks to be covered pursuant to this insurance be removed, closed or upgraded within the next eighteen (18) months? field is required.</mat-checkbox>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>Supplemental Information for Lead-Based Paint and Asbestos Coverage <br /> If you are seeking coverage for liability arising out of bodily injury or property damage resulting from exposure to Lead-Based Paint and/or Asbestos, complete the following.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--IsLBPACApplicable-->
          <mat-checkbox formControlName="IsLBPACApplicable">The If you are not, please confirm that the items below are not applicable by checking here: field is required.</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_19-->
          <mat-checkbox formControlName="Ans_19">19. Do any of the buildings located at the proposed covered locations contain lead-based paint?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_20-->
          <mat-checkbox formControlName="Ans_20">20. If the applicant answered “Yes” to Question 19., above, does the applicant or any other relevant party to the proposed insurance have a lead-based paint management plan in place to address the asbestos?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_20-->
          <mat-label>The field a. If “Yes” is indicated above, please provide a copy of any such plan(s) as an attachment to this application. must be a string or array type with a maximum length of '200'.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_20" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_21-->
          <mat-checkbox formControlName="Ans_21">21. Do any of the buildings located at the proposed covered locations contain asbestos or asbestos-containing materials (ACM)?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_22-->
          <mat-checkbox formControlName="Ans_22">22. If the applicant answered “Yes” to Question 21., above, does the applicant or any other relevant party to the proposed insurance have an asbestos management plan in place to address the asbestos?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_22-->
          <mat-label>a. If “Yes” is indicated above, please provide a copy of any such plan(s) as an attachment to this application</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_22" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_23-->
          <mat-checkbox formControlName="Ans_23">23. Have any health concerns been raised, or any claims been made, with respect to the presence of lead-based paint, asbestos or asbestos containing materials at any of the buildings located at the proposed covered locations?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_23-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information regarding the mold, fungi, legionella pneumophila or similar bacteria related problems as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_23" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>IT IS UNDERSTOOD AND AGREED THAT IF ANY SUCH CLAIMS EXIST, OR ANY SUCH FACTS OR CIRCUMSTANCES EXIST WHICH COULD GIVE RISE TO A CLAIM, THEN THOSE CLAIMS AND ANY OTHER CLAIMS ARISING FROM SUCH FACTS OR CIRCUMSTANCES ARE EXCLUDED FROM THE PROPOSED INSURANCE UNLESS OTHERWISE AFFIRMATIVELY STATED IN THE POLICY.<br /> Supplemental Information for Mold, Fungi and/or Legionella Pneumophila Coverage <br /> If you are seeking coverage for Mold, Fungi and/or Legionella Pneumophila, complete the following.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--IsMFLPCApplicable-->
          <mat-checkbox formControlName="IsMFLPCApplicable">If you are not, please confirm that the items below are not applicable by checking here:</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Ans_24-->
          <mat-checkbox formControlName="Ans_24">
            24. Do the applicant and any other parties to the proposed insurance perform due diligence with respect to mold and/or fungi when acquiring or leasing property such as in accordance with ASTM Standard E2418-06 “Standard Guide for Readily Observable Mold and Conditions Conducive to Mold in Commercial Buildings: Baseline Survey Process?”
          </mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_24-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information regarding the scope of that due diligence as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_24" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_25-->
          <mat-checkbox formControlName="Ans_25">25. Have any of the buildings located at the proposed covered locations ever been identified as having mold, fungi, legionella pneumophila or similar bacteria-related problems?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_25-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information regarding the mold, fungi, legionella pneumophila or similar bacteria related problems as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_25" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_26-->
          <mat-checkbox formControlName="Ans_26">26. Have any of the buildings located at the proposed covered locations experienced any water leaks or flooding within the past five (5) years?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_26-->
          <mat-label>If “Yes” is indicated above, please provide detailed information regarding the leaks or flooding as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_26" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_27-->
          <mat-checkbox formControlName="Ans_27">27. Are any of the buildings situated at the proposed covered locations constructed using Exterior Insulation and Finish Systems (EFIS)?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_27-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information confirming the applicable locations as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_27" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_28-->
          <mat-checkbox formControlName="Ans_28">28. Do the applicant and any other parties to the proposed insurance have any mold management and/or water intrusion plans in place?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_28-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information confirming the applicable locations as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_28" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_29-->
          <mat-checkbox formControlName="Ans_29">29. Do employees or members of the applicant and any other parties to the proposed insurance receive any training regarding the handling of mold, fungi or legionella pneumophila or similar bacteria-related issues?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_29-->
          <mat-label>a. If “Yes” is indicated above, please provide detailed information confirming the applicable locations as an attachment to this application.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_29" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--Ans_30-->
          <mat-checkbox formControlName="Ans_30">30. Have any health concerns been identified by, or any claims been made against, the applicant or any other parties to the proposed insurance with respect to mold, legionella pneumophila, similar bacteria-related issues or any other indoor air quality-related issues at buildings located on any of the proposed covered locations?</mat-checkbox>
        </div>
        <div class="col-md-12">
          <!--Descp_30-->
          <mat-label>a. If “Yes” is indicated above, please provide a brief description of the claim or circumstance (indicate the alleged incident, location, date, type of injury, etc.). Also, please provide a summary of any steps that may have been taken to avoid or mitigate the possibility of a similar loss occurring in the future.</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Descp_30" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--EffectiveDate-->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row section-title">
        <div class="col-md-12 mb-2">
          <h5>IT IS UNDERSTOOD AND AGREED THAT IF ANY SUCH CLAIMS EXIST, OR ANY SUCH FACTS OR CIRCUMSTANCES EXIST WHICH COULD GIVE RISE TO A CLAIM, THEN THOSE CLAIMS AND ANY OTHER CLAIMS ARISING FROM SUCH FACTS OR CIRCUMSTANCES ARE EXCLUDED FROM THE PROPOSED INSURANCE UNLESS OTHERWISE AFFIRMATIVELY STATED IN THE POLICY.</h5>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="siteEnvironmentalLiability.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
