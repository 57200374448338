//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

//Import Services
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-cyber-liability3',
  templateUrl: './cyber-liability.component.html',
  styleUrls: ['./cyber-liability.component.scss']
})
export class CyberLiabilityComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.cyberLiabilityForm = new FormGroup({
      LiabilityCyberID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      AcceptCredit: new FormControl(false), //BOOL
      SecurityStandards: new FormControl(false), //BOOL
      CurrentStatus: new FormControl(''),
      ConductTraining: new FormControl(false), //BOOL
      InformationSecurity: new FormControl(false), //BOOL
      AuditSecurity: new FormControl(false), //BOOL
      AuditScope: new FormControl('', [Validators.maxLength(200)]), //STRING
      ExitProcess: new FormControl(false), //BOOL
      CommunicationEncrypted: new FormControl(false), //BOOL
      PortableMedia: new FormControl(false), //BOOL
      SoftwarePatches: new FormControl(false), //BOOL
      CriticalPatches: new FormControl(false), //BOOL
      AntiVirus: new FormControl('', [Validators.maxLength(200)]), //STRING
      UpdateFirewall: new FormControl(false), //BOOL
      KnownIntrusions: new FormControl(false), //BOOL
      DescribeDamage: new FormControl('', [Validators.maxLength(200)]), //STRING
      ProvideTraining: new FormControl(false), //BOOL
      NameAddress: new FormControl(false), //BOOL
      NameAddressDescription: new FormControl('', [Validators.maxLength(200)]), //STRING
      FinancialAccount: new FormControl(false), //BOOL
      FinancialAccountDescription: new FormControl('', [Validators.maxLength(200)]), //STRING
      LicenseNumbers: new FormControl(false), //BOOL
      LicenseNumbersDescription: new FormControl('', [Validators.maxLength(200)]), //STRING
      SocialSecurity: new FormControl(false), //BOOL
      SocialSecurityDescription: new FormControl('', [Validators.maxLength(200)]), //STRING
      ProtectedHealth: new FormControl(false), //BOOL
      ProtectedHealthDescription: new FormControl('', [Validators.maxLength(200)]), //STRING
      ElectronicallyStored: new FormControl(0, Validators.required), //INT
      PaperFormat: new FormControl(0, Validators.required), //INT
      EmployeeAccess: new FormControl(false), //BOOL
      EmployeeAccessDescription: new FormControl('', [Validators.maxLength(200)]), //STRING
      ThirdPartyPayments: new FormControl(false), //BOOL
      SponsorWifi: new FormControl(false), //BOOL
      ReviewInfringement: new FormControl(false), //BOOL
      EffectiveDate: new FormControl('', Validators.required) //STRING
    });

    //AcceptCredit
    this.cyberLiabilityForm.controls['AcceptCredit'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('SecurityStandards').enable() : this.cyberLiabilityForm.get('SecurityStandards').disable();
      value ? this.cyberLiabilityForm.get('CurrentStatus').enable() : this.cyberLiabilityForm.get('CurrentStatus').disable();
    });

    //SecurityStandards
    this.cyberLiabilityForm.controls['SecurityStandards'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('CurrentStatus').enable() : this.cyberLiabilityForm.get('CurrentStatus').disable();
    });

    //AuditSecurity
    this.cyberLiabilityForm.controls['AuditSecurity'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('AuditScope').enable() : this.cyberLiabilityForm.get('AuditScope').disable();
    });

    //KnownIntrusions
    this.cyberLiabilityForm.controls['KnownIntrusions'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('DescribeDamage').enable() : this.cyberLiabilityForm.get('DescribeDamage').disable();
    });

    //NameAddress
    this.cyberLiabilityForm.controls['NameAddress'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('NameAddressDescription').enable() : this.cyberLiabilityForm.get('NameAddressDescription').disable();
    });

    //FinancialAccount
    this.cyberLiabilityForm.controls['FinancialAccount'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('FinancialAccountDescription').enable() : this.cyberLiabilityForm.get('FinancialAccountDescription').disable();
    });

    //LicenseNumbers
    this.cyberLiabilityForm.controls['LicenseNumbers'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('LicenseNumbersDescription').enable() : this.cyberLiabilityForm.get('LicenseNumbersDescription').disable();
    });

    //SocialSecurity
    this.cyberLiabilityForm.controls['SocialSecurity'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('SocialSecurityDescription').enable() : this.cyberLiabilityForm.get('SocialSecurityDescription').disable();
    });

    //ProtectedHealth
    this.cyberLiabilityForm.controls['ProtectedHealth'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('ProtectedHealthDescription').enable() : this.cyberLiabilityForm.get('ProtectedHealthDescription').disable();
    });

    //EmployeeAccess
    this.cyberLiabilityForm.controls['EmployeeAccess'].valueChanges.subscribe(value => {
      value ? this.cyberLiabilityForm.get('EmployeeAccessDescription').enable() : this.cyberLiabilityForm.get('EmployeeAccessDescription').disable();
    });
  };

  public activeMunicipality;
  public cyberLiabilityForm: FormGroup;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.cyberLiabilityForm.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getCyberLiability(response);
      }
    });
  };

  public getCyberLiability(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetLiabilityCyber').subscribe((response: any) => {
      if (!response) {
        this.cyberLiabilityForm.patchValue({
          'LiabilityCyberID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'AcceptCredit': false,
          'SecurityStandards': false,
          'CurrentStatus': '',
          'ConductTraining': false,
          'InformationSecurity': false,
          'AuditSecurity': false,
          'AuditScope': '',
          'ExitProcess': false,
          'CommunicationEncrypted': false,
          'PortableMedia': false,
          'SoftwarePatches': false,
          'CriticalPatches': false,
          'AntiVirus': '',
          'UpdateFirewall': false,
          'KnownIntrusions': false,
          'DescribeDamage': '',
          'ProvideTraining': false,
          'NameAddress': false,
          'NameAddressDescription': '',
          'FinancialAccount': false,
          'FinancialAccountDescription': '',
          'LicenseNumbers': false,
          'LicenseNumbersDescription': '',
          'SocialSecurity': false,
          'SocialSecurityDescription': '',
          'ProtectedHealth': false,
          'ProtectedHealthDescription': '',
          'ElectronicallyStored': 0,
          'PaperFormat': 0,
          'EmployeeAccess': false,
          'EmployeeAccessDescription': '',
          'ThirdPartyPayments': false,
          'SponsorWifi': false,
          'ReviewInfringement': false,
          'EffectiveDate': ''
        })
      }
      else {
        this.cyberLiabilityForm.patchValue({
          'LiabilityCyberID': response.liabilityCyberID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'AcceptCredit': response.acceptCredit,
          'SecurityStandards': response.securityStandards,
          'CurrentStatus': response.currentStatus,
          'ConductTraining': response.conductTraining,
          'InformationSecurity': response.informationSecurity,
          'AuditSecurity': response.auditSecurity,
          'AuditScope': response.auditScope,
          'ExitProcess': response.exitProcess,
          'CommunicationEncrypted': response.communicationEncrypted,
          'PortableMedia': response.portableMedia,
          'SoftwarePatches': response.softwarePatches,
          'CriticalPatches': response.criticalPatches,
          'AntiVirus': response.antiVirus,
          'UpdateFirewall': response.updateFirewall,
          'KnownIntrusions': response.knownIntrusions,
          'DescribeDamage': response.describeDamage,
          'ProvideTraining': response.provideTraining,
          'NameAddress': response.nameAddress,
          'NameAddressDescription': response.nameAddressDescription,
          'FinancialAccount': response.financialAccount,
          'FinancialAccountDescription': response.financialAccountDescription,
          'LicenseNumbers': response.licenseNumbers,
          'LicenseNumbersDescription': response.licenseNumbersDescription,
          'SocialSecurity': response.socialSecurity,
          'SocialSecurityDescription': response.socialSecurityDescription,
          'ProtectedHealth': response.protectedHealth,
          'ProtectedHealthDescription': response.protectedHealthDescription,
          'ElectronicallyStored': response.electronicallyStored,
          'PaperFormat': response.paperFormat,
          'EmployeeAccess': response.employeeAccess,
          'EmployeeAccessDescription': response.employeeAccessDescription,
          'ThirdPartyPayments': response.thirdPartyPayments,
          'SponsorWifi': response.sponsorWifi,
          'ReviewInfringement': response.reviewInfringement,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.LiabilityCyberID == 0) {
      this.exposuresService.add(form.value, 'AddLiabilityCyber').subscribe((response: any) => {
        if (response.error == 0) {
          this.getCyberLiability(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateLiabilityCyber').subscribe((response: any) => {
        if (response.error == 0) {
          this.getCyberLiability(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
