<div class="property-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Property Information</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="propertyForm" (ngSubmit)="submitForm(propertyForm)">
      <!--PropertyAppr3Yr-->
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="PropertyAppr3Yr">Has a property appraisal been conducted in the last three years?</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-label>Date of Last Appraisal(s)</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="PropertyAppraisal" [matDatepicker]="PropertyAppraisal">
            <mat-datepicker-toggle matSuffix [for]="PropertyAppraisal"></mat-datepicker-toggle>
            <mat-datepicker #PropertyAppraisal></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="propertyForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
