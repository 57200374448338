<div class="change-password-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Change Password</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="changePasswordForm" (ngSubmit)="submitChangePasswordForm(changePasswordForm)">
      <div class="row">
        <div class="col-md-6">
          <mat-label>Current password <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="password" matInput formControlName="CurrentPassword" placeholder="" />
            <mat-error *ngIf="changePasswordForm.controls['CurrentPassword'].hasError('required')"> This field is Required!</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-label>New password <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="password" matInput formControlName="Password" placeholder="" />
            <mat-error *ngIf="changePasswordForm.controls['Password'].hasError('required')">This field is Required!</mat-error>
            <mat-error *ngIf="changePasswordForm.controls['Password'].hasError('minlength')">Must be at least 6 characters</mat-error>
            <mat-error *ngIf="changePasswordForm.controls['Password'].hasError('hasCapitalCase')">Must contain at least 1 in Capital Case</mat-error>
            <mat-error *ngIf="changePasswordForm.controls['Password'].hasError('hasSmallCase')">Must contain at least 1 Letter in Small Case</mat-error>
            <mat-error *ngIf="changePasswordForm.controls['Password'].hasError('hasSpecialCharacter')">Must contain at least 1 special character</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-label>Confirm password <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="password" matInput formControlName="ConfirmPassword" placeholder="" />
            <mat-error *ngIf="changePasswordForm.controls['ConfirmPassword'].hasError('required')"> Password is Required!</mat-error>
            <mat-error *ngIf="changePasswordForm.controls['ConfirmPassword'].hasError('NoPassswordMatch')">Password do not match</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="primary" class="submit-btn">Change password</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
