//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { MunicipalitiesService } from '../../../../../shared/services/municipalities/municipalities.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-update-vehicle-dialog',
  templateUrl: './update-vehicle-dialog.component.html',
  styleUrls: ['./update-vehicle-dialog.component.scss']
})
export class UpdateVehicleDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdateVehicleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private municipalitiesService: MunicipalitiesService, private notifier: NotifierService) {
    this.updateVehicleForm = new FormGroup({
      DepartmentID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      ScheduleVehicles_yearId: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      VehicleID: new FormControl(0), //INT
      VehicleYear: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]), //STRING REQUIRED
      Make: new FormControl('', [Validators.required, Validators.maxLength(150)]), //STRING REQUIRED
      Model: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING REQUIRED
      VehicleDescription: new FormControl('', Validators.maxLength(100)), //STRING
      VehicleNumber: new FormControl('', Validators.maxLength(50)), //STRING
      VIN: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING REQUIRED
      PlateNumber: new FormControl('', Validators.maxLength(50)), //STRING
      CostNew: new FormControl(0), //INT
      ClassCode: new FormControl('', Validators.maxLength(50)), //STRING
      CovOver: new FormControl(false), //BOOL
      GrossWeight: new FormControl(0), //INT
      CompColl: new FormControl(false), //BOOL
      GarageLocation: new FormControl('', Validators.maxLength(100)), //STRING
      Leased: new FormControl(false), //BOOL
      LeasingCompany: new FormControl('', Validators.maxLength(150)), //STRING
      LeaseAddIns: new FormControl(false), //BOOL
      LeaseLossPayee: new FormControl(false), //BOOL
      Financed: new FormControl(false), //BOOL
      FinanceCompany: new FormControl('', Validators.maxLength(150)), //STRING
      FinanceLossPayee: new FormControl(false), //BOOL
      Comments: new FormControl('', Validators.maxLength(250)), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public departments;
  public updateVehicleForm: FormGroup;

  ngOnInit() {
    this.getDepartmentsMunicipalitiesService();
    console.log(this.data);
    this.updateVehicleForm.patchValue({
      'VehicleID': this.data.vehicleID,
      'MunicipalityYear': this.data.municipalityYear,
      'MunicipalityID': this.data.municipalityID,
      'ScheduleVehicles_yearId': this.data.scheduleVehicles_yearId,
      'DepartmentID': this.data.departmentID,
      'VehicleYear': this.data.vehicleYear,
      'Make': this.data.make,
      'Model': this.data.model,
      'VehicleDescription': this.data.vehicleDescription,
      'VehicleNumber': this.data.vehicleNumber,
      'VIN': this.data.vin,
      'PlateNumber': this.data.plateNumber,
      'CostNew': this.data.costNew,
      'ClassCode': this.data.classCode,
      'CovOver': this.data.covOver,
      'GrossWeight': this.data.grossWeight,
      'CompColl': this.data.compColl,
      'GarageLocation': this.data.garageLocation,
      'Leased': this.data.leased,
      'LeasingCompany': this.data.leasingCompany,
      'LeaseAddIns': this.data.leaseAddIns,
      'LeaseLossPayee': this.data.leaseLossPayee,
      'Financed': this.data.financed,
      'FinanceCompany': this.data.financeCompany,
      'FinanceLossPayee': this.data.financeLossPayee,
      'Comments': this.data.comments,
      'EffectiveDate': this.data.effectiveDate
    });
  };

  //Get department id, populates the dropdown field
  getDepartmentsMunicipalitiesService() {
    this.municipalitiesService.getDepartments().pipe(first()).subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: error => {
        this.notifier.notify('error', 'Something went wrong');
      }
    });
  };

  //Close modal and pass form data to controller
  submitUpdateVehicleForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
      this.notifier.notify('success', "Record successfully updated");
    } else {
      console.log(form);
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
