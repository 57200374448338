//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-update-tank-location',
  templateUrl: './update-tank-location.component.html',
  styleUrls: ['./update-tank-location.component.scss']
})
export class UpdateTankLocationComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdateTankLocationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.tanksForm = new FormGroup({
      StorageTankID: new FormControl(0),
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]),
      StorageTankInventoryDetailsID: new FormControl(0),
      StorageTank_yearID: new FormControl(0),
      TankNo: new FormControl('', [Validators.maxLength(50)]),
      UST_AST: new FormControl('', [Validators.maxLength(50)]),
      InstallDateYear: new FormControl('', [Validators.maxLength(50)]),
      Capacity: new FormControl('', [Validators.maxLength(50)]),
      Contents: new FormControl('', Validators.required),
      TankConstructionMaterial: new FormControl('', Validators.required),
      OverfillSpillProtection: new FormControl('', Validators.required),
      TankLeakDetection: new FormControl('', Validators.required),
      ASTDiking: new FormControl('', Validators.required),
      PipingConstruction: new FormControl('', Validators.required),
      PipingLeakDetection: new FormControl('', Validators.required),
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public tanksForm: FormGroup;

  ngOnInit() {
    console.log(this.data);
    this.tanksForm.patchValue({
      'StorageTankID': this.data.storageTankID,
      'StorageTank_yearID': this.data.storageTank_yearID,
      'MunicipalityID': this.data.municipalityID,
      'MunicipalityYear': this.data.municipalityYear,
      'StorageTankInventoryDetailsID': this.data.storageTankInventoryDetailsID,
      'TankNo': this.data.tankNo,
      'UST_AST': this.data.usT_AST,
      'InstallDateYear': this.data.installDateYear,
      'Capacity': this.data.capacity,
      'Contents': this.data.contents,
      'TankConstructionMaterial': this.data.tankConstructionMaterial,
      'OverfillSpillProtection': this.data.overfillSpillProtection,
      'TankLeakDetection': this.data.tankLeakDetection,
      'ASTDiking': this.data.astDiking,
      'PipingConstruction': this.data.pipingConstruction,
      'PipingLeakDetection': this.data.pipingLeakDetection,
      'EffectiveDate': this.data.effectiveDate
    });
  };

  //Close modal and pass form data to controller
  submitTank(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };
};
