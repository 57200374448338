//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAccount, ILoginAccount, ILoginAccountTwoFactor } from '../../classes/account.class';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isTokenExpired: boolean = false;
  constructor(private http: HttpClient, private router: Router) {
    this.accountSubject = new BehaviorSubject<IAccount>(JSON.parse(localStorage.getItem('account')));
    this.account = this.accountSubject.asObservable();
  };

  private accountSubject: BehaviorSubject<IAccount>;
  public account: Observable<IAccount>;

  public get activeAccount(): IAccount {
    return this.accountSubject.value;
  };

  public accountLogin(data: ILoginAccount) {
    return this.http.post<IAccount>('/api/Auth/Login', data).pipe(map(account => {
      localStorage.setItem('account', JSON.stringify(account));
      this.accountSubject.next(account);
      return account;
    }));
  };

  public accountAuthTwoFactorLogin(data: ILoginAccountTwoFactor) {
    return this.http.post<IAccount>('/api/Auth/LoginTwoStep', data).pipe(map(account => {
      localStorage.setItem('account', JSON.stringify(account));
      this.accountSubject.next(account);
      return account;
    }));
  };

  public accountLogout() {
    return this.http.post('/api/Auth/Logout', {}).pipe(map(account => {
      localStorage.removeItem('account');
      localStorage.removeItem('activeMunicipality');
      this.accountSubject.next(null);
      return;
    }));
  };


  getToken(): string | null {
    const account = localStorage.getItem('account');
    if (account) {
      const accountJson = JSON.parse(account);
      return accountJson.token;
    }
    return null;
  }

  alertAndLogout() {
    this.isTokenExpired = true;
    alert('Your session has expired. Please log in again.');

    this.accountLogout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

};
