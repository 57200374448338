//Import Modules
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

//Import Services
import { AuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
export class AccessGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.authService.activeAccount;
    if (route.data.role && route.data.role.indexOf(account.role) === -1) {
      return false;
    } else {
      return true;
    }
  };
};
