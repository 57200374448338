//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AccountService } from '../service/account.service';
import { NotifierService } from "angular-notifier";
import { PasswordValidators } from '../../shared/pipes/password-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  constructor(public accountService: AccountService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private notifier: NotifierService) {
    this.resetPasswordForm = fb.group({
      UserId: new FormControl('', Validators.required), //STRING
      Password: ['', Validators.compose([Validators.required, PasswordValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }), PasswordValidators.patternValidator(/[a-z]/, { hasSmallCase: true }), PasswordValidators.patternValidator(/[!@#$%^&*]/, { hasSpecialCharacter: true }), Validators.minLength(6)])], //STRING
      ConfirmPassword: ['', Validators.compose([Validators.required])], //STRING
      Token: new FormControl('', Validators.required) //STRING
    },
      {
        validator: PasswordValidators.passwordMatchValidator
      })
  };

  public resetPasswordForm: FormGroup;

  ngOnInit() {
    this.extractUrlParams();
  };

  //Retrive user information from url string & patch the form
  extractUrlParams() {
    this.resetPasswordForm.controls.UserId.setValue(this.route.snapshot.queryParams.UserId);
    this.resetPasswordForm.controls.Token.setValue(this.route.snapshot.queryParams.token);
  };

  //Submit reset password form
  submitResetPasswordForm(form) {
    if (form.valid) {
      this.accountService.accountResetPassword(form.value).pipe(first()).subscribe({
        next: (response: any) => {
          if (response.error === 0) {
            this.router.navigateByUrl('/login');
          } else {
            this.notifier.notify('error', response.message[0].description);
          }
        },
        error: error => {
          this.notifier.notify('error', 'Something went wrong');
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
