<div class="risk-managers-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Risk Managers</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" (click)="openNewRiskManagerDialog()">Register New Risk Manager</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table mat-table class="datatable" [dataSource]="dataSource" matSort matSortActive="lastName" matSortDirection="asc" [@.disabled]="true">
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
          </ng-container>
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
          </ng-container>
          <ng-container matColumnDef="address1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address 1 </th>
            <td mat-cell *matCellDef="let element"> {{element.address1}} </td>
          </ng-container>
          <ng-container matColumnDef="address2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address 2 </th>
            <td mat-cell *matCellDef="let element"> {{element.address2}} </td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
            <td mat-cell *matCellDef="let element"> {{element.company}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <div style="white-space: nowrap;">
                <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openEditRiskManagerDialog(element)">mode_edit</mat-icon>
                <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteAccountDialog(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
