//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

//Import Services
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-site-environmental-liability',
  templateUrl: './site-environmental-liability.component.html',
  styleUrls: ['./site-environmental-liability.component.scss']
})
export class SiteEnvironmentalLiabilityComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) {
    this.siteEnvironmentalLiability = new FormGroup({
      LiabilitySiteEnvironmental_yearId: new FormControl(0), //INT
      LiabilitySiteEnvironmentalID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Applicant_Name: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      Applicant_Principal_Contact: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      Applicant_Principal_Contact2: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      Applicant_Email: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.email]), //STRING
      Applicant_Mailing_Address: new FormControl('', [Validators.required, Validators.maxLength(250)]), //STRING
      Applicant_Telephone: new FormControl('', [Validators.required, Validators.maxLength(20)]), //STRING
      Applicant_Fax: new FormControl('', [Validators.required, Validators.maxLength(20)]), //STRING
      IsAboveGround: new FormControl(false), //BOOL
      IsAirports: new FormControl(false), //BOOL
      IsBusDepots: new FormControl(false), //BOOL
      IsEducationalFacilities: new FormControl(false), //BOOL
      IsElectricUtility: new FormControl(false), //BOOL
      IsGasUtility: new FormControl(false), //BOOL
      IsGolfCourses: new FormControl(false), //BOOL
      IsHazardousWasteFacilities: new FormControl(false), //BOOL
      IsHealthClinics: new FormControl(false), //BOOL //BOOL
      IsHospitals: new FormControl(false), //BOOL
      IsHousingAuthorities: new FormControl(false), //BOOL
      IsIrrigationDistricts: new FormControl(false), //BOOL
      IsMunicipalGarages: new FormControl(false), //BOOL
      IsLandfills: new FormControl(false), //BOOL
      IsNursingHomes: new FormControl(false), //BOOL
      IsReclaimedWaterSales: new FormControl(false), //BOOL
      IsRecyclingFacilities: new FormControl(false), //BOOL
      IsServiceWork: new FormControl(false), //BOOL
      IsSewageDistricts: new FormControl(false), //BOOL
      IsSprayingOperation: new FormControl(false), //BOOL
      IsUndergroundStorage: new FormControl(false), //BOOL
      IsWastewaterTreatmentFacilities: new FormControl(false), //BOOL
      IsWaterDistricts: new FormControl(false), //BOOL
      IsWaterTreatmentFacilities: new FormControl(false), //BOOL
      Population: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      Enrollment: new FormControl('', Validators.maxLength(50)), //STRING
      DesiredEffectiveDate: new FormControl(''), //DATE
      Limits_Liability_Per_Population_Condition: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      Limits_Liability_Aggregate: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      Self_Insured_Retention_Per_Pollution_Condition: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      Ans_7: new FormControl(false), //BOOL
      Descp_7: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_8: new FormControl(false),  //BOOL
      Descp_8: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_9: new FormControl(false),  //BOOL
      Descp_9: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_10: new FormControl(false),  //BOOL
      Descp_10: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_11: new FormControl(false),  //BOOL
      Descp_11: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_12: new FormControl(false),  //BOOL
      Descp_12: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_13: new FormControl(false), //BOOL
      Descp_13: new FormControl('', Validators.maxLength(200)), //STRING
      IsSTCApplicable: new FormControl(false),  //BOOL
      Ans_14: new FormControl(false), //BOOL
      Descp_14: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_15: new FormControl(false), //BOOL
      Ans_16: new FormControl(false), //BOOL
      Ans_17: new FormControl(false), //BOOL
      Descp_17: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_18: new FormControl(false), //BOOL
      IsLBPACApplicable: new FormControl(false), //BOOL
      Ans_19: new FormControl(false), //BOOL
      Ans_20: new FormControl(false), //BOOL
      Descp_20: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_21: new FormControl(false), //BOOL
      Ans_22: new FormControl(false), //BOOL
      Descp_22: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_23: new FormControl(false), //BOOL
      Descp_23: new FormControl('', Validators.maxLength(200)), //STRING
      IsMFLPCApplicable: new FormControl(false), //BOOL
      Ans_24: new FormControl(false), //BOOL
      Descp_24: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_25: new FormControl(false), //BOOL
      Descp_25: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_26: new FormControl(false), //BOOL
      Descp_26: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_27: new FormControl(false), //BOOL
      Descp_27: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_28: new FormControl(false), //BOOL
      Descp_28: new FormControl('', Validators.maxLength(200)), //STRING
      Ans_29: new FormControl(false), //BOOL
      Descp_29: new FormControl('', Validators.maxLength(200)), //STRING
      OtherFacilityTypes: new FormControl('', [Validators.required, Validators.maxLength(150)]), //STRING
      Ans_30: new FormControl(false), //BOOL
      Descp_30: new FormControl('', Validators.maxLength(200)), //STRING
      //New Fields
      IsCities: new FormControl(false), //BOOL
      IsParks: new FormControl(false), //BOOL
      IsSchools: new FormControl(false), //BOOL
      IsSpecialDistricts: new FormControl(false), //BOOL
      IsTransitAuthorities: new FormControl(false), //BOOL
      IsUtilitiesCooperatives: new FormControl(false), //BOOL
      IsWaterFacilities: new FormControl(false), //BOOL
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });

    //Ans_7
    this.siteEnvironmentalLiability.controls['Ans_7'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_7').enable() : this.siteEnvironmentalLiability.get('Descp_7').disable()
    });

    //Ans_8
    this.siteEnvironmentalLiability.controls['Ans_8'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_8').enable() : this.siteEnvironmentalLiability.get('Descp_8').disable();
    });

    //Ans_9
    this.siteEnvironmentalLiability.controls['Ans_9'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_9').enable() : this.siteEnvironmentalLiability.get('Descp_9').disable();
    });

    //Ans_10
    this.siteEnvironmentalLiability.controls['Ans_10'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_10').enable() : this.siteEnvironmentalLiability.get('Descp_10').disable();
    });

    //Ans_11
    this.siteEnvironmentalLiability.controls['Ans_11'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_11').enable() : this.siteEnvironmentalLiability.get('Descp_11').disable();
    });

    //Ans_12
    this.siteEnvironmentalLiability.controls['Ans_12'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_12').enable() : this.siteEnvironmentalLiability.get('Descp_12').disable();
    });

    //Ans_13
    this.siteEnvironmentalLiability.controls['Ans_13'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_13').enable() : this.siteEnvironmentalLiability.get('Descp_13').disable();
    });

    //Ans_14
    this.siteEnvironmentalLiability.controls['Ans_14'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_14').enable() : this.siteEnvironmentalLiability.get('Descp_14').disable();
    });

    //Ans_17
    this.siteEnvironmentalLiability.controls['Ans_17'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_17').enable() : this.siteEnvironmentalLiability.get('Descp_17').disable();
    });

    //Ans_20
    this.siteEnvironmentalLiability.controls['Ans_20'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_20').enable() : this.siteEnvironmentalLiability.get('Descp_20').disable();
    });

    //Ans_22
    this.siteEnvironmentalLiability.controls['Ans_22'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_22').enable() : this.siteEnvironmentalLiability.get('Descp_22').disable();
    });

    //Ans_23
    this.siteEnvironmentalLiability.controls['Ans_23'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_23').enable() : this.siteEnvironmentalLiability.get('Descp_23').disable();
    });

    //Ans_24
    this.siteEnvironmentalLiability.controls['Ans_24'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_24').enable() : this.siteEnvironmentalLiability.get('Descp_24').disable();
    });

    //Ans_25
    this.siteEnvironmentalLiability.controls['Ans_25'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_25').enable() : this.siteEnvironmentalLiability.get('Descp_25').disable();
    });

    //Ans_26
    this.siteEnvironmentalLiability.controls['Ans_26'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_26').enable() : this.siteEnvironmentalLiability.get('Descp_26').disable();
    });

    //Ans_27
    this.siteEnvironmentalLiability.controls['Ans_27'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_27').enable() : this.siteEnvironmentalLiability.get('Descp_27').disable();
    });

    //Ans_28
    this.siteEnvironmentalLiability.controls['Ans_28'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_28').enable() : this.siteEnvironmentalLiability.get('Descp_28').disable();
    });

    //Ans_29
    this.siteEnvironmentalLiability.controls['Ans_29'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_29').enable() : this.siteEnvironmentalLiability.get('Descp_29').disable();
    });

    //Ans_30
    this.siteEnvironmentalLiability.controls['Ans_30'].valueChanges.subscribe(value => {
      value ? this.siteEnvironmentalLiability.get('Descp_30').enable() : this.siteEnvironmentalLiability.get('Descp_30').disable();
    });
  };

  public activeMunicipality;
  public siteEnvironmentalLiability: FormGroup;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.siteEnvironmentalLiability.disable();
        return;
      } else {
        this.activeMunicipality = response;
        this.getLiabilitySiteEnvironmental(response);
      }
    });
  };

  public getLiabilitySiteEnvironmental(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetLiabilitySiteEnvironmental').subscribe((response: any) => {
      if (!response) {
        this.siteEnvironmentalLiability.patchValue({
          'LiabilitySiteEnvironmental_yearId': 0,
          'LiabilitySiteEnvironmentalID': 0,
          'MunicipalityID': data.municipalityID,
          'MunicipalityYear': data.municipalityYear,
          'Applicant_Name': '',
          'Applicant_Principal_Contact': '',
          'Applicant_Principal_Contact2': '',
          'Applicant_Email': '',
          'Applicant_Mailing_Address': '',
          'Applicant_Telephone': '',
          'Applicant_Fax': '',
          'IsAboveGround': false,
          'IsAirports': false,
          'IsBusDepots': false,
          'IsEducationalFacilities': false,
          'IsElectricUtility': false,
          'IsGasUtility': false,
          'IsGolfCourses': false,
          'IsHazardousWasteFacilities': false,
          'IsHealthClinics': false,
          'IsHospitals': false,
          'IsHousingAuthorities': false,
          'IsIrrigationDistricts': false,
          'IsMunicipalGarages': false,
          'IsLandfills': false,
          'IsNursingHomes': false,
          'IsReclaimedWaterSales': false,
          'IsRecyclingFacilities': false,
          'IsServiceWork': false,
          'IsSewageDistricts': false,
          'IsSprayingOperation': false,
          'IsUndergroundStorage': false,
          'IsWastewaterTreatmentFacilities': false,
          'IsWaterDistricts': false,
          'IsWaterTreatmentFacilities': false,
          'Population': '',
          'Enrollment': '',
          'DesiredEffectiveDate': '',
          'Limits_Liability_Per_Population_Condition': '',
          'Limits_Liability_Aggregate': '',
          'Self_Insured_Retention_Per_Pollution_Condition': '',
          'Ans_7': false,
          'Descp_7': '',
          'Ans_8': false,
          'Descp_8': '',
          'Ans_9': false,
          'Descp_9': '',
          'Ans_10': false,
          'Descp_10': '',
          'Ans_11': false,
          'Descp_11': '',
          'Ans_12': false,
          'Descp_12': '',
          'Ans_13': false,
          'Descp_13': '',
          'IsSTCApplicable': false,
          'Ans_14': false,
          'Descp_14': '',
          'Ans_15': false,
          'Ans_16': false,
          'Ans_17': false,
          'Descp_17': '',
          'Ans_18': false,
          'IsLBPACApplicable': false,
          'Ans_19': false,
          'Ans_20': false,
          'Descp_20': '',
          'Ans_21': false,
          'Ans_22': false,
          'Descp_22': '',
          'Ans_23': false,
          'Descp_23': '',
          'IsMFLPCApplicable': false,
          'Ans_24': false,
          'Descp_24': '',
          'Ans_25': false,
          'Descp_25': '',
          'Ans_26': false,
          'Descp_26': '',
          'Ans_27': false,
          'Descp_27': '',
          'Ans_28': false,
          'Descp_28': '',
          'Ans_29': false,
          'Descp_29': '',
          'OtherFacilityTypes': '',
          'Ans_30': false,
          'Descp_30': '',
          'IsCities': false,
          'IsParks': false,
          'IsSchools': false,
          'IsSpecialDistricts': false,
          'IsTransitAuthorities': false,
          'IsUtilitiesCooperatives': false,
          'IsWaterFacilities': false,
          'EffectiveDate': ''
        });
      } else {
        this.siteEnvironmentalLiability.patchValue({
          'LiabilitySiteEnvironmental_yearId': response.liabilitySiteEnvironmental_yearId,
          'LiabilitySiteEnvironmentalID': response.liabilitySiteEnvironmentalID,
          'MunicipalityID': response.municipalityID,
          'MunicipalityYear': response.municipalityYear,
          'Applicant_Name': response.applicant_Name,
          'Applicant_Principal_Contact': response.applicant_Principal_Contact,
          'Applicant_Principal_Contact2': response.applicant_Principal_Contact2,
          'Applicant_Email': response.applicant_Email,
          'Applicant_Mailing_Address': response.applicant_Mailing_Address,
          'Applicant_Telephone': response.applicant_Telephone,
          'Applicant_Fax': response.applicant_Fax,
          'IsAboveGround': response.isAboveGround,
          'IsAirports': response.isAirports,
          'IsBusDepots': response.isBusDepots,
          'IsEducationalFacilities': response.isEducationalFacilities,
          'IsElectricUtility': response.isElectricUtility,
          'IsGasUtility': response.isGasUtility,
          'IsGolfCourses': response.isGolfCourses,
          'IsHazardousWasteFacilities': response.isHazardousWasteFacilities,
          'IsHealthClinics': response.isHealthClinics,
          'IsHospitals': response.isHospitals,
          'IsHousingAuthorities': response.isHousingAuthorities,
          'IsIrrigationDistricts': response.isIrrigationDistricts,
          'IsMunicipalGarages': response.isMunicipalGarages,
          'IsLandfills': response.isLandfills,
          'IsNursingHomes': response.isNursingHomes,
          'IsReclaimedWaterSales': response.isReclaimedWaterSales,
          'IsRecyclingFacilities': response.isRecyclingFacilities,
          'IsServiceWork': response.isServiceWork,
          'IsSewageDistricts': response.isSewageDistricts,
          'IsSprayingOperation': response.isSprayingOperation,
          'IsUndergroundStorage': response.isUndergroundStorage,
          'IsWastewaterTreatmentFacilities': response.isWastewaterTreatmentFacilities,
          'IsWaterDistricts': response.isWaterDistricts,
          'IsWaterTreatmentFacilities': response.isWaterTreatmentFacilities,
          'Population': response.population,
          'Enrollment': response.enrollment,
          'DesiredEffectiveDate': response.desiredEffectiveDate,
          'Limits_Liability_Per_Population_Condition': response.limits_Liability_Per_Population_Condition,
          'Limits_Liability_Aggregate': response.limits_Liability_Aggregate,
          'Self_Insured_Retention_Per_Pollution_Condition': response.self_Insured_Retention_Per_Pollution_Condition,
          'Ans_7': response.ans_7,
          'Descp_7': response.descp_7,
          'Ans_8': response.ans_8,
          'Descp_8': response.descp_8,
          'Ans_9': response.ans_9,
          'Descp_9': response.descp_9,
          'Ans_10': response.ans_10,
          'Descp_10': response.descp_10,
          'Ans_11': response.ans_11,
          'Descp_11': response.descp_11,
          'Ans_12': response.ans_12,
          'Descp_12': response.descp_12,
          'Ans_13': response.ans_13,
          'Descp_13': response.descp_13,
          'IsSTCApplicable': response.isSTCApplicable,
          'Ans_14': response.ans_14,
          'Descp_14': response.descp_14,
          'Ans_15': response.ans_15,
          'Ans_16': response.ans_16,
          'Ans_17': response.ans_17,
          'Descp_17': response.descp_17,
          'Ans_18': response.ans_18,
          'IsLBPACApplicable': response.isLBPACApplicable,
          'Ans_19': response.ans_19,
          'Ans_20': response.ans_20,
          'Descp_20': response.descp_20,
          'Ans_21': response.ans_21,
          'Ans_22': response.ans_22,
          'Descp_22': response.descp_22,
          'Ans_23': response.ans_23,
          'Descp_23': response.descp_23,
          'IsMFLPCApplicable': response.isMFLPCApplicable,
          'Ans_24': response.ans_24,
          'Descp_24': response.descp_24,
          'Ans_25': response.ans_25,
          'Descp_25': response.descp_25,
          'Ans_26': response.ans_26,
          'Descp_26': response.descp_26,
          'Ans_27': response.ans_27,
          'Descp_27': response.descp_27,
          'Ans_28': response.ans_28,
          'Descp_28': response.descp_28,
          'Ans_29': response.ans_29,
          'Descp_29': response.descp_29,
          'OtherFacilityTypes': response.otherFacilityTypes,
          'Ans_30': response.ans_30,
          'Descp_30': response.descp_30,
          'IsCities': response.isCities,
          'IsParks': response.isParks,
          'IsSchools': response.isSchools,
          'IsSpecialDistricts': response.isSpecialDistricts,
          'IsTransitAuthorities': response.isTransitAuthorities,
          'IsUtilitiesCooperatives': response.isUtilitiesCooperatives,
          'IsWaterFacilities': response.isWaterFacilities,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  public submitForm(form) {
    if (form.valid && form.value.LiabilitySiteEnvironmentalID == 0 && form.value.LiabilitySiteEnvironmental_yearId == 0) {
      this.exposuresService.add(form.value, 'AddLiabilitySiteEnvironmental').subscribe((response: any) => {
        if (response.error == 0) {
          this.getLiabilitySiteEnvironmental(this.activeMunicipality);
          this.notifier.notify('success', 'New record created successfully');
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateLiabilitySiteEnvironmental').subscribe((response: any) => {
        if (response.error == 0) {
          this.getLiabilitySiteEnvironmental(this.activeMunicipality);
          this.notifier.notify('success', "Record successfully updated");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };
};
