<h2 mat-dialog-title>Log Details</h2>
<div class="log-dialog">
  <mat-dialog-content class="log-dialog-content">
    <!-- Log Metadata Section -->
    <div class="log-metadata">
      <p><strong>Log ID:</strong> {{data.auditLogID}}</p>
      <p><strong>Table Name:</strong> {{data.tableName}}</p>
      <p><strong>Operation:</strong> {{data.operation}}</p>
      <p><strong>Changed By:</strong> {{data.changedBy}}</p>
      <p><strong>Changed Date:</strong> {{data.changedDate | date:'short'}}</p>
    </div>

    <!-- Differences Section -->
    <div *ngIf="differences" class="log-section">
      <h3>Differences</h3>

      <!-- Table for UPDATE operation (shows both Old and New Value columns) -->
      <table class="differences-table table table-striped"
             [ngClass]="{
               'header-insert': data.operation === 'INSERT',
               'header-update': data.operation === 'UPDATE',
               'header-delete': data.operation === 'DELETE'
             }"
             *ngIf="data.operation === 'UPDATE'">
        <thead>
          <tr>
            <th>Field</th>
            <th>Old Value</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let key of getDifferenceKeys()">
            <td>{{ key }}</td>
            <td>{{ differences[key]?.OldValue }}</td>
            <td>{{ differences[key]?.NewValue }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Table for DELETE operation (shows only the Old Value column) -->
      <table class="differences-table table table-striped"
             [ngClass]="{
               'header-insert': data.operation === 'INSERT',
               'header-update': data.operation === 'UPDATE',
               'header-delete': data.operation === 'DELETE'
             }"
             *ngIf="data.operation === 'DELETE'">
        <thead>
          <tr>
            <th>Field</th>
            <th>Old Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let key of getDifferenceKeys()">
            <td>{{ key }}</td>
            <td>{{ differences[key]?.OldValue }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Table for INSERT operation (shows only the New Value column) -->
      <table class="differences-table table table-striped"
             [ngClass]="{
               'header-insert': data.operation === 'INSERT',
               'header-update': data.operation === 'UPDATE',
               'header-delete': data.operation === 'DELETE'
             }"
             *ngIf="data.operation === 'INSERT'">
        <thead>
          <tr>
            <th>Field</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let key of getDifferenceKeys()">
            <td>{{ key }}</td>
            <td>{{ differences[key]?.NewValue }}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </mat-dialog-content>

  <!-- Fix the dialog actions -->
  <mat-dialog-actions class="log-dialog-actions">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
