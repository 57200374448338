<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>New Position Bond Worksheet</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="crimePositionBondForm" (ngSubmit)="submitCrimePositionBondForm(crimePositionBondForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Crime position bond information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--Name-->
            <mat-label>Name <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Name">
              <mat-error *ngIf="crimePositionBondForm.controls['Name'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--Position-->
            <mat-label>Position <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Position">
              <mat-error *ngIf="crimePositionBondForm.controls['Position'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--LimitRequired-->
            <mat-label>Limit Required</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="LimitRequired">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--BondCopy-->
            <mat-checkbox formControlName="BondCopy">Bond Copy</mat-checkbox>
          </div>
          <div class="col-md-2">
            <!--ApplicationCopy-->
            <mat-checkbox formControlName="ApplicationCopy">Application Copy</mat-checkbox>
          </div>
          <div class="col-md-4">
            <!--AODate-->
            <mat-label>AO Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="AODate" [matDatepicker]="AODate">
              <mat-datepicker-toggle matSuffix [for]="AODate"></mat-datepicker-toggle>
              <mat-datepicker #AODate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!--AsOf-->
            <mat-label>As of <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="AsOf" [matDatepicker]="AsOf">
              <mat-datepicker-toggle matSuffix [for]="AsOf"></mat-datepicker-toggle>
              <mat-datepicker #AsOf></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--ApplicationDate-->
            <mat-label>Application Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="ApplicationDate" [matDatepicker]="ApplicationDate">
              <mat-datepicker-toggle matSuffix [for]="ApplicationDate"></mat-datepicker-toggle>
              <mat-error>This field is requried</mat-error>
              <mat-datepicker #ApplicationDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--CurrentPolicyDuration-->
            <mat-label>Current Policy Duration <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="CurrentPolicyDuration">
              <mat-error *ngIf="crimePositionBondForm.controls['CurrentPolicyDuration'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate-->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
