//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { FormValidator } from '../../../../shared/pipes/FormValidator';

//Import Services
import { MunicipalitiesService } from '../../../../shared/services/municipalities/municipalities.service';

@Component({
  selector: 'app-add-new-munipality',
  templateUrl: './add-new-munipality.component.html',
  styleUrls: ['./add-new-munipality.component.scss']
})
export class AddNewMunipalityComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddNewMunipalityComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService, private municipalitiesService: MunicipalitiesService) {
    this.newMunicipalityForm = new FormGroup({
      MunicipalityID: new FormControl(0, Validators.required),
      RenewalApplicationID: new FormControl(0, Validators.required),
      MunicipalityYear: new FormControl(((new Date()).getFullYear().toString()), Validators.required),
      FullName: new FormControl('', [Validators.required, Validators.maxLength(150)]),
      ShortName: new FormControl('', [Validators.required]),
      Address1: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      Address2: new FormControl('', [Validators.maxLength(100)]),
      City: new FormControl('', [Validators.maxLength(50)]),
      State: new FormControl({ value: 'NJ', disabled: true }, Validators.compose([Validators.minLength(2), Validators.maxLength(2), Validators.required, FormValidator.patternValidator(/^[A-Z]*$/, { isCapital: true })])), //STRING
      Zip: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required, FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])), //STRING
      County: new FormControl('', [Validators.required]),
      ContactName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ContactTitle: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ContactPhone: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      ContactFax: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      ContactEmail: new FormControl('', [Validators.required, Validators.email]),
      RiskManagerID: new FormControl(0, [Validators.required]),
      ApplicationDate: new FormControl('', Validators.required),
      CoverageEffectiveDate: new FormControl('', Validators.required),
      CoverageEffectiveYear: new FormControl('', Validators.required),
      PublicOfficialsElected: new FormControl(0),
      PublicOfficialsAppointed: new FormControl(0),
      PopulationNormal: new FormControl(0),
      PopulationSeasonal: new FormControl(0),
      EmployeeTotal: new FormControl(0),
      GrossPayrollTotal: new FormControl(0),
      ContactorExpenditures: new FormControl(0),
      QuasiMunicipalEntities: new FormControl('', Validators.maxLength(500)),
      OperatingExpenditureEstimate: new FormControl(0),
      EffectiveDate: new FormControl('', Validators.required)
      //PropertyAppraisal: new FormControl('', Validators.required),
      //PropertyAppr3Yr: new FormControl(0),
      //TransitSystem: new FormControl(0)
     });
  };

  public newMunicipalityForm: FormGroup;
  public riskManagers;

  officialsElected = [];

  ngOnInit(): void {
    this.getListRiskManagersService();
  }

  //Get risk managers & populate the dropdown box
  getListRiskManagersService() {
    this.municipalitiesService.getListRiskManagers().subscribe((response: any) => {
      this.riskManagers = response;
    });
  };

  //Close modal and pass form data to controller
  submitNewMunicipalityForm(form) {
    if (form.valid) {
      this.municipalitiesService.addMunicipality(form.getRawValue()).subscribe((response: any) => {
        if (response.error == 0) {
          this.notifier.notify("success", "Successfully added record");
          this.dialogRef.close();
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };

}
