//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { FormValidator } from '../../../../shared/pipes/FormValidator';

//Import Services
import { MunicipalitiesService } from '../../../../shared/services/municipalities/municipalities.service';

@Component({
  selector: 'app-edit-munipality',
  templateUrl: './edit-munipality.component.html',
  styleUrls: ['./edit-munipality.component.scss']
})
export class EditMunipalityComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<EditMunipalityComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService, private municipalitiesService: MunicipalitiesService) {
    this.editMunicipalityForm = new FormGroup({
      MunicipalityID: new FormControl(0, Validators.required),
      RenewalApplicationID: new FormControl(0, Validators.required),
      MunicipalityYear: new FormControl(0, Validators.required),
      FullName: new FormControl('', [Validators.required, Validators.maxLength(150)]),
      ShortName: new FormControl('', [Validators.required]),
      Address1: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      Address2: new FormControl('', [Validators.maxLength(100)]),
      City: new FormControl('', [Validators.maxLength(50)]),
      State: new FormControl({ value: 'NJ', disabled: true }, Validators.compose([Validators.minLength(2), Validators.maxLength(2), Validators.required, FormValidator.patternValidator(/^[A-Z]*$/, { isCapital: true })])), //STRING
      Zip: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required, FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])), //STRING
      County: new FormControl('', [Validators.required]),
      ContactName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ContactTitle: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ContactPhone: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      ContactFax: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      ContactEmail: new FormControl('', [Validators.required, Validators.email]),
      RiskManagerID: new FormControl(0, [Validators.required]),
      ApplicationDate: new FormControl('', Validators.required),
      CoverageEffectiveDate: new FormControl('', Validators.required),
      CoverageEffectiveYear: new FormControl('', Validators.required),
      PublicOfficialsElected: new FormControl(0),
      PublicOfficialsAppointed: new FormControl(0),
      PopulationNormal: new FormControl(0),
      PopulationSeasonal: new FormControl(0),
      EmployeeTotal: new FormControl(0),
      GrossPayrollTotal: new FormControl(0),
      ContactorExpenditures: new FormControl(0),
      QuasiMunicipalEntities: new FormControl('', Validators.maxLength(500)),
      OperatingExpenditureEstimate: new FormControl(0),
      EffectiveDate: new FormControl('', Validators.required)
    });
  };

  public editMunicipalityForm: FormGroup;
  public riskManagers;

  officialsElected = [];

  ngOnInit(): void {
    this.getListRiskManagersService();
    this.getDetailMunicipality();
  };

  //Get risk managers & populate the dropdown box
  getListRiskManagersService() {
    this.municipalitiesService.getListRiskManagers().subscribe((response: any) => {
      this.riskManagers = response;
    });
  };

  //Get general info application form for the following "municipality" and patch the form with the values
  public getDetailMunicipality() {
    this.municipalitiesService.getDetailMunicipalities(this.data.id, this.data.selectedYear).subscribe((response: any) => {
      if (response == null) {
        this.editMunicipalityForm.reset();
      } else {
        this.editMunicipalityForm.patchValue({
          'MunicipalityID': response.municipalityID,
          'RenewalApplicationID': response.renewalApplicationID,
          'MunicipalityYear': response.municipalityYear,
          'FullName': response.fullName,
          'ShortName': response.shortName,
          'Address1': response.address1,
          'Address2': response.address2,
          'City': response.city,
          'State': response.state,
          'Zip': response.zip,
          'County': response.county,
          'ContactName': response.contactName,
          'ContactTitle': response.contactTitle,
          'ContactPhone': response.contactPhone,
          'ContactFax': response.contactFax,
          'ContactEmail': response.contactEmail,
          'RiskManagerID': response.riskManagerID,
          'ApplicationDate': response.applicationDate,
          'CoverageEffectiveDate': response.coverageEffectiveDate,
          'CoverageEffectiveYear': response.coverageEffectiveYear,
          'PublicOfficialsElected': response.publicOfficialsElected,
          'PublicOfficialsAppointed': response.publicOfficialsAppointed,
          'PopulationNormal': response.populationNormal,
          'PopulationSeasonal': response.populationSeasonal,
          'EmployeeTotal': response.employeeTotal,
          'GrossPayrollTotal': response.grossPayrollTotal,
          'ContactorExpenditures': response.contactorExpenditures,
          'QuasiMunicipalEntities': response.quasiMunicipalEntities,
          'OperatingExpenditureEstimate': response.operatingExpenditureEstimate,
          'EffectiveDate': response.effectiveDate
        });
      }
    });
  };

  //Close modal and pass form data to controller
  public submitEditMunicipalityForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.getRawValue());
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };
};
