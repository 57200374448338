//Import Modules
import { Component, ChangeDetectorRef, OnInit, AfterViewChecked } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './shared/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  account: any;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private AuthService: AuthService

  ) {
    this.AuthService.account.subscribe(x => this.account = x);
  };

  ngOnInit() { };
}
