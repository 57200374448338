<div class="user-profile-section-wrapper">
  <div class="row">
    <div class="col-md-12">
      <mat-toolbar class="feature-toolbar bg-white">
        <mat-toolbar-row>
          <span class="path">Edit Account</span>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-card>
        <div class="row">
          <div class="col-md-4 align-self-center">
            <div class="profile-image text-center">
              <img src="https://thumbs.dreamstime.com/b/creative-illustration-default-avatar-profile-placeholder-isolated-background-art-design-grey-photo-blank-template-mockup-144849704.jpg" class="img-fluid img-thumbnail w-50" />
            </div>
          </div>
          <div class="col-md-8 border-left">
            <form class="mt-3" [formGroup]="userProfileForm" (ngSubmit)="submitUserProfileForm(userProfileForm)">
              <div class="row">
                <div class="col-md-12">
                  <mat-label>First Name</mat-label>
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="FirstName" placeholder="" />
                    <mat-error *ngIf="userProfileForm.controls['FirstName'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label>Last Name</mat-label>
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="LastName" placeholder="" />
                    <mat-error *ngIf="userProfileForm.controls['LastName'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label>Email</mat-label>
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="Email" placeholder="" />
                    <mat-error *ngIf="userProfileForm.controls['Email'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label>Phone Number</mat-label>
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="PhoneNumber" placeholder="" mask="(000) 000-0000" />
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-checkbox formControlName="TwoFactor">Two Factor Authentication</mat-checkbox>
                </div>
              </div>
              <!-- ActionButtons -->
              <div class="form-action-buttons">
                <div class="row">
                  <div class="col-md-12">
                    <button class="mr-1" type="submit" mat-raised-button color="primary" [disabled]="userProfileForm.invalid">Save</button>
                    <button class="ml-1" type="button" mat-raised-button color="warn" routerLink="/dashboard/user-profile/change-password">Change your password</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
