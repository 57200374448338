//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { AuthService } from '../../shared/services/auth/auth.service';
import { NotifierService } from "angular-notifier";


@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {
  constructor(public authService: AuthService, private http: HttpClient, private fb: FormBuilder, private router: Router, private notifier: NotifierService) { };

  ngOnInit() { }

}
