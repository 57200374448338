<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>New Employee Location</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="newEmployeeLocationForm" (ngSubmit)="submitNewEmployeeForm(newEmployeeLocationForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Employee Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--NumberOfEmployees -->
            <mat-label>Number Of Employees <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="NumberOfEmployees">
              <mat-error *ngIf="newEmployeeLocationForm.controls['NumberOfEmployees'].hasError('required')">This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--EmployeesPerFloor -->
            <mat-label>Employees Per Floor <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="EmployeesPerFloor">
              <mat-error *ngIf="newEmployeeLocationForm.controls['EmployeesPerFloor'].hasError('required')">This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Shift1Employees -->
            <mat-label>Shift 1 Employees <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Shift1Employees">
              <mat-error *ngIf="newEmployeeLocationForm.controls['Shift1Employees'].hasError('required')">This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Shift2Employees -->
            <mat-label>Shift 2 Employees <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Shift2Employees">
              <mat-error *ngIf="newEmployeeLocationForm.controls['Shift2Employees'].hasError('required')">This field is requried</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--Shift3Employees -->
            <mat-label>Shift 3 Employees <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="Shift3Employees">
              <mat-error *ngIf="newEmployeeLocationForm.controls['Shift3Employees'].hasError('required')">This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <!--PropLocation-->
            <mat-label>Prop Location <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="PropLocation">
              <mat-error *ngIf="newEmployeeLocationForm.controls['Shift3Employees'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--HoursOfOperation-->
            <mat-label>Hours Of Operation</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="HoursOfOperation">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--FloorsOccupied-->
            <mat-label>Floors Occupied</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FloorsOccupied">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--BuildingConstruction-->
            <mat-label>Building Construction</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="BuildingConstruction">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--NumberOfStories-->
            <mat-label>Number Of Stories</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="NumberOfStories">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--YearBuilt-->
            <mat-label>Year Built</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="YearBuilt">
            </mat-form-field>
          </div>
        </div>
        <div class="form-section">
          <div class="row">
            <div class="col-md-12">
              <div class="form-section-title">
                <h6>Entry Information</h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <!--EffectiveDate -->
              <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
              <mat-form-field class="field-full-width" appearance="fill">
                <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
                <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
                <mat-datepicker #EffectiveDate></mat-datepicker>
                <mat-error>This field is requried</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
