//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { MunicipalitiesService } from '../../../../../shared/services/municipalities/municipalities.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-new-equipment-dialog',
  templateUrl: './new-equipment-dialog.component.html',
  styleUrls: ['./new-equipment-dialog.component.scss']
})
export class NewEquipmentDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewEquipmentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private municipalitiesService: MunicipalitiesService, private notifier: NotifierService) {
    this.newEquipmentForm = new FormGroup({
      ScheduleEquipments_yearId: new FormControl(0), //INT
      EquipmentID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      SerialNumber: new FormControl('', [Validators.required, Validators.maxLength(150)]), //STRING
      Manufacturer: new FormControl('', [Validators.required, Validators.maxLength(150)]), //STRING
      Model: new FormControl('', Validators.maxLength(50)), //STRING
      EquipYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      InsuranceLimit: new FormControl(0), //INT
      GrossWeight: new FormControl(0), //INT
      EquipDescription: new FormControl('', Validators.maxLength(100)), //STRING
      EquipNumber: new FormControl('', Validators.maxLength(50)), //STRING
      DepartmentID: new FormControl(0, Validators.required), //INT
      GarageLocation: new FormControl('', Validators.maxLength(250)), //STRING
      Leased: new FormControl(false), //BOOL
      LeasingCompany: new FormControl('', Validators.maxLength(150)), //STRING
      LeaseAddIns: new FormControl(false), //BOOL
      LeaseLossPayee: new FormControl(false), //BOOL
      Financed: new FormControl(false), //BOOL
      FinanceCompany: new FormControl('', Validators.maxLength(250)), //STRING
      FinanceLossPayee: new FormControl(false), //BOOL
      Comments: new FormControl(''), //STRING
      //EntryDate: new FormControl('', Validators.required), //DATE
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public departments;
  public newEquipmentForm: FormGroup;

  ngOnInit() {
    this.getDepartmentsMunicipalitiesService();
    this.newEquipmentForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear
    });
  };

  //Get department id, populates the dropdown field
  getDepartmentsMunicipalitiesService() {
    this.municipalitiesService.getDepartments().pipe(first()).subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: error => {
        this.notifier.notify('error', 'Something went wrong');
      }
    });
  };

  //Close modal and pass form data to controller
  submitNewEquipmentForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "There were errors in your submission. Please correct the highlighted fields and try again.");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
