<div class="logs-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>System Logs</h3>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table mat-table class="datatable" [dataSource]="dataSource" matSort matSortActive="ChangedDate" matSortDirection="desc">
          <!-- ChangedDate Column -->
          <ng-container matColumnDef="ChangedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Changed Date </th>
            <td mat-cell *matCellDef="let element"> {{element.changedDate | date:'short'}} </td>
          </ng-container>
          <!-- TableName Column -->
          <ng-container matColumnDef="TableName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Table </th>
            <td mat-cell *matCellDef="let element"> {{element.tableName}} </td>
          </ng-container>
          <!-- Operation Column -->
          <ng-container matColumnDef="Operation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Operation </th>
            <td mat-cell *matCellDef="let element"> {{element.operation}} </td>
          </ng-container>
          <!-- ChangedBy Column -->
          <ng-container matColumnDef="ChangedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Changed By </th>
            <td mat-cell *matCellDef="let element"> {{element.changedBy}} </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="viewLogDetail(element)">
                <mat-icon>visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
